import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BookingService,HomeService, DataService,MixpanelService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate   } from '@angular/common';
import { PassDestinationValueService } from '../services/pass-destination-value.service';
import { PassDepatureValueService } from '../services/pass-depature-value.service';
import * as moment from 'moment';
import { Base64 } from 'js-base64';
import { catchError, EMPTY, filter, fromEvent, Subject, takeUntil, tap,  } from 'rxjs';
import { Subscription } from 'rxjs';
import moengage from "@moengage/web-sdk";
import { Destinations } from '../models';
import { Seat, SeatType, SearchParams, StreamData,BusResult, Schedule, Operator } from '../interfaces/booking-interface';
import { ChangeDetectorRef } from '@angular/core';

// import { constants } from 'crypto';





@Component({
  selector: 'app-bus-booking',
  templateUrl: './bus-booking.component.html',
  styleUrls: ['./bus-booking.component.css']
})
export class BusBookingComponent implements OnInit, AfterViewInit {
  @ViewChild('focustarget') focusEl: ElementRef;
  private destroy$ = new Subject<void>();
  private streamSubscription: Subscription;
  private seatTypesFound = new Set<string>();
  private timeRangesFound = new Set<string>();
  private returnSelectedSeatTypes: Set<string> = new Set();

  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;pickupForm: UntypedFormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  // pickupanddropoff
  pickuplocation:string =""
  dropofflocation:string =""
  pickupreturnlocation:string =""
  dropoffreturnlocation:string =""
  today=new Date();
  noResultsFound: boolean = false; 
  isOpen=false;
  bsValue=new Date();
  bsInlineValue;
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);
  //traveldate:any;
  data = [];formdata={};
  formdataTravel:any={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  insurance: any = {};
  insurance_operators:any;
  insurance_cap:any;
  insurance_counter:any;
  return_schedule:any;
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  dummyDropoff:any =[];
  seatsSelected: any=[];
  selectedOptionPassengers;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any ;
  isResultsLoading=false;
  isResultsAllLoading = false;
  seatErrors=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  returnPickUpInit: string;
  returnDropOffInit: string;
  travelDateInit: string;
  returnDateInit:string;
  isUnavailabe=false;
  dataLoading=false;
  isPickupClicked=false;
  isReturnTicket=false;
  isReturnTicketSelected=false;
  isShowingFilter=false;
  totalFare=0;
  fareCurrency='Ksh'
  returning=false;
  submitted=false;
  completed:boolean=false;
  submittedMashPoa:boolean=false;
  selectedIndex = -1;
  hide_from_ui:number;
  availableSeats=[];
  seats:Seat[][] = [];
  seatData:any =[];
  seatMobile:any =[];
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false;
  discountAmount:any;
  busCheckSelected:any;
  isReadOnly=false;
  types= [];
  sticky: boolean = false;
  elementPosition: any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  //added this now to support the filters .
  operators: Set<Operator> = new Set<Operator>(); // Assume this is populated from somewhere
  selectedOperators: Set<string> = new Set();
  selectedTimeRanges: Set<string> = new Set();
  sortOption: string = 'Recommended'; 
  filteredSchedules: any[] = [];
  providers: any[] =[];
  departure_date: string;  
  departure_time: string;
  seats_types: SeatType[];
  isSearchingOrBooked: boolean = false;
  originalResults: any[] = [];
  selectedSeatTypes = new Set<string>(); // Store selected seat types
  availableSeatTypes = ['Normal', 'VIP', 'Business', 'Executive'];
  departureTimes :any[] = [];
  
  

  selectedBus:any;
  currentBusId:any;
  selectedBusStatus:boolean=false;
  isAcClicked=false;
  isEmptyReturn=false;
  isWater=false;
  From: string;
  To: string;
  TravelDate: string;
  finalTravelDate: string;
  finalReturnDateInit:string;
  hide_from_ui_shuttle:number;
  booked_seat_session_id='';
  hideFeedbackBtn=false;
  initialBus:any;
  destinations: Destinations[] = [];
 

  r_pickup:string;
  r_dropoff:string;
  traveldate:string;
  returndate:string; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeService:HomeService,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private mixpanelService: MixpanelService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,

    

  ) {
  
  
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:['', Validators.required],
      returndate:[new Date(), Validators.required]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required]
    });
     // pickupanddropoff
    this.pickupForm = _fb.group({
      pickupLocation:['', Validators.required],
      dropoffLocation:['', Validators.required],
    });
    
   }
   //changed this ??
  returnClicked() {
    this.returning = !this.returning;
    this.isOpen = true;
    // Reset form and UI elements specific to the return trip here
    if (this.returning) {
        this.reactiveForm.controls['returndate'].setValidators([Validators.required]);
        this.reactiveForm.controls['returndate'].updateValueAndValidity();
    } else {
        this.reactiveForm.controls['returndate'].clearValidators();
        this.reactiveForm.controls['returndate'].updateValueAndValidity();
    }
}
  closeReturn = () =>{
    this.returning=false;
  }


    ngOnInit(): void {
      this.loadDestinations();
      
      // Handle navigation scroll behavior
      this.router.events.pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => window.scrollTo(0, 0));
    
      // Get message from DataService
      this.dataService.returnSearchValueChange.pipe(
        takeUntil(this.destroy$),
      ).subscribe(message => this.processMessage(message));
    
      this.searchBuses(this.bookingdetails);
  
      this.trackPageView();
      this.loadScripts();
    }

      /**
       * Processes the message from dataService or initializes booking details.
       */
        private processMessage(message: string): void {
          if (!message) {
            this.initializeBookingDetails();
          } else {
            this.bookingdetails = JSON.parse(Base64.decode(message));
          }
        
          this.setFormPlaceholders();
          this.serviceDepature.changeValue(this.pickUpInit);
          this.serviceDestination.changeValue(this.dropOffInit);
        
          if (this.bookingdetails.returning) {
            this.returnPickUpInit = this.bookingdetails.dropoffname;
            this.returnDropOffInit = this.bookingdetails.pickupname;
            this.returnDateInit = this.bookingdetails.returndate ?? '';
          }
        }

      /**
     * Sets form placeholders and initializes booking details.
     */
      private setFormPlaceholders(): void {
        this.pickUpInit = this.bookingdetails.pickupname;
        this.dropOffInit = this.bookingdetails.dropoffname;
        this.travelDateInit = this.bookingdetails.traveldate;
        this.fromPlaceholder = this.bookingdetails.pickupname;
        this.toPlaceholder = this.bookingdetails.dropoffname;
        this.returnDateInit = this.bookingdetails.returndate?? '';
        this.returning = this.bookingdetails.returning;

      

      

        setTimeout(() => {
        
        
          if(!this.isReturnTicket){
              // Convert traveldate
              const validTravelDate = this.parseDate(this.bookingdetails.traveldate);
              // Patch form with correctly formatted travel date
              this.reactiveForm.patchValue({
                traveldate: validTravelDate ? formatDate(validTravelDate, "dd-MM-yyyy", "en-KE") : '',
                pickup: this.pickUpInit,
                dropoff: this.dropOffInit
              });
          }
          
        
          // Convert returndate (if applicable)
          if (this.bookingdetails.returning && this.bookingdetails.returndate) {
            const validReturnDate = this.parseDate(this.bookingdetails.returndate);
            this.reactiveForm.patchValue({ returndate: validReturnDate });
          }
        }, 0);
      
      
      }

    /**
     * Initializes booking details from query params.
     */
      private initializeBookingDetails(): void {
        const queryParams = this.route.snapshot.queryParamMap;
        this.r_pickup = queryParams.get('fromCityName') ?? '';
        this.r_dropoff = queryParams.get('toCityName') ?? '';
        this.traveldate = queryParams.get('onward') ?? formatDate(new Date(), 'yyyy-MM-dd', 'en-KE');
        this.returndate = queryParams.get('return') ?? '';
      
          this.returning = !!this.returndate;
          if (this.returning) {
            this.returndate = formatDate(this.returndate, 'yyyy-MM-dd', 'en-KE');
          }
      
          this.bookingdetails = {
            pickup: this.r_pickup,
            dropoff: this.r_dropoff,
            pickupname: this.r_pickup,
            dropoffname: this.r_dropoff,
            traveldate: this.traveldate,
            returndate: this.returndate,
            returning: this.returning
          };
      }

      get returnDate(): Date {
        return this.returnDateInit ? new Date(this.returnDateInit) : new Date();
      }
      get todaydate(): Date {
        let now = new Date();
        now.setHours(0, 0, 0, 0); // Ensure the time is set to midnight
        return now;
      }
      


      /**
       * Parses date string and returns a valid Date object.
       * Supports formats: "dd/MM/yyyy", "yyyy-MM-dd", "dd-MM-yyyy"
       */
      private parseDate(dateStr: string): Date | null {
        if (!dateStr) return null;

        // Try different date formats
        const patterns = [
          { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, order: ["d", "m", "y"] }, // dd/MM/yyyy
          { regex: /^(\d{4})-(\d{2})-(\d{2})$/, order: ["y", "m", "d"] }, // yyyy-MM-dd
          { regex: /^(\d{2})-(\d{2})-(\d{4})$/, order: ["d", "m", "y"] } // dd-MM-yyyy
        ];

        for (const pattern of patterns) {
          const match = dateStr.match(pattern.regex);
          if (match) {
            const parts = match.slice(1).map(num => parseInt(num, 10));
            const year = parts[pattern.order.indexOf("y")];
            const month = parts[pattern.order.indexOf("m")] - 1; // Month is zero-based
            const day = parts[pattern.order.indexOf("d")];

            return new Date(year, month, day);
          }
        }

        console.warn("Unrecognized date format:", dateStr);
        return null; // Return null if format is unrecognized
      }

        /**
         * Tracks page view using Mixpanel.
         */
        private trackPageView(): void {
          this.mixpanelService.track('PageView', {
            pageName: 'Schedules page',
            source: 'buupassSite',
            role: 'customer',
          });
        }

          /**
         * Loads scripts required for desktop, mobile, and ads.
         */
        private loadScripts(): void {
          this.loadDesktopScript();
          this.loadMobileScript();
          this.loadAds();
        }




toggleOperatorSelection(operator: string): void {  
  // Normalize the name to lowercase to avoid mismatch issues
  const normalizedOperator = operator.trim().toLowerCase();

  if (this.selectedOperators.has(normalizedOperator)) {
    this.selectedOperators.delete(normalizedOperator);
  } else {
    this.selectedOperators.add(normalizedOperator);
  }  
  // Apply filters after updating selection
  this.applyFilters();
}


filterByTimeRange(schedules: any[]): any[] {
  return schedules.filter(schedule => {
    const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);
   

    const hour = departureTime.getHours();
  

    const timeRange = this.getTimeRangeForHour(hour);

    return this.selectedTimeRanges.has(timeRange);
  });
}



  
 // Function to toggle departure time selection
 toggleTimeSelection(timeRange: string): void {
  timeRange = timeRange.trim().toLowerCase(); // Normalize input

  if (this.selectedTimeRanges.has(timeRange)) {
    this.selectedTimeRanges.delete(timeRange);
  } else {
    this.selectedTimeRanges.add(timeRange);
  }

  this.applyFilters(); // Apply filters after updating selection
}


toggleSeatTypeSelection(seatType: string): void {
  if (this.selectedSeatTypes.has(seatType)) {
    this.selectedSeatTypes.delete(seatType);
  } else {
    this.selectedSeatTypes.add(seatType);
  }
  this.applyFilters(); // Reapply filters after selection
}

getTimeRangeForHour(hour: number): string {
  if (hour >= 0 && hour < 6) return "Night";
  if (hour >= 6 && hour < 12) return "Morning";
  if (hour >= 12 && hour < 18) return "Afternoon";
  if (hour >= 18 && hour < 24) return "Evening";
 
  return "Others"; // This should never be reached
}




sortByProvider(results: any[]): void {
  results.sort((a, b) => (a.provider || "").localeCompare(b.provider || ""));
}



applyFilters(): void {

  if (!this.originalResults || this.originalResults.length === 0) {
    this.filteredSchedules = [];
    this.isResultsLoading = false;
    return;
  }

  const selectedRanges = new Set([...this.selectedTimeRanges].map(range => range.trim().toLowerCase()));
  const selectedOperators = new Set([...this.selectedOperators].map(op => op.trim().toLowerCase()));
  const selectedSeatTypes = new Set([...this.selectedSeatTypes].map(seat => seat.trim().toLowerCase()));


  // Apply filters in a single `.filter()` pass
  let filteredResults = this.originalResults.filter((schedule) => {
    if(!schedule.is_shuttle){
      const isTimeValid = this.isTimeMatching(schedule, selectedRanges);

      const isOperatorValid = this.isOperatorMatching(schedule, selectedOperators);
      const isSeatTypeValid = this.isSeatTypeMatching(schedule, selectedSeatTypes);

      return isTimeValid && isOperatorValid && isSeatTypeValid;
    }else if(selectedRanges.has('others')){
      return true;
    }else{
      return false
    }



  });
  
  if (this.isReturnTicket && this.return_schedule) {
    let filteredReturnSchedules = this.return_schedule.filter((schedule) => {
      return this.isTimeMatching(schedule, selectedRanges) &&
             this.isOperatorMatching(schedule, selectedOperators) &&
             this.isSeatTypeMatching(schedule, selectedSeatTypes);
    });

    this.results = [...filteredResults, ...filteredReturnSchedules];
  } else {
    this.results = filteredResults;
  }
  this.results = this.sortSchedules2(this.results);
 
 
}


private isTimeMatching(schedule: any, selectedRanges: Set<string>): boolean {
  if (selectedRanges.size === 0) return true;

  if (!schedule.departure_date || !schedule.departure_time) {
    console.warn(`Skipping schedule due to missing departure info: ${schedule.id}`);
    return false;
  }



  const hour = this.getHours(schedule.departure_time);
  const timeRange = this.getTimeRangeForHour(hour).trim().toLowerCase();


  return selectedRanges.has(timeRange);
}




private isOperatorMatching(schedule: any, selectedOperators: Set<string>): boolean {
  if (selectedOperators.size === 0) return true;

  if (this.isReturnTicket && this.initialBus?.operator?.alias !== schedule.operator?.alias) {
    return false;
  }

  if (!schedule.operator?.alias) {
    console.warn(`Skipping schedule due to missing operator info: ${schedule.id}`);
    return false;
  }

  return selectedOperators.has(schedule.operator.alias.trim().toLowerCase());
}

private isSeatTypeMatching(schedule: any, selectedSeatTypes: Set<string>): boolean {
  if (selectedSeatTypes.size === 0) return true;

  if (!schedule.seats_types || schedule.seats_types.length === 0) {
    console.warn(`Skipping schedule due to missing seat types: ${schedule.id}`);
    return false;
  }

  // Check if any of the available seat types in the schedule match those selected by the user
  const matchesSelected = schedule.seats_types.some(seat => selectedSeatTypes.has(seat.alias.trim().toLowerCase()));

  // Special handling for return trips
  if (this.isReturnTicket && this.initialBus) {
    if (this.returnSelectedSeatTypes && this.returnSelectedSeatTypes.size > 0) {
      const matchesReturnSelected = schedule.seats_types.some(seat =>
        this.returnSelectedSeatTypes.has(seat.alias.trim().toLowerCase())
      );

      return matchesReturnSelected && matchesSelected;
    }
  }
  return matchesSelected;
}






sortSchedules2(schedules: any[]): any[] {
  if (!schedules || schedules.length === 0) return [];

  return schedules.sort((a, b) => {
    // Example: Sort by departure time
    const timeA = new Date(`${a.departure_date}T${a.departure_time}Z`).getTime();
    const timeB = new Date(`${b.departure_date}T${b.departure_time}Z`).getTime();
    return timeA - timeB; 
  });
}


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  ngAfterViewInit(): void {
   
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    setTimeout(() => {
      this.checkDates();

    }, 0);

  }


  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
    this.serviceDepature.changeValue(pickup);
    this.serviceDestination.changeValue(dropoff);

    // setTimeout(()=>{
    //   this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE")})
    // },0)

  }


    checkDates=() =>{
      let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
      
      let today = formatDate(this.today ?? new Date(),"yyyy-MM-dd","en-KE");
      var date = new Date(traveldate);
      this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
      this.yesterday=new Date(date.setDate(date.getDate()+1));
      this.tomorrow =new Date(date.setDate(date.getDate()+2))
      this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
        if(traveldate == today)
            return true;
        else
          return false;
    }


    changeDate=(selected) =>{
      
      this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
      this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
      
      this.checkDates();
      this.bookingdetails={
        'pickup' : this.pickUpInit,
        'dropoff' : this.dropOffInit,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
        'returndate' :this.returnDateInit,
        'returning' : this.returning
      }

      this.searchBuses(this.bookingdetails);
    }


  interChange=()=>{
    this.changeI=!this.changeI;
       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
       let webformdata: SearchParams = {
        pickup: dropOff ?? '',  // Ensure it's a string, default to ''
        dropoff: pickUp ?? '',
        pickupname: dropOff ?? '',
        dropoffname: pickUp ?? '',
        traveldate: this.travelDateInit ?? '',  // Ensure it's a string
        returndate: this.returnDateInit ?? '',
        returning: this.returning
      };     

      this.interChangeValues(dropOff,pickUp);
      this.searchBuses(webformdata);

  }

  onTravelDateChange = (event) => {
    this.today = event;
  }


  private loadDestinations(): void {
    this.homeService.getDestinations()
      .pipe(
        takeUntil(this.destroy$), // Unsubscribes on component destroy
        catchError(error => {
          console.error('Error loading destinations:', error);
          this.destinationLoading = false; // Handle error state
          return EMPTY;
        })
      )
      .subscribe({
        next: (response: any) => {
          // Assuming response has a structure like { data: { all_cities: Destinations[] } }
          this.destinations = response.data.all_cities;
          // Extract names from the nested structure
          this.data = response.data.all_cities.map((dest: any) => dest.name);
          // Set loading to false
          this.destinationLoading = false;
        },
        error: () => {
          // Handled by `catchError`, won't execute
        }
      });
  }


  private showSidebarFormValuesForAllOperators(): void {
    this.selectedOperators.clear();
    this.providers.forEach(operator => this.selectedOperators.add(operator.toLowerCase()));
  }

  backHome(){
    this.router.navigateByUrl('/home');
  }

  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }


  submitWebForm(): void {
    this.isReturnTicket=false;
    this.initialBus=null;
    this.submitted = true;
    const value = this.reactiveForm.value;
  
    // Format travel date
    const traveldate = this.formatDateInput(value.traveldate);
    this.travelDateInit=traveldate;
    this.checkDates();

    // Initialize return date
    let returndate = '';
  
    if (this.returning) {
      this.returning = true;
      returndate = this.formatDateInput(value.returndate);
      value.returndate = returndate;
    } else {
      this.returning = false;
    }
  
    // Construct webform data
    this.bookingdetails = {
      pickup: value.pickup,
      dropoff: value.dropoff,
      pickupname: value.pickup,
      dropoffname: value.dropoff,
      traveldate,
      returndate: value.returndate || '',
      returning: this.returning
    };
  
  
    // Trigger search function
    this.searchBuses(this.bookingdetails);
  }

    /**
   * Helper function to format date input.
   */
    private formatDateInput(date: string | Date): string {
      if (typeof date === 'string') {
        const pattern = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
        const arrayDate = date.match(pattern);
        if (arrayDate) {
          const dt = new Date(+arrayDate[3], +arrayDate[2] - 1, +arrayDate[1]);
          return formatDate(dt, 'yyyy-MM-dd', 'en-KE');
        }
      }
      return formatDate(date, 'yyyy-MM-dd', 'en-KE');
    }


    submitMobileForm() {
      this.submitted = true;
      let value = this.reactiveMobileForm.value;
      var traveldate = new Date(formatDate(value.traveldate,"yyyy-MM-dd","en-KE"));
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
      value.traveldate=traveldate;
      let mobileformdata={
        'pickup' : value.pickup,
        'dropoff' : value.dropoff,
        'pickupname' : value.pickup,
        'dropoffname' : value.dropoff,
        traveldate: formatDate(traveldate, 'yyyy-MM-dd', 'en-KE'), // Convert Date to string
        returndate: returndate ? formatDate(returndate, 'yyyy-MM-dd', 'en-KE') : '',
        'returning' : this.returning
      }

      
      this.searchBuses(mobileformdata);
    }


    toggleShow(results: any): void {
      // Reset state variables
      this.seatsSelected = [];
      this.totalFare = 0;
      this.dataLoading = false;
      this.hide_from_ui = 0;
      this.hide_from_ui_shuttle = 0;
      this.isBusSelected = false;
      this.setElementWidth('mySidenavDropoff', '0px');
      this.setElementWidth('mySidenav', '0px');
      this.setElementWidth('mySidenavShuttle', '0px');
      this.setElementDisplay('.fadeMe', 'none');
    }


      selectSeats(results: BusResult): void {
        this.selectedBus = results;
        this.selectedBusStatus = true;
        this.dataLoading = true;
        this.hide_from_ui = results.id;
      
        this.setElementWidth('mySidenav', '380px');
        this.setElementDisplay('.fadeMe', 'block');
      
        // Reset seat-related variables
        this.seatsSelected = [];
        this.seatData = [];
        this.seatMobile = [];
        this.totalFare = 0;
        this.seats = [];
      
        // Format travel date
        const travelDate = formatDate(this.travelDateInit, 'd-M-yyyy', 'en-KE');
      
        // Construct query object using shorthand notation
        const query = {
          fleet_registration_id: results.id,
          date: travelDate,
          start_point: results.origin_city_id,
          end_point: results.destination_city_id,
          alias: results.operator.alias,
          rsc_id: results.route_schedule_id,
          bus_id: results.id,
          id: results.id,
          no_of_seats: results.number_of_available_seats,
          fare: results.fare,
          leaving_from: results.from,
          going_to: results.to,
        };
      
        // Track event in MoEngage
        moengage.track_event('Bus Select', {
          SelectedBusOperator: results.operator.alias,
          Fare: results.fare, // No need to parseInt if already a number
          RouteDepatureCity: results.from,
          RouteDestinationCity: results.to,
          AvailableSeats: results.number_of_available_seats,
          BusId: results.id,
          TravelDate: travelDate,
        });
      
        // Fetch available seats
        this.getAvailableSeats(query);
      }

      selectDropoff(): void {
        // Use Renderer2 for better Angular compatibility
        this.setElementWidth('mySidenavDropoff', '380px');
        this.setElementDisplay('.fadeMe', 'block');
        this.setElementWidth('mySidenav', '0px');
        this.setElementWidth('mySidenavShuttle', '0px');
      }


      /**
       * Helper function to set the width of an element by ID.
       */
      private setElementWidth(elementId: string, width: string): void {
        const element = document.getElementById(elementId);
        if (element) {
          this.renderer.setStyle(element, 'width', width);
        }
      }

      /**
       * Helper function to set the display property of an element by class name.
       */
      private setElementDisplay(selector: string, display: string): void {
        const element = document.querySelector(selector);
        if (element) {
          this.renderer.setStyle(element, 'display', display);
        }
      }      

   
      sortByDepartureTime(results: any[]): any[] {
       

        return results.sort((a, b) => {

   
          let aTime = this.getHours(a.departure_time)
          let bTime = this.getHours(b.departure_time)
     
      
          return aTime - bTime;
        });
      }
      
      
      

      getAvailableSeats(query: any): void {
        this.seatErrors = false;
        this.dataLoading = true;
      
        this.service.getSeats(query)
          .pipe(
            takeUntil(this.destroy$), // Ensures unsubscription
            catchError(error => {
              this.handleSeatError();
              return EMPTY;
            })
          )
          .subscribe({
            next: response => this.processSeatResponse(response, query),
            error: () => this.handleSeatError()
          });
      }

            /**
             * Processes the seat response data.
             */
            private processSeatResponse(response: any, query: any): void {
              this.seatErrors = false;
              this.seatRes = response;
      
              const availableSeatsData = this.seatRes.data.available_seats;
              this.availableSeats = availableSeatsData.available_seat_ids;
              this.seats = availableSeatsData.seats;
              this.booked_seat_session_id = availableSeatsData.booked_seat_session_id;
      
              // Handle discount for 'moderncoast'
              this.IsDiscount = query.alias === 'moderncoast' && availableSeatsData.online_discount > 0;
              if (this.IsDiscount) {
                this.discountAmount = availableSeatsData.online_discount;
              }
      
              // Clear previous seat data
              this.seatData = [];
              this.seatMobile = [];
      
              this.seats = (this.seats ?? []) as Seat[][];
      
              this.seats.forEach((iseats: Seat[], rowIndex: number) => {
                let s = 5, m = 5;
                iseats.forEach(seat => {
                  const seatInfo = {
                    id: seat.id,
                    type: seat.type,
                    status: seat.status,
                    space_type: seat.space_type,
                    fare: seat.fare
                  };
      
                  this.seatData.push({ ...seatInfo, s: s--, row: rowIndex });
                  this.seatMobile.push({ ...seatInfo, m: m++, row: rowIndex });
                });
              });
      
              // Sort seat data
              this.seatData.sort((a, b) => a.s - b.s);
              this.seatMobile.sort((a, b) => a.m - b.m);
      
              // Group data
              this.seatData = Object.values(this.groupByKey(this.seatData, 's'));
              this.seatMobile = Object.values(this.groupByKey(this.seatMobile, 'row'));
      
              this.dataLoading = false;
            }
      
            /**
             * Handles errors when fetching seat data.
             */
            private handleSeatError(): void {
              this.seatErrors = true;
              this.dataLoading = false;
            }



      seatSelect(event: any, result: any, id: number, space_type: string, fare: any) {
        let seatPrice = '0';
        let seatCurrency = 'KSH';
        let seatsPrices = result.seats_types;
    
        // Determine seat price and currency
        if (['mashpoa', 'moderncoast'].includes(result.operator.alias)) {
            const seat = seatsPrices.find(seat => seat.alias === space_type);
            if (seat) {
                seatPrice = seat.fare;
                seatCurrency = seat.currency;
            }
        } else {
            seatsPrices.forEach(seat => {
                if (space_type === seat.alias || (space_type === 'business' && seat.alias === 'business')) {
                    seatPrice = seat.fare;
                    seatCurrency = seat.currency;
                } else if (seat.fare > 0) {
                    seatPrice = seat.fare;
                    seatCurrency = seat.currency;
                }
            });
        }
    
        if (!event.srcElement.parentElement.classList.contains('unavailable')) {
            const seatIndex = this.seatsSelected.findIndex(e => e.id === id);
            
            if (seatIndex !== -1) {
                // Seat already selected, remove it
                event.srcElement.classList.remove("selected");
                event.srcElement.parentElement.classList.remove('selected');
                this.totalFare -= parseInt(seatPrice);
                this.seatsSelected.splice(seatIndex, 1);

               
            } else {
                // Add seat to selection
                event.srcElement.classList.add("selected");
                event.srcElement.parentElement.classList.add('selected');
                this.totalFare += parseInt(seatPrice);
    
                let seatFare = fare[space_type] || (result.operator.alias === 'easycoach' ? fare.normal : undefined);
                this.seatsSelected.push({ id, space_type, fare: seatFare });
    
                if (this.return_schedule) {
                    Object.assign(this, {
                        busSelected: result,
                        currentBusId: result.id,
                        hide_from_ui: 0,
                        busOperatorSelected: result.operator.alias,
                        isBusSelected: true
                    });
                }
             
            }
    
            // Track events
            moengage.track_event('Seat Selection', {
                'SelectedSeatNumber': id,
                'SeatType': space_type,
                'BusOperator': result.operator.alias,
                'SeatPrice': parseInt(seatPrice)
            });
    
            this.mixpanelService.track('SeatSelect', {
                seatNumber: id,
                operator: result.operator.alias,
                source: 'buupassSite',
                role: 'customer'
            });
        }
      }

    // Utility function for grouping array objects by a key
    groupByKey(array, key) {
      return array.reduce((acc, obj) => {
          const value = obj[key];
          acc[value] = acc[value] || [];
          acc[value].push(obj);
          return acc;
      }, {});
  }
  

    get f() { return this.reactiveForm.controls; }
     // pickupanddropoff
    get fpickup() { return this.pickupForm.controls; }
    
    //book shuttle
    bookShuttle(result:any){
      this.selectedBus=result;
      this.hide_from_ui_shuttle = result.trip_id;

      this.setElementWidth('mySidenavShuttle', '380px');
      this.setElementDisplay('.fadeMe', 'block');


      this.selectedBusStatus=true;

        let seatsPrices =result.seats_types;
        let seatPrice ='0';
        for(let i=0; i<seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
        }
        this.totalFare=parseInt(seatPrice);
        this.busSelected=result;
        this.currentBusId=result.id;
        this.busOperatorSelected=result.operator.alias;
        this.isBusSelected =true;

      //TODO implement the shuttle process
      this.formdataTravel={
        'result':result,
        'fare': result.fare,
        'title': result.from + ' to ' + result.to,
        'total_fare':this.totalFare,
        "search_details": this.bookingdetails,
        'pickup' : result.boarding_points.length,
        'dropoff' :result.dropoff_points,
        'totalfare' : this.totalFare,
        'seatsSelected' : this.seatsSelected
      }

      let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let syncData={
          'travel':paytravel,
          'return':'',
          'returning':false
        }
    }

    selectPassOption(id: number,selectedBus) {
      let seatsPrices =selectedBus.seats_types;
      let seatPrice ='0';
      for(let i=0; i<seatsPrices.length; i++){
           if(seatsPrices[i].fare > 0){
             seatPrice=seatsPrices[i].fare;
           }
     }
       //getted from event

       this.seatsSelected=[];
       this.selectedOptionPassengers=id
       this.totalFare=parseInt(this.selectedOptionPassengers) * parseInt(seatPrice)

       for(let i=0;i<this.selectedOptionPassengers;i++)
       {
           const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
           this.seatsSelected.push({id:random(1, 23),space_type:'business'});
       }

     }
    
    submitForm(result: any) {
      
          this.submitted = true;

          // Validate insurance before proceeding
          if (!this.insurance || !this.insurance_operators) return;

          // Ensure dropoff & boarding points are set
          if (result.dropoff_points.length < 1) result.dropoff_points.push(this.dropOffInit);
          if (result.boarding_points.length < 1) result.boarding_points.push(this.pickUpInit);

          //Storing the departure date separately from return date 
          const departureDate = this.formatDepartureDate(result);

          // Handle return ticket selection
          if (this.isReturnTicket && !this.isReturnTicketSelected) {
            this.isReturnTicketSelected = true;
         
            // Process return ticket details
            // Process return ticket details
            const returnDate = this.formatDepartureDate(result); // Separate return date
            const { pickupLocation, dropoffLocation } = this.getPickupAndDropoff(
              result.operator.alias,
              this.bookingdetails.returning
            );
            
            this.formdataReturn = this.createBookingData(
              result,
              returnDate, // Use return date for return ticket
              pickupLocation,
              dropoffLocation,
              this.seatsSelected
            );

           
            this.processPayment();
            return
          }

          // Process one-way ticket details
          const { pickupLocation, dropoffLocation } = this.getPickupAndDropoff(
            result.operator.alias,
            result // Use the original result for departure date
          );

          this.formdataTravel = this.createBookingData(
            result,
            departureDate, // Use departure date for one-way ticket
            pickupLocation,
            dropoffLocation,
            this.seatsSelected
          );

          // Handle one-way vs. return logic
          if (this.bookingdetails.returning && !this.isReturnTicket) {
          
            this.handleReturnSearch();
          } else {
            this.processPayment();
          }

          // Track Mixpanel Event
          this.mixpanelService.track("BusSelected", {
            operator: result.operator.alias,
            pickup: this.fromPlaceholder,
            dropoff: this.toPlaceholder,
            fare: this.formdataTravel.total_fare,
            source: "buupassSite",
            role: "customer",
          });
}


private formatDepartureDate(result: any): string {
  const formatMapping: { [key: string]: string } = {
    easycoach: "MM/DD/YYYY",
    shuttle: "YYYY-MM-DD",
  };

  const format = formatMapping[result.operator.alias] || "DD/MM/YYYY";
  return moment(result.departure_date, "YYYY-MM-DD").format(format);
}

private getPickupAndDropoff(operatorAlias: string, returning: boolean): { pickupLocation: string; dropoffLocation: string } {
  if (operatorAlias === "mashpoa") {
    if (!this.pickupForm.valid) return { pickupLocation: "", dropoffLocation: "" };

    let fvalues = this.pickupForm.value;
    this.pickuplocation = fvalues.pickupLocation;
    this.dropofflocation = fvalues.dropoffLocation;

    if (returning) {
      this.submittedMashPoa = false;
      this.closeModalUI();
      this.pickupForm.reset();
    } else {
      this.submittedMashPoa = true;
      this.closeModalUI();
    }
  } else {
    this.closeModalUI();
    this.pickuplocation = this.fromPlaceholder;
    this.dropofflocation = this.toPlaceholder;
  }

  return {
    pickupLocation: this.pickuplocation,
    dropoffLocation: this.dropofflocation,
  };
}

private closeModalUI() {
  this.setElementDisplay(".fadeMe", "none");
  this.setElementWidth("mySidenavDropoff", "0px");
  this.setElementWidth("mySidenav", "0px");
}



private createBookingData(result: any, departureDate: string, pickupLocation: string, dropoffLocation: string, seatsSelected: any) {
  return {
    result,
    fare: result.fare,
    title: `${result.from} to ${result.to}`,
    total_fare: this.totalFare,
    fare_currency: this.fareCurrency,
    search_details: this.bookingdetails,
    pickup: result.boarding_points.length,
    dropoff: result.dropoff_points,
    search_from: this.fromPlaceholder,
    search_to: this.toPlaceholder,
    pickup_location: pickupLocation,
    dropoff_location: dropoffLocation,
    totalfare: this.totalFare,
    seatsSelected,
    booking_session_id: this.booked_seat_session_id,
    insurance: this.insurance,
    insurance_operators: this.insurance_operators,
    insurance_cap: this.insurance_cap,
    insurance_counter: this.insurance_counter,
  };
}

private handleReturnSearch() {
  this.interChangeValues(this.bookingdetails.dropoff, this.bookingdetails.pickup);
  const returnSearch: SearchParams = {
    pickup: this.bookingdetails.dropoff,
    dropoff: this.bookingdetails.pickup,
    pickupname: this.bookingdetails.dropoff,
    dropoffname: this.bookingdetails.pickup,
    traveldate: this.bookingdetails.returndate,
    returndate: this.bookingdetails.originalDepartureDate,
    returning: this.bookingdetails.returning,
  };

  this.isReturnTicket = true;
  // this.showSidebarFormValuesForAllOperators();
  this.searchBuses(returnSearch);
}

private processPayment() {
  
  const payTravel = Base64.encode(JSON.stringify(this.formdataTravel));
  const payReturn = this.isReturnTicket ? Base64.encode(JSON.stringify(this.formdataReturn)) : "";
  const syncData = { travel: payTravel, return: payReturn, returning: !!this.isReturnTicket };
  const payMessage = Base64.encode("Oops, sorry mate!");

  this.dataService.changePaymentMessage(Base64.encode(JSON.stringify(syncData)));
  this.selectedBusStatus = false;
  this.router.navigate(["/payments", payMessage]);
}





    


/**
 * Stores search data in localStorage.
 */
private storeSearchInLocalStorage(message: string): void {
  this.dataService.changeSearchMessage(message);
}

searchBuses(search: SearchParams) {

  
  this.isSearchingOrBooked = true;
  //store original dates separately
  const originalTravelDate = search.traveldate;
  const originalReturnDate = search.returndate;

  //Tracking Moengage
  let message = Base64.encode(JSON.stringify(search));
  this.storeSearchInLocalStorage(message);
  moengage.track_event("Bus ticket search", {
    DepartureCity: search.pickup,
    ArrivalCity: search.dropoff,
    DepartureDate: originalTravelDate,
  });

  if (this.isReturnTicket) {
    this.handleReturnTicket({ 
      ...search, 
      traveldate: originalTravelDate, 
      returndate: originalReturnDate 
    });
  } else {
    this.updateQueryParams(search);
    this.handleOneWayTicket({ 
      ...search, 
      traveldate: originalTravelDate 
    }, message);
  }
 
}

updateQueryParams(searchParams: SearchParams): void {
  // Construct query params dynamically
  const queryParams: any = {
    fromCityName: searchParams.pickup,
    toCityName: searchParams.dropoff,
    onward: searchParams.traveldate
  };

  // Add returndate only if isreturning is true and returndate is present
  if (searchParams.returning && searchParams.returndate) {
    queryParams.return = searchParams.returndate;
  }

  this.router.navigate([], {
    relativeTo: this.route,
    queryParams: queryParams,
    queryParamsHandling:"replace" // Merge existing query params instead of replacing all
  });
}

private handleReturnTicket(search: SearchParams) {


  this.initialBus = this.selectedBus;
  
  this.results = this.return_schedule?.filter(
    (schedule) => schedule.operator.alias === this.busOperatorSelected
  ) || [];




  if (!this.results.length) {
    this.isEmptyReturn = true;
    this.submitted = false;
    this.results = [];
    this.isResultsLoading = true;
  }

  this.processSearchDates(search, true);
  this.updateSearchState(search);

  this.isResultsLoading = true;
  this.results = [];


  this.subscribeToStream(search);
}

private handleOneWayTicket(search: SearchParams, message: string) {
  localStorage.setItem("search-buupass", message);
  this.results = [];
  this.isResultsLoading = true;
  this.processSearchDates(search, false);
  this.updateSearchState(search);
  this.subscribeToStream(search);
  
}

private processSearchDates(search: SearchParams, isReturn: boolean = false) {
  if (!isReturn) {

  this.travelDateInit = search.traveldate;

  if (search.traveldate?.trim()) {
    search.traveldate = formatDate(search.traveldate, "dd/MM/yyyy", "en-KE");
  } else {
    console.error("Invalid travel date:", search.traveldate);
  }

  if (this.returning && search.returndate?.trim()) {
    search.returndate = formatDate(search.returndate, "dd/MM/yyyy", "en-KE");
  } else if (this.returning) {
    console.warn("Return date is missing or invalid:", search.returndate);
  }
}
}

private updateSearchState(search: SearchParams) {
  this.pickUpInit = search.pickupname;
  this.dropOffInit = search.dropoffname;
  this.finalReturnDateInit = search.returndate ?? '';
  search.returning = this.returning;
}

private updateTimeRanges(timeRanges: string[]) {
  const timeRangeMapping = {
    "Night": { name: "Night", iconClass: "icon-nighttime",description: "(12 AM - 6 AM)"  },
    "Morning": { name: "Morning", iconClass: "icon-morning",description: "(6 AM - 12 PM)"   },
    "Afternoon": { name: "Afternoon", iconClass: "icon-midday", description: "(12 PM - 6 PM)" },
    "Evening": { name: "Evening", iconClass: "icon-late", description: "(6 PM - 12 AM)" },
    "Others": { name: "Others", iconClass: "icon-none", description: "Others" },
    
  };

  this.departureTimes = timeRanges.map(range => timeRangeMapping[range]).filter(Boolean);
  const customOrder = ['Morning', 'Afternoon', 'Evening', 'Night', 'Others'];


// Sort departure times based on the custom order
  this.departureTimes.sort((a, b) => {
    return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
  });

}



private subscribeToStream(search: SearchParams) {
  this.providers = [];
  this.departureTimes = [];
   this.availableSeatTypes = []; 
   

  
  this.isResultsLoading = true;
  this.isResultsAllLoading = true;
  this.isSearchingOrBooked = true;

  this.streamSubscription = this.service
    .streamSchedules(search.pickupname, search.dropoff, search.traveldate)
    
    .subscribe({
      next: (streamData: StreamData) => this.handleStreamData(streamData),
      error: (err) => this.handleStreamError(err),
      complete: () =>  
        this.handleStreamComplete(),
      
      
    });
   
}



private handleStreamData(streamData: StreamData) {
  if (!streamData || typeof streamData !== "object") {
    return;
  }
  this.isResultsLoading = false;
  
  if (!this.initialBus) {
    if(streamData.provider){
      // If initialBus is not selected, add all providers
      this.providers.push(streamData.provider);
    }

  } else {
    // If initialBus is selected, filter providers
    this.providers = []; // Clear providers first
    if (this.initialBus.operator.alias === streamData.provider) {
      this.providers.push(streamData.provider); // Add only matching providers
    }
  }
  

  

  let scheduleArray = streamData.data ?? [];
  let timeRangesFound = new Set<string>();
  let seatTypesFound = new Set<string>(); 
  

  if (streamData.data?.insurance) {
    this.insurance = streamData.data.insurance.data || {};
    this.insurance_operators = streamData.data.insurance.allowed_operators || [];
    this.insurance_cap = streamData.data.insurance.cap || 0;
    this.insurance_counter = streamData.data.insurance.counter || 0;
  } else {
    this.insurance = {};
    this.insurance_operators = [];
    this.insurance_cap = 0;
    this.insurance_counter = 0;
  }

  if (Array.isArray(scheduleArray) && scheduleArray.length > 0) {
    scheduleArray.forEach((schedule: Schedule) => {



      const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);

      const hour = this.getHours(schedule.departure_time);
      const minutes =  this.getMinutes(schedule.departure_time);

      const nairobiHour = Number(moment().utcOffset(180).format("HH")); // 180 minutes = UTC+3
      const nairobiMinutes = Number(moment().utcOffset(180).format("mm")); // 180 minutes = UTC+3




    // Get today's date in both possible formats
    const todayFormatted1 = moment().format("YYYY-MM-DD"); // 2025-03-19 format
    const todayFormatted2 = moment().format("MM/DD/YYYY"); // 03/19/2025 format

    // Check if the departure date is today before comparing the time
    if (schedule.departure_date === todayFormatted1 || schedule.departure_date === todayFormatted2) {
      if (hour < nairobiHour || (hour === nairobiHour && minutes < nairobiMinutes)) {
        return;
      }
    }
      // const hour = departureTime.getHours();

      const timeRange = this.getTimeRangeForHour(hour);
     
      this.timeRangesFound.add(timeRange);
    
       // Extract and track available seat types
       schedule.seats_types?.forEach(seatType => this.seatTypesFound.add(seatType.alias));
       




       if (!this.initialBus || (this.initialBus && this.initialBus.operator.alias === schedule.operator.alias && this.selectedTimeRanges.has(timeRange))) {
        if (schedule.operator.alias === 'easycoach') {
          if(schedule.number_of_available_seats > 0) {
            this.results.push(schedule);
          }
        }else{
          this.results.push(schedule);
        }
     
        this.originalResults.push(schedule);
        this.operators.add(schedule.operator);
        timeRangesFound.add(timeRange); // Track time ranges only for reevant schedules
      }else{

        if (this.initialBus.operator.alias === schedule.operator.alias) {
          if(schedule.operator.alias === 'easycoach'){
            if(schedule.number_of_available_seats > 0) {
              this.results.push(schedule);
            }
          }else{
            this.results.push(schedule);
          }
          this.originalResults.push(schedule);
          this.operators.add(schedule.operator);
        }else{
          this.results =[]
        }
      }
      
    
    });

   
    // this.updateTimeRanges([...timeRangesFound]);
    this.availableSeatTypes = [...seatTypesFound];
   
  } else {
    console.warn("No schedules found in response data.");
  }

  this.cd.detectChanges();
}

private handleStreamError(err: any) {
  this.streamSubscription?.unsubscribe();
  this.isResultsLoading = false;
  this.isResultsAllLoading = false;
}

getHours(time: string): number {
  let hours = parseInt(time.slice(0, 2));
  return hours;
}

getMinutes(time: string): number {
  let minutes =  parseInt(time.slice(3, 5));
  return minutes;
}

private handleStreamComplete() {
  // Ensure results are sorted
  this.results = this.sortByDepartureTime(this.results);

  
  // Keep track of original unfiltered results
  this.originalResults = [...this.results];
  
  
  // Ensure seat types and time ranges are updated dynamically for return trips
  this.availableSeatTypes = [...this.seatTypesFound];

  this.updateTimeRanges([...this.timeRangesFound]);
  
  // Apply filters dynamically for return schedules if applicable
  if (this.isReturnTicket && this.return_schedule) {
    let filteredReturnSchedules = this.return_schedule.filter((schedule) => {
      return this.isTimeMatching(schedule, this.selectedTimeRanges) &&
             this.isSeatTypeMatching(schedule, this.selectedSeatTypes) &&
             this.isOperatorMatching(schedule, this.selectedOperators);
    });
    this.results = [...this.results, ...filteredReturnSchedules];
  }
  
  this.isSearchingOrBooked = false;
  this.isResultsAllLoading = false;
  
  // Unsubscribe to prevent memory leaks
  this.streamSubscription?.unsubscribe();  // Consider unsubscribing if the stream is no longer needed
  this.cd.detectChanges();
}


  trackByBooking(result:any) {
    return result.id;
 }


 loadDesktopScript(): void {
  // Prevent multiple script injections
  if (document.getElementById('globalAmlScript')) {
    return;
  }

  // Create the script element
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.id = 'globalAmlScript'; // Unique ID to prevent multiple injections

  // Ad slots configuration
  const adSlots = [
    { z: '8e5c9e81-34db-47a2-a612-8f8b613c86fc', ph: 'yehtu_8e5c9e8134db47a2a6128f8b613c86fc_zone_125480_sect_59126_site_52307' },
    { z: '8d0993d8-2d8f-40c9-969e-0160b22bbebd', ph: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307' },
    { z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307' },
    { z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307' },
    { z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307' },
    { z: '2e47ebaa-4cd3-4517-890d-73002a99a2e2', ph: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307' }
  ];

  const scriptContent = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
      ${adSlots.map(slot => `
        globalAml.defineSlot({ 
          z: '${slot.z}', 
          ph: '${slot.ph}', 
          i: 'inv-nets', 
          s: '86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', 
          sender: 'yehtu' 
        });
      `).join('')}
      globalAml.singleRequest("yehtu");
    });
  `;

  script.text = scriptContent;
  this.renderer.appendChild(document.body, script);
}


loadMobileScript(): void {
  // Prevent multiple script injections
  if (document.getElementById('globalAmlMobileScript')) {
    return;
  }

  // Create the script element
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.id = 'globalAmlMobileScript'; // Unique ID to prevent multiple injections

  // Ad slots configuration
  const adSlots = [
    { z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307' },
    { z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307' },
    { z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307' },
    { z: '27641268-2842-43d1-8342-87da9ce25b3e', ph: 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307' },
    { z: 'ee955c02-ec3b-4635-9630-27bd45ff897e', ph: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307' },
    { z: '83f705e0-0010-4481-87be-1348fab1446b', ph: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307' }
  ];

  // Dynamically create script content
  const scriptContent = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
      ${adSlots.map(slot => `
        globalAml.defineSlot({ 
          z: '${slot.z}', 
          ph: '${slot.ph}', 
          i: 'inv-nets', 
          s: '86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', 
          sender: 'yehtu' 
        });
      `).join('')}
      globalAml.singleRequest("yehtu");
    });
  `;

  script.text = scriptContent;
  this.renderer.appendChild(document.body, script);
}

loadAds(): void {
  const screenWidth = window.innerWidth;

  const ads = [
    { id: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', minWidth: 992 },
    { id: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', minWidth: 992 }
  ];

  ads.forEach(ad => {
    if (screenWidth >= ad.minWidth) {
      const adElement = document.getElementById(ad.id);

      if (adElement && !adElement.querySelector('script')) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
          (window.globalAmlAds = window.globalAmlAds || []).push(function() {
            globalAml.display('${ad.id}');
          });
        `;
        this.renderer.appendChild(adElement, script);
      }
    }
  });
}


hideFeedback(){
  this.hideFeedbackBtn=true;
}






}
