import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Content-Type':  'application/json',
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http:HttpClient
  ) { }

  submitBooking(booking:any){
    return this.http.post<any>(environment.bookingApi,booking,httpOptions);
  }

  submitBookingEasy(reference:any,operator:any,booking:any){
    return this.http.post(environment.easyUrl +'?transaction_reference='+reference+'&operator_alias='+operator.alias,booking);
  }


  submitBookingReturn(booking:any){
    return this.http.post(environment.bookingApiV2,booking);
  }

  getInsuarance(query:any){
    return this.http.get(`${environment.getInsurance}` + '&leaving_from='+query.leaving_from+'&going_to='+query.going_to ,httpOptions)
    .pipe(
      retry(3),
    );
  }

  fetchData(query:any){
    return this.http.get(`${environment.bookingCheckPayApi}` +'?m='+ query ,httpOptions);
  }

  fetchDataWhatsApp(query:any){
    return this.http.get(`${environment.bookingCheckPayApi}` +'?booking_id='+ query ,httpOptions);
  }

  


  submitCheckPay(data:any,id){
    return this.http.post<any>(environment.bookingCheckPayApi +'?m='+ id,data,httpOptions);
  }
  // fetchData(id: string) {
  //   this.http.get(`https://api.buupass.com/fetch/data/${id}`).subscribe(
  //     response => {
  //       this.data = response;
  //       // Handle the response data as needed
  //     },
  //     error => {
  //       console.error('There was an error!', error);
  //     }
  //   );
  // }


}
