
  <div class="row">
      <div (click)="toggle($event)" class="col-md-12 {{isFocused ? 'focus':''}}" [ngClass]="{'is-open':isOpen,'input-opened':isOpen}">
        <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
          <div class="theme-search-area-section-inner">
            <i class="theme-search-area-section-icon fa fa-map-marker-alt "></i>
            <input (ngModelChange)="checkIfOpen($event)" class="theme-search-area-section-input " type="text" [ngbTypeahead]="search" id="unique-name" [(ngModel)]="location" type="text"  placeholder="{{placeholder}}" data-provide="typeahead">
          </div>
        </div>
      </div>
  </div>

<div class="row" [@dialog]>
    <div class="col-md-12" >
      <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}" *ngIf="!options">
        <span>Loading</span>
      </ul>
      <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}" *ngIf="options">
        <ng-container>
          <li id="lif{{i}}"
            *ngFor="let option of options.slice(0, 8); let i = index"
            (click)="optionSelect(option, i, $event)"
          >
          <i class="fa fa-map-marker-alt _mr-10" aria-hidden="true"></i> {{option}}
          </li>
        </ng-container>

      </ul>
    </div>
</div>


