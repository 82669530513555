import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/index';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.css']
})
export class AccountHeaderComponent implements OnInit {
  @Input() activeTab: string = 'profile'; // ✅ this line fixes the error
  @Output() scrollToSection = new EventEmitter<string>();

  scroll(tab: string) {
    this.scrollToSection.emit(tab);
  }

  account: boolean = false;
  notifications: boolean = false;
  trips: boolean = false;
  isLoggedIn: any;
  user: User;

  constructor(private router: Router) {}

  ngOnInit() {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');

    console.log(this.isLoggedIn);
    if (!this.isLoggedIn) {
      this.router.navigateByUrl('/home');
    } else {
      this.user = new User().deserialize(JSON.parse(localStorage.getItem('user')));
    }

    if (this.router.url === '/my-account') {
      this.account = true;
    } else if (this.router.url === '/my-notifications') {
      this.notifications = true;
    } else if (this.router.url === '/my-trips') {
      this.trips = true;
    }
  }

  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/home']);
  }
}
