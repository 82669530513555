<div class="hidden-sm hidden-md hidden-lg ">
  <app-nav ></app-nav>
</div>
<div class="visible-lg">
<app-headerhome ></app-headerhome>
</div>
<div class="container _mt-50 bg-buupass">
</div>

<div class="theme-page-section _p-20 _mt-50 ">
<!-- Main Account Section -->
<div class="container py-4">
  <div class="row justify-content-center">
    <!-- Sidebar -->
    <div class="col-md-4 col-lg-3 mb-4">
      <app-account-header
        [activeTab]="activeTab"
        (scrollToSection)="scrollTo($event)">
      </app-account-header>
    </div>

    <!-- Content -->
    <div class="col-md-10 col-lg-8">

      <!-- Profile Completion -->
      <div class="card p-4 rounded-3 shadow-sm mb-4">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0 buupass-text">Complete your Profile</h5>
          <span class="fw-semibold text-success">30%</span>
        </div>
        <p class="text-muted mt-2 mb-3">Get the best out of Buupass by adding the remaining details!</p>
        <div class="progress mb-3" style="height: 8px;">
          <div class="progress-bar" style="width: 30%; background-color: #EC5B24;"></div>
        </div>
        <div class="d-flex flex-wrap gap-2">
          <ng-container *ngIf="user?.email">
            <button class="btn btn-outline-success btn-sm rounded-pill px-3" >✔ Verified Email ID</button>
          </ng-container>
          <ng-container *ngIf="!user?.email">
            <button class="btn buupass-btn-primary btn-sm rounded-pill px-3" data-toggle="modal" data-target="#buupassEmailModal">+ Add your email ID</button>
          </ng-container>
          <ng-container *ngIf="user?.phone_number">
            <button class="btn btn-outline-success btn-sm rounded-pill px-3" >✔ Verified Mobile Number</button>
          </ng-container>
          <ng-container *ngIf="!user?.phone_number">
            <button class="btn buupass-btn-primary btn-sm rounded-pill px-3" data-toggle="modal" data-target="#buupassPhoneModal">+ Add your mobile number</button>
          </ng-container>
          <button class="btn buupass-btn-primary btn-sm rounded-pill px-3" data-toggle="modal" data-target="#buupassProfileModal">+ Complete Basic Info</button>
        </div>
      </div>


                <!-- Profile Info -->
              <!-- Profile Info -->
          <div *ngIf="activeTab === 'profile'" class="profile-section">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <h5 class="section-title">Profile</h5>
                <p class="section-subtitle">Basic info, for a faster booking experience</p>
              </div>
              <button class="btn btn-outline-primary btn-sm rounded-pill px-3" data-toggle="modal" data-target="#buupassProfileModal">
                ✏️ Edit
              </button>
            </div>

            <div class="profile-row">
              <span>Name</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
            <div class="profile-row">
              <span>Birthday</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
            <div class="profile-row">
              <span>Gender</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
            <div class="profile-row">
              <span>City</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
            <div class="profile-row">
              <span>Nationality</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
            <div class="profile-row">
              <span>Passport No.</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassProfileModal">+ Add</button>
            </div>
          </div>

          <!-- Login Details -->
          <div *ngIf="activeTab === 'login'" class="profile-section">
            <h5 class="section-title">Login Details</h5>
            <p class="section-subtitle">Manage your mobile number, email address and password</p>

            <div class="profile-row">
              <span>Mobile Number</span>
              <span *ngIf="user?.phone_number; else noPhone">
                {{ user.phone_number }} ✅
              </span>
              <ng-template #noPhone>
                <button class="add-btn" data-toggle="modal" data-target="#buupassPhoneModal">
                  + Add your mobile number
                </button>
              </ng-template>
            </div>
            <div class="profile-row">
              <span>Email ID</span>
              <span *ngIf="user?.email; else noEmail">
                {{ user.email }} ✅
              </span>
              <ng-template #noEmail>
                <button class="add-btn" data-toggle="modal" data-target="#buupassEmailModal">
                  + Add your email ID
                </button>
              </ng-template>
            </div>
            <div class="profile-row">
              <span>Password</span>
              <button class="add-btn" data-toggle="modal" data-target="#buupassPasswordModal">Change Password</button>
            </div>
          </div>

          <!-- Co-Travellers -->
          <div *ngIf="activeTab === 'travellers'" class="profile-section">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <h5 class="section-title">Co-Travellers</h5>
              <button class="btn btn-outline-primary btn-sm rounded-pill" (click)="openModal('traveller')">+ Add Traveller</button>
            </div>
            <p class="section-subtitle">Add, remove and update your traveller list</p>
          </div>

          <!-- Logged In Devices -->
          <div *ngIf="activeTab === 'devices'" class="profile-section">
            <h5 class="section-title">Logged In Devices</h5>
            <p class="section-subtitle">Check all the devices where your account is logged in</p>
          </div>

    </div>
  </div>
</div>
</div>

<!-- Profile Modal -->
<!-- Profile Modal -->
<div class="modal fade" id="buupassProfileModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button id="closeProfile" type="button" class="close" data-dismiss="modal">&times;</button>
        <h5 class="modal-title fw-bold">Edit Profile</h5>
      </div>

      <div class="modal-body">
        <form class="container-fluid">
          <!-- Full Name & Birthday -->
          <div class="row g-3 mb-3">
            <div class="col-md-6">
              <label class="form-label">Full Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="form.fullName" name="fullName" required />
            </div>
            <div class="col-md-6">
              <label class="form-label">Birthday</label>
              <input type="date" class="form-control" [(ngModel)]="form.birthday" name="birthday" />
            </div>
          </div>

          <!-- Gender & Marital Status -->
          

          <!-- City & Nationality -->
          <div class="row g-3 mb-4">
            <div class="col-md-6">
              <label class="form-label">City</label>
              <input type="text" class="form-control" [(ngModel)]="form.city" name="city" />
            </div>
            
          </div>

          <!-- Passport Details -->
          <hr />
          <div class="mb-2">
            <h6 class="mb-1">Passport Details</h6>
            <small class="text-muted">Add your Passport details for a faster booking experience</small>
          </div>

          <div class="row g-3">
            <div class="col-md-6">
              <label class="form-label">Passport Number</label>
              <input type="text" class="form-control" [(ngModel)]="form.passportNumber" name="passportNumber" />
            </div>
            <div class="col-md-3">
              <label class="form-label">Issuing Country</label>
              <select class="form-select" [(ngModel)]="form.issuingCountry" name="issuingCountry">
                <option value="">-Select-</option>
                <option value="Kenya">Kenya</option>
                <option value="Uganda">Uganda</option>
                <option value="Tanzania">Tanzania</option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="form-label">Expiry Date</label>
              <input type="date" class="form-control" [(ngModel)]="form.passportExpiry" name="passportExpiry" />
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary"  data-dismiss="modal" >Cancel</button>
        <button type="button" class="btn btn-primary px-4" (click)="submitProfile()">Save</button>
      </div>
    </div>
  </div>
</div>


  <!-- Mobile Number Modal -->
<div class="modal fade" id="buupassPhoneModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Your Mobile Number</h5>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body">
        <p class="text-muted">For a faster booking experience, exclusive offers and rewards</p>

        <label class="form-label">Mobile Number</label>
        <div class="input-group ">
          <span class="input-group-text">
            <img src="https://flagcdn.com/16x12/ke.png" alt="KE Flag" class="me-1" />
            +254
          </span>
          <input type="tel"
                 class="form-control"
                 [(ngModel)]="phoneNumber"
                 name="phone"
                 placeholder="eg. 999999999"
                 maxlength="9"
                 pattern="^[0-9]{9}$"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="submitPhoneNumber()">Submit</button>
      </div>
    </div>
  </div>
</div>


<!-- Change Password Modal -->
<div class="modal fade" id="buupassPasswordModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change Password?</h5>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button> -->
      </div>

      <div class="modal-body">
        <form>
          <!-- Old Password -->
          <div class="mb-3">
            <label class="form-label">Old Password</label>
            <input
              [type]="showOldPassword ? 'text' : 'password'"
              class="form-control"
              [(ngModel)]="passwordForm.oldPassword"
              name="oldPassword"
              placeholder="Enter Old Password"
            />
          </div>

          <!-- New Password -->
          <div class="mb-3 position-relative">
            <label class="form-label">New Password</label>
            <input
              [type]="showNewPassword ? 'text' : 'password'"
              class="form-control"
              [(ngModel)]="passwordForm.newPassword"
              name="newPassword"
              placeholder="Enter New Password"
            />
          </div>

          <!-- Confirm Password -->
          <div class="mb-3 position-relative">
            <label class="form-label">Confirm New Password</label>
            <input
              [type]="showConfirmPassword ? 'text' : 'password'"
              class="form-control"
              [(ngModel)]="passwordForm.confirmPassword"
              name="confirmPassword"
              placeholder="Confirm New Password"
            />
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitPassword()">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- Other Modal -->
<div class="modal fade" id="buupassOtherModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content border-primary">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="btn-close btn-close-white" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="modalTitle === 'Mobile Number'">
          <label class="form-label">Mobile Number</label>
          <input type="tel" class="form-control" [(ngModel)]="modalValue" placeholder="+254...">
        </div>
        <div *ngIf="modalTitle === 'Password'">
          <label class="form-label">New Password</label>
          <input type="password" class="form-control" [(ngModel)]="modalValue">
        </div>
        <div *ngIf="modalTitle === 'Traveller'">
          <p><strong>Traveller Modal (Coming Soon)</strong></p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitModal()">Save</button>
      </div>
    </div>
  </div>
</div>