<div class="card shadow-sm text-center p-4 rounded-3">
  <div class="position-relative">
    <img
      src="assets/img/company1/user.png"
      alt="User Avatar"
      class="rounded-circle mx-auto"
      style="width: 80px; height: 80px; object-fit: cover; border: 3px solid #EC5B24;"
    />
    <span
      class="position-absolute top-0 end-0 translate-middle p-1 bg-white border rounded-circle"
      title="Edit Profile Photo"
      style="cursor: pointer;"
    >
      <i class="fa fa-pencil text-secondary"></i>
    </span>
  </div>

  <small class="mt-3 text-muted d-block fw-semibold">PERSONAL PROFILE</small>

  <ul class="list-group mt-3">
    <li
      class="list-group-item"
      [ngClass]="{ 'active': activeTab === 'profile' }"
      (click)="scroll('profile')"
      [ngStyle]="activeTab === 'profile' ? { backgroundColor: '#EC5B24', color: '#fff' } : {}"
    >
      <a
        href="#"
        (click)="$event.preventDefault()"
        class="d-flex align-items-center gap-2 text-decoration-none"
        [ngStyle]="activeTab === 'profile' ? { color: '#fff' } : { color: '#000' }"
      >
        <i class="fa fa-user"></i> Profile
      </a>
    </li>

    <li
      class="list-group-item"
      [ngClass]="{ 'active': activeTab === 'login' }"
      (click)="scroll('login')"
      [ngStyle]="activeTab === 'login' ? { backgroundColor: '#EC5B24', color: '#fff' } : {}"
    >
      <a
        href="#"
        (click)="$event.preventDefault()"
        class="d-flex align-items-center gap-2 text-decoration-none"
        [ngStyle]="activeTab === 'login' ? { color: '#fff' } : { color: '#000' }"
      >
        <i class="fa fa-bell"></i> Login Details
      </a>
    </li>

    <li
      class="list-group-item"
      [ngClass]="{ 'active': activeTab === 'travellers' }"
      (click)="scroll('travellers')"
      [ngStyle]="activeTab === 'travellers' ? { backgroundColor: '#EC5B24', color: '#fff' } : {}"
    >
      <a
        href="#"
        (click)="$event.preventDefault()"
        class="d-flex align-items-center gap-2 text-decoration-none"
        [ngStyle]="activeTab === 'travellers' ? { color: '#fff' } : { color: '#000' }"
      >
        <i class="fa fa-users"></i> Co-Travellers
      </a>
    </li>

    <li
      class="list-group-item"
      [ngClass]="{ 'active': activeTab === 'devices' }"
      (click)="scroll('devices')"
      [ngStyle]="activeTab === 'devices' ? { backgroundColor: '#EC5B24', color: '#fff' } : {}"
    >
      <a
        href="#"
        (click)="$event.preventDefault()"
        class="d-flex align-items-center gap-2 text-decoration-none"
        [ngStyle]="activeTab === 'devices' ? { color: '#fff' } : { color: '#000' }"
      >
        <i class="fa fa-desktop"></i> Logged In Devices
      </a>
    </li>

    <li class="list-group-item">
      <a
        href="#"
        (click)="logoutUser(); $event.preventDefault()"
        class="d-flex align-items-center gap-2 text-decoration-none fw-semibold"
        style="color: #D50000;"
      >
        <i class="fa fa-lock"></i> Logout
      </a>
    </li>
  </ul>
</div>
