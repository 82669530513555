<div class="skeleton-filters flex flex-col gap-4 p-4">
    <!-- Operators Section -->
    <div class="skeleton-section">
      <div class="skeleton-title"></div>
      <div class="skeleton-line w-3/4"></div>
      <div class="skeleton-line w-2/3"></div>
      <div class="skeleton-line w-4/5"></div>
    </div>
  
  
  </div>
  