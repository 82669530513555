<div class="skeleton-search-results flex flex-col gap-4 p-4">
    <!-- Skeleton for each search result item -->
    <div class="skeleton-search-item" *ngFor="let item of skeletonArray">
      <div class="skeleton-header flex items-center gap-4">
        <div class="skeleton-circle"></div>
        <div class="skeleton-from-to flex flex-col flex-1 ml-4">
            <div class="skeleton-title w-1/2"></div> <!-- Departure City (From) -->
          </div>
      </div>
  
      <div class="skeleton-body flex gap-4">
        <div class="skeleton-column">
          <div class="skeleton-line w-full"></div>
          <div class="skeleton-line w-3/4"></div>
        </div>
        <div class="skeleton-column">
          <div class="skeleton-line w-full"></div>
          <div class="skeleton-line w-3/4"></div>
        </div>
        <!-- <div class="skeleton-column">
          <div class="skeleton-price"></div>
        </div> -->
      </div>
  
      <div class="skeleton-footer">
        <div class="skeleton-button"></div>
      </div>
    </div>
  </div>
  