import { Injectable, NgZone  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private http:HttpClient,
    private zone: NgZone
  ) { }


  
  onSearch(search : any) {
    if(search.returning){
      return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate+'&return_date='+search.returndate,httpOptions)
      .pipe(
        retry(3),
      );
    }
    return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate,httpOptions)
    .pipe(
      retry(3),
    );
  }
  getSeats(query:any){
    return this.http.get(`${environment.getSeats}`  +'?bus_id='+query.bus_id + '&leaving_from='+query.leaving_from+'&going_to='+query.going_to+'&departing_on='+ '&rsc_id='+query.rsc_id + '&start_point='+query.start_point + '&end_point='+query.end_point +'&alias='+query.alias+'&date='+query.date + '&fleet_registration_id='+query.fleet_registration_id + '&no_of_seats='+query.no_of_seats + '&fare='+query.fare + '&id='+query.id,httpOptions)
    .pipe(
      retry(3),
    );
  }
  
  streamSchedules(leaving_from: string, going_to: string, departing_on: string) {
    console.log(leaving_from)
    const url = `${environment.streamSearchBuses}?leaving_from=${leaving_from}&going_to=${going_to}&departing_on=${departing_on}&token=${environment.api_key}`;
    console.log('Opening SSE connection to:', url);
    const eventSource = new EventSource(url);
  
    return new Observable(observer => {
      eventSource.onopen = (event) => {
        console.log('SSE connection opened:', event);
      };
  
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received SSE data:', data);
          if(data.completed){
            observer.complete()
          }else{
            observer.next(data);
          }
         
        } catch (error) {
          observer.error('Error parsing stream data: ' + error);
        }
      };
  
      eventSource.onerror = (error) => {
        console.error("SSE Error Encountered:", error);
        
        observer.error("SSE connection failed.");  // Trigger error
        
        console.log(" Manually completing the observer due to SSE failure.");
        observer.complete();  // Manually complete
        eventSource.close();
      };

      
      
      
  
      // Cleanup on unsubscribe:
      return () => {
        console.log('Closing SSE connection');
        eventSource.close();
      };
    });
  }
  
      
}

