import { Component, ViewChild, ElementRef, HostListener, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Destinations } from '../models/index';
import { HomeService, DataService, MixpanelService } from '../services/index';
import { formatDate } from '@angular/common';
import { Router } from "@angular/router";
import { SharedscrollstatusService } from '../services/sharedscrollstatus.service';
import { TownsService } from '../services/towns.service';
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  fromPlaceholder = "From";
  toPlaceholder = "To";
  destinationLoading = false;
  pickup = '';
  dropoff = '';
  traveldate = '';
  daterange = '';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today = new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked = false;
  isPickupClicked = false;
  returning = false;
  isReadOnly = false;
  types = [];
  data = [];
  msg = '';
  formdata = {};
  submitted = false;
  loading: boolean;
  currentVersion: boolean = false;
  currentVnumber;
  storedVnumber;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  defaultImage = "assets/img/cities/placeholder.jpg";
  mombasa = "assets/img/cities/mombasa.jpg";
  nairobi = "assets/img/cities/nairobi.jpg";
  kisumu = "assets/img/cities/kisumu.jpg";
  kampala = "assets/img/cities/kampala.jpg";
  dar = "assets/img/cities/dar.jpg";
  bungoma = "assets/img/cities/bungoma.jpg";
  eldoret = "assets/img/cities/eldoret.jpg";

  @ViewChild('stickyForm') menuElement: ElementRef;
  sticky: boolean = false;
  isOpen = false;
  elementPosition: any;

  hideFeedbackBtn=false;

  constructor(
    private service: HomeService,
    private dataService: DataService,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private townService: TownsService,
    private serviceScroll: SharedscrollstatusService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService,
    private pixel: PixelService 
  ) {
    this.reactiveForm = _fb.group({
      pickup: [null, Validators.required],
      dropoff: [null, Validators.required],
      daterange: [formatDate(new Date(), "yyyy-MM-dd", "en") + ':One Way', Validators.required],
      traveldate: [new Date(), Validators.required],
      returndate: [null, null]
    });
  }

  ngOnInit() {
    let message = sessionStorage.getItem('bversion');
    if (message) {
      this.storedVnumber = message;
      this.getCurrentVesrion();
    } else {
      this.storedVnumber = "0.0.0";
      this.getCurrentVesrion();
    }

    localStorage.setItem('search', '');
    this.loadDestinations();
    this.cdRef.detectChanges();
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

      this.mixpanelService.track('PageView', {
        pageName: 'Home page',
        source: 'buupassSite',
        role: 'customer',
      });

      const screenWidth = window.innerWidth;

      if (screenWidth <= 991) {
        this.loadMobileScript();
      }else{
        this.loadDesktopScript();
      }
  
     

      this.loadAds();

  }

  onTravelDateChange = (event) => {
    var traveldate = formatDate(event, "yyyy-MM-dd", "en-KE");
    this.today = event;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event) {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.menuElement.nativeElement.setAttribute('style', 'position:fixed');
      this.sticky = true;
    } else {
      this.sticky = false;
    }
    this.serviceScroll.changeValue(this.sticky);
  }

  get f() {
    return this.reactiveForm.controls;
  }

  formControlValueChanged() {
    const pickupControl = this.reactiveForm.get('pickup');
    const dropoffControl = this.reactiveForm.get('dropoff');
  
    if (pickupControl) {
      pickupControl.valueChanges.subscribe((mode: string) => {
        // this.serviceDepature.changeValue(mode);
      });
    }
  
    if (dropoffControl) {
      dropoffControl.valueChanges.subscribe((mode: string) => {
        // this.serviceDestination.changeValue(mode);
      });
    }
  }

  onFocused(e) {
    this.isClicked = true;
  }

  returnClicked = () => {
    this.returning = true;
    this.isOpen = true;
  }

  closeReturn() {
    this.returning = false;
  }

  onClosed(e) {
    this.isClicked = false;
  }

  onPickupFocused(e) {
    this.isClicked = true;
    this.isPickupClicked = true;
  }

  onPickupClosed(e) {
    this.isClicked = false;
    this.isPickupClicked = false;
  }

  // Updated submitForm to update the URL
  submitForm() {
    if (this.reactiveForm.value.returndate == null) {
      this.returning = false;
      this.isOpen = false;
    }
    this.submitted = true;
  
    // Stop here if the form is invalid
    if (this.reactiveForm.invalid) {
      return;
    }
  
    let value = this.reactiveForm.value;
    var traveldate = formatDate(value.traveldate, "yyyy-MM-dd", "en-KE");
    var returndate = value.returndate ? formatDate(value.returndate, "yyyy-MM-dd", "en-KE") : null;
  
    value.traveldate = traveldate;
    if (this.returning) {
      value.returndate = returndate;
    }
  
    this.formdata = {
      pickup: value.pickup,
      dropoff: value.dropoff,
      pickupname: value.pickup,
      dropoffname: value.dropoff,
      traveldate: traveldate,
      returndate: returndate,
      returning: this.returning
    };
  
    // Tracking event with Mixpanel and Pixel
    this.mixpanelService.track('BusSearch', {
      pickup: value.pickup,
      dropoff: value.dropoff,
      traveldate: traveldate,
      source: 'buupassSite',
      role: 'customer',
    });

    const route = `${value.pickup}-to-${value.dropoff}`;  // e.g., "Nairobi-to-Mombasa"

    let queryParams = {
      fromCityName: value.pickup,
      toCityName: value.dropoff,
      onward: traveldate,
      ...(this.returning && { return: returndate })  // Add 'return' only if returning is true
    };

    this.router.navigate([`/buses/${route}`], { queryParams });


  }

  searchTrip(destination) {
    const tripDetails = {
        'pickup': 'Nairobi',
        'dropoff': destination,
        'pickupname': 'Nairobi',
        'dropoffname': destination,
        'traveldate': this.today,
        'returndate': '',
        'returning': this.returning
    };

    // Track the event only if Mixpanel tracking is required
    if (['Kisumu', 'Kitale', 'Bungoma', 'Busia', 'Mombasa'].includes(destination)) {
        this.mixpanelService.track('BusSearch', {
            pickup: 'Nairobi',
            dropoff: destination,
            traveldate: this.today,
            source: 'buupassSite',
            role: 'customer',
        });
    }
    let tdate = formatDate(this.today,"yyyy-MM-dd","en-KE");

    const route = `Nairobi-to-${destination}`;  // e.g., "Nairobi-to-Mombasa"

    const queryParams = {
      fromCityName: `Nairobi`,
      toCityName: `${destination}`,
      onward: tdate,
    };

    this.router.navigate([`/booking/${route}`], { queryParams });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Bungoma",
      traveldate: this.today,
    });

}

  private loadDestinations() {
    this.destinationLoading = true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
        this.res.data.all_cities.forEach(element => {
          list.push(element.name);
        });
        this.data = list;
        this.townService.changeValue(this.res.data.all_cities);
        let cities = Base64.encode(JSON.stringify(this.res.data.all_cities));
        localStorage.setItem('cities', cities);
        this.types = this.res.data;
        this.destinationLoading = false;
      },
      error => { }
    );
  }

  
  
  private getCurrentVesrion() {
    this.service.getLatestVersion().subscribe(
      response => {
        this.res = response;
        this.currentVnumber = this.res.data.version;
        if (this.currentVnumber != this.storedVnumber) {
          window.location.replace('/');
          sessionStorage.setItem('bversion', this.currentVnumber);
        }
      },
      error => { }
    );
  }



  loadDesktopScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: 'ceeded0d-9d3e-4592-ae7f-b1617c665612', ph: 'yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '537f67f8-0a4c-42b6-9a3c-e526fbebbeaa', ph: 'yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '7f88d539-e711-4e95-ae2c-f2a96217ceba', ph: 'yehtu_7f88d539e7114e95ae2cf2a96217ceba_zone_125485_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadMobileScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: 'aad808d4-176a-4bd1-97fa-01439e396a43', ph: 'yehtu_aad808d4176a4bd197fa01439e396a43_zone_125486_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '701fd587-08f3-48d8-8cd9-0353bedc0bbf', ph: 'yehtu_701fd58708f348d88cd90353bedc0bbf_zone_126380_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'c7d35594-e9d3-4850-8bbc-3f70810e44cc', ph: 'yehtu_c7d35594e9d348508bbc3f70810e44cc_zone_126381_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadAds() {
    const screenWidth = window.innerWidth;

    let adElementId;

    if (screenWidth >= 991) {
        adElementId = 'yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307';
    } else {
        adElementId = 'yehtu_701fd58708f348d88cd90353bedc0bbf_zone_126380_sect_59123_site_52307';
    }

    const adElement = document.getElementById(adElementId);

    if (adElement) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            (window.globalAmlAds = window.globalAmlAds || []).push(function() {
                globalAml.display('${adElementId}');
            });
        `;
        this.renderer.appendChild(document.body, script);
    } 
}

//feedback form handlers
onFeedback(){

}
hideFeedback(){
  this.hideFeedbackBtn=true; 
}


}
