import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BookingService,HomeService, DataService,MixpanelService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate   } from '@angular/common';

import { PassDestinationValueService } from '../services/pass-destination-value.service';
import { PassDepatureValueService } from '../services/pass-depature-value.service';
import * as moment from 'moment';
import { Base64 } from 'js-base64';
import { catchError, EMPTY, filter, Subject, takeUntil, tap,  } from 'rxjs';
import moengage from "@moengage/web-sdk";
import { Destinations } from '../models';
import { Operator } from '../interfaces/booking-interface';


interface SearchParams {
  pickup: string;
  dropoff: string;
  traveldate: string;
  returndate?: string;
  pickupname: string;
  dropoffname: string;
  returning: boolean;
}


interface BusResult {
  id: number;
  origin_city_id: string;
  destination_city_id: string;
  operator: { alias: string };
  route_schedule_id: string;
  number_of_available_seats: number;
  fare: number;
  from: string;
  to: string;
}


interface Seat {
  id: string;
  type: string;
  status: string;
  space_type: string;
  fare: number;
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  @ViewChild('focustarget') focusEl: ElementRef;
  private destroy$ = new Subject<void>();

  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;pickupForm: UntypedFormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  // pickupanddropoff
  pickuplocation:string =""
  dropofflocation:string =""
  pickupreturnlocation:string =""
  dropoffreturnlocation:string =""
  today=new Date();
  isOpen=false;
  bsValue;
  bsInlineValue;
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);
  //traveldate:any;
  data: string[] = [];formdata={};
  destinations: Destinations[] = [];
  formdataTravel:any={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  insurance: any = {};
  insurance_operators:any;
  insurance_cap:any;
  insurance_counter:any;
  return_schedule:any;
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  dummyDropoff:any =[];
  seatsSelected: any=[];
  selectedOptionPassengers;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: SearchParams;
  results: any ;
  isResultsLoading=false;
  seatErrors=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  returnPickUpInit: string;
  returnDropOffInit: string;
  travelDateInit: string;
  returnDateInit:string;
  isUnavailabe=false;
  dataLoading=false;
  isPickupClicked=false;
  isReturnTicket=false;
  isReturnTicketSelected=false;
  isShowingFilter=false;
  totalFare=0;
  fareCurrency='Ksh'
  returning=false;
  submitted=false;
  submittedMashPoa:boolean=false;
  selectedIndex = -1;
  hide_from_ui:number;
  availableSeats=[];
  seats:Seat[][] = [];
  seatData:any =[];
  seatMobile:any =[];
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false;
  discountAmount:any;
  busCheckSelected:any;
  isReadOnly=false;
  types= [];
  sticky: boolean = false;
  elementPosition: any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  selectedBus:any;
  currentBusId:any;
  selectedBusStatus:boolean=false;
  isAcClicked=false;
  isEmptyReturn=false;
  isWater=false;
  finalTravelDate: string;
  finalReturnDateInit:string;
  hide_from_ui_shuttle:number;
  booked_seat_session_id='';
  hideFeedbackBtn=false;
  initialBus:any;
  
 operators: Set<Operator> = new Set<Operator>(); // Assume this is populated from somewhere
   selectedOperators: Set<string> = new Set();
   selectedTimeRanges: Set<string> = new Set();
   sortOption: string = 'Recommended'; 
   filteredSchedules: any[] = [];
   public providers: Set<string> = new Set<string>();
   departure_date: string;  
   departure_time: string;
  //  seats_types: SeatType[];
   originalResults: any[] = [];
   selectedSeatTypes = new Set<string>(); // Store selected seat types
   availableSeatTypes = ['Normal', 'VIP', 'Business', 'Executive'];
   departureTimes = [
     { name: "Nighttime",   iconClass: "icon-nighttime" },
     { name: "Morning",   iconClass: "icon-morning" },
     { name: "Midday",  iconClass: "icon-midday" },
     { name: "Late",   iconClass: "icon-late" }
   ];
 
  
 

  r_pickup:string;
  r_dropoff:string;
  traveldate:string;
  returndate:string; 
  //added this now to support the filters .
  // originalResults: any[] = [];
  // filteredSchedules: any[] = [];
  // private providers: Set<string> = new Set<string>(); // ✅ Change from array to Set

  // operators: Set<Operator> = new Set<Operator>(); // Assume this is populated from somewhere
  // selectedOperators: Set<string> = new Set();
  // selectedTimeRanges: Set<string> = new Set();
  // selectedSeatTypes = new Set<string>(); // Store selected seat types
  // availableSeatTypes = ['Normal', 'VIP', 'Business', 'Executive'];
  // departureTimes = [
  //   { name: "Nighttime",   iconClass: "icon-nighttime" },
  //   { name: "Morning",   iconClass: "icon-morning" },
  //   { name: "Midday",  iconClass: "icon-midday" },
  //   { name: "Late",   iconClass: "icon-late" }
  // ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeService:HomeService,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private mixpanelService: MixpanelService,
    private renderer: Renderer2,

  ) {
  
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:['', Validators.required],
      returndate:[new Date(), Validators.required]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required]
    });
     // pickupanddropoff
    this.pickupForm = _fb.group({
      pickupLocation:['', Validators.required],
      dropoffLocation:['', Validators.required],
    });
    
   }
   returnClicked(){
    this.returning=!this.returning;
    this.isOpen=true;
  }
  closeReturn = () =>{
    this.returning=false;
  }


  ngOnInit(): void {
    this.loadDestinations();
    // Handle navigation scroll behavior
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => window.scrollTo(0, 0));

    // Get message from DataService
    this.dataService.returnSearchValueChange.pipe(
      takeUntil(this.destroy$),
      tap(message => console.log("Current message:", message))
    ).subscribe(message => this.processMessage(message));
    this.searchBuses(this.bookingdetails);
    this.trackPageView();
    this.loadScripts();
  }
  
  /**
   * Processes the message from dataService or initializes booking details.
   */
  private processMessage(message: string): void {
    if (!message) {
      this.initializeBookingDetails();
    } else {
      this.bookingdetails = JSON.parse(Base64.decode(message));
    }
  
    this.setFormPlaceholders();
    this.serviceDepature.changeValue(this.pickUpInit);
    this.serviceDestination.changeValue(this.dropOffInit);
  
    if (this.bookingdetails.returning) {
      this.returnPickUpInit = this.bookingdetails.dropoffname;
      this.returnDropOffInit = this.bookingdetails.pickupname;
      this.returnDateInit = this.bookingdetails.returndate ?? '';
    }
  }
  
  /**
   * Initializes booking details from query params.
   */
  private initializeBookingDetails(): void {
    const queryParams = this.route.snapshot.queryParamMap;
  
    this.r_pickup = queryParams.get('fromCityName') ?? '';
    this.r_dropoff = queryParams.get('toCityName') ?? '';
    this.traveldate = queryParams.get('onward') ?? formatDate(new Date(), 'yyyy-MM-dd', 'en-KE');
    this.returndate = queryParams.get('return') ?? '';

    this.returning = !!this.returndate;
    if (this.returning) {
      this.returndate = formatDate(this.returndate, 'yyyy-MM-dd', 'en-KE');
    }
  
      this.bookingdetails = {
        pickup: this.r_pickup,
        dropoff: this.r_dropoff,
        pickupname: this.r_pickup,
        dropoffname: this.r_dropoff,
        traveldate: this.traveldate,
        returndate: this.returndate,
        returning: this.returning
      };

  }
  
  /**
   * Sets form placeholders and initializes booking details.
   */
  private setFormPlaceholders(): void {
    this.pickUpInit = this.bookingdetails.pickupname;
    this.dropOffInit = this.bookingdetails.dropoffname;
    this.travelDateInit = this.bookingdetails.traveldate;
    this.fromPlaceholder = this.bookingdetails.pickupname;
    this.toPlaceholder = this.bookingdetails.dropoffname;
    this.returning = this.bookingdetails.returning;

    setTimeout(() => {
      console.log(this.bookingdetails);
    
      // Convert traveldate
      const validTravelDate = this.parseDate(this.bookingdetails.traveldate);
    
      // Patch form with correctly formatted travel date
      this.reactiveForm.patchValue({
        traveldate: validTravelDate ? formatDate(validTravelDate, "dd-MM-yyyy", "en-KE") : '',
        pickup: this.pickUpInit,
        dropoff: this.dropOffInit
      });
    
      // Convert returndate (if applicable)
      if (this.bookingdetails.returning && this.bookingdetails.returndate) {
        const validReturnDate = this.parseDate(this.bookingdetails.returndate);
        this.reactiveForm.patchValue({ returndate: validReturnDate });
      }
    }, 0);
    
    

  }

  /**
 * Parses date string and returns a valid Date object.
 * Supports formats: "dd/MM/yyyy", "yyyy-MM-dd", "dd-MM-yyyy"
 */
  private parseDate(dateStr: string): Date | null {
    if (!dateStr) return null;

    // Try different date formats
    const patterns = [
      { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, order: ["d", "m", "y"] }, // dd/MM/yyyy
      { regex: /^(\d{4})-(\d{2})-(\d{2})$/, order: ["y", "m", "d"] }, // yyyy-MM-dd
      { regex: /^(\d{2})-(\d{2})-(\d{4})$/, order: ["d", "m", "y"] } // dd-MM-yyyy
    ];

    for (const pattern of patterns) {
      const match = dateStr.match(pattern.regex);
      if (match) {
        const parts = match.slice(1).map(num => parseInt(num, 10));
        const year = parts[pattern.order.indexOf("y")];
        const month = parts[pattern.order.indexOf("m")] - 1; // Month is zero-based
        const day = parts[pattern.order.indexOf("d")];

        return new Date(year, month, day);
      }
    }

    console.warn("Unrecognized date format:", dateStr);
    return null; // Return null if format is unrecognized
  }
  
  /**
   * Tracks page view using Mixpanel.
   */
  private trackPageView(): void {
    this.mixpanelService.track('PageView', {
      pageName: 'Schedules page',
      source: 'buupassSite',
      role: 'customer',
    });
  }
  
  /**
   * Loads scripts required for desktop, mobile, and ads.
   */
  private loadScripts(): void {
    this.loadDesktopScript();
    this.loadMobileScript();
    this.loadAds();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  ngAfterViewInit(): void {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.checkDates();
  }

  toggleOperatorSelection(operator: string): void {
    console.log('Toggling operator:', operator);
    
    // Normalize the name to lowercase to avoid mismatch issues
    const normalizedOperator = operator.trim().toLowerCase();
  
    if (this.selectedOperators.has(normalizedOperator)) {
      this.selectedOperators.delete(normalizedOperator);
    } else {
      this.selectedOperators.add(normalizedOperator);
    }
  
    console.log('Updated operators:', Array.from(this.selectedOperators));
    
    // Apply filters after updating selection
    this.applyFilters();
  }
  
  
  filterByTimeRange(schedules: any[]): any[] {
    return schedules.filter(schedule => {
      const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);
      const hour = departureTime.getHours(); 
  
      // Check if current hour falls within a selected range
      const timeRange = this.getTimeRangeForHour(hour); 
      return this.selectedTimeRanges.has(timeRange);
    });
  }
  
  
    
   // Function to toggle departure time selection
   toggleTimeSelection(timeRange: string): void {
    if (this.selectedTimeRanges.has(timeRange)) {
      this.selectedTimeRanges.delete(timeRange);
    } else {
      this.selectedTimeRanges.add(timeRange);
    }
    this.applyFilters(); 
  }
  toggleSeatTypeSelection(seatType: string): void {
    console.log('Toggling seat type:', seatType);
    if (this.selectedSeatTypes.has(seatType)) {
      this.selectedSeatTypes.delete(seatType);
    } else {
      this.selectedSeatTypes.add(seatType);
    }
    console.log('Updated seat types:', Array.from(this.selectedSeatTypes));
    this.applyFilters(); // Reapply filters after selection
  }
  
 
  
  
  
  sortByProvider(results: any[]): void {
    results.sort((a, b) => (a.provider || "").localeCompare(b.provider || ""));
  }
  
  
  


  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
    this.serviceDepature.changeValue(pickup);
    this.serviceDestination.changeValue(dropoff);

    setTimeout(()=>{
      this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE")})
    },0)

  }


    checkDates=() =>{
      let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
      let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
      var date = new Date(traveldate);
      this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
      this.yesterday=new Date(date.setDate(date.getDate()+1));
      this.tomorrow =new Date(date.setDate(date.getDate()+2))
      this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
        if(traveldate == today)
            return true;
        else
          return false;
    }


  changeDate=(selected) =>{
    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
    this.checkDates();
    this.bookingdetails={
      'pickup' : this.pickUpInit,
      'dropoff' : this.dropOffInit,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
      'returndate' :this.returnDateInit,
      'returning' : this.returning
    }

    this.searchBuses(this.bookingdetails);
  }


  interChange=()=>{
    this.changeI=!this.changeI;
       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
       let webformdata: SearchParams = {
        pickup: dropOff ?? '',  // Ensure it's a string, default to ''
        dropoff: pickUp ?? '',
        pickupname: dropOff ?? '',
        dropoffname: pickUp ?? '',
        traveldate: this.travelDateInit ?? '',  // Ensure it's a string
        returndate: this.returnDateInit ?? '',
        returning: this.returning
      };     

      this.interChangeValues(dropOff,pickUp);
      this.searchBuses(webformdata);

  }



  private loadDestinations(): void {
    this.homeService.getDestinations()
      .pipe(
        takeUntil(this.destroy$), // Unsubscribes on component destroy
        catchError(error => {
          console.error('Error loading destinations:', error);
          this.destinationLoading = false; // Handle error state
          return EMPTY;
        })
      )
      .subscribe({
        next: (response: any) => {
          // Assuming response has a structure like { data: { all_cities: Destinations[] } }
          this.destinations = response.data.all_cities;
          // Extract names from the nested structure
          this.data = response.data.all_cities.map((dest: any) => dest.name);
          // Set loading to false
          this.destinationLoading = false;
        },
        error: () => {
          // Handled by `catchError`, won't execute
        }
      });
  }


  backHome(){
    this.router.navigateByUrl('/home');
  }



  submitWebForm(): void {
    this.submitted = true;
    const value = this.reactiveForm.value;
  
    // Format travel date
    const traveldate = this.formatDateInput(value.traveldate);
    this.travelDateInit=traveldate;
    this.checkDates();

    // Initialize return date
    let returndate = '';
  
    if (this.returning) {
      this.returning = true;
      returndate = this.formatDateInput(value.returndate);
      value.returndate = returndate;
    } else {
      this.returning = false;
    }
  
    // Construct webform data
    this.bookingdetails = {
      pickup: value.pickup,
      dropoff: value.dropoff,
      pickupname: value.pickup,
      dropoffname: value.dropoff,
      traveldate,
      returndate: value.returndate || '',
      returning: this.returning
    };
  
  
    // Trigger search function
    this.searchBuses(this.bookingdetails);
  }
  
  /**
   * Helper function to format date input.
   */
  private formatDateInput(date: string | Date): string {
    if (typeof date === 'string') {
      const pattern = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
      const arrayDate = date.match(pattern);
      if (arrayDate) {
        const dt = new Date(+arrayDate[3], +arrayDate[2] - 1, +arrayDate[1]);
        return formatDate(dt, 'yyyy-MM-dd', 'en-KE');
      }
    }
    return formatDate(date, 'yyyy-MM-dd', 'en-KE');
  }
  

      submitMobileForm() {
        this.submitted = true;
        let value = this.reactiveMobileForm.value;
        var traveldate = new Date(formatDate(value.traveldate,"yyyy-MM-dd","en-KE"));
        var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
        value.traveldate=traveldate;
        let mobileformdata={
          'pickup' : value.pickup,
          'dropoff' : value.dropoff,
          'pickupname' : value.pickup,
          'dropoffname' : value.dropoff,
          traveldate: formatDate(traveldate, 'yyyy-MM-dd', 'en-KE'), // Convert Date to string
          returndate: returndate ? formatDate(returndate, 'yyyy-MM-dd', 'en-KE') : '',
          'returning' : this.returning
        }

        
        this.searchBuses(mobileformdata);
      }



      toggleShow(results: any): void {
        // Reset state variables
        this.seatsSelected = [];
        this.totalFare = 0;
        this.dataLoading = false;
        this.hide_from_ui = 0;
        this.hide_from_ui_shuttle = 0;
        this.isBusSelected = false;
        this.setElementWidth('mySidenavDropoff', '0px');
        this.setElementWidth('mySidenav', '0px');
        this.setElementWidth('mySidenavShuttle', '0px');
        this.setElementDisplay('.fadeMe', 'none');
      }


      selectSeats(results: BusResult): void {
        this.selectedBus = results;
        this.selectedBusStatus = true;
        this.dataLoading = true;
        this.hide_from_ui = results.id;
      
        this.setElementWidth('mySidenav', '380px');
        this.setElementDisplay('.fadeMe', 'block');
      
        // Reset seat-related variables
        this.seatsSelected = [];
        this.seatData = [];
        this.seatMobile = [];
        this.totalFare = 0;
        this.seats = [];
      
        // Format travel date
        const travelDate = formatDate(this.travelDateInit, 'd-M-yyyy', 'en-KE');
      
        // Construct query object using shorthand notation
        const query = {
          fleet_registration_id: results.id,
          date: travelDate,
          start_point: results.origin_city_id,
          end_point: results.destination_city_id,
          alias: results.operator.alias,
          rsc_id: results.route_schedule_id,
          bus_id: results.id,
          id: results.id,
          no_of_seats: results.number_of_available_seats,
          fare: results.fare,
          leaving_from: results.from,
          going_to: results.to,
        };
      
        // Track event in MoEngage
        moengage.track_event('Bus Select', {
          SelectedBusOperator: results.operator.alias,
          Fare: results.fare, // No need to parseInt if already a number
          RouteDepatureCity: results.from,
          RouteDestinationCity: results.to,
          AvailableSeats: results.number_of_available_seats,
          BusId: results.id,
          TravelDate: travelDate,
        });
      
        // Fetch available seats
        this.getAvailableSeats(query);
      }



        selectDropoff(): void {
          // Use Renderer2 for better Angular compatibility
          this.setElementWidth('mySidenavDropoff', '380px');
          this.setElementDisplay('.fadeMe', 'block');
          this.setElementWidth('mySidenav', '0px');
          this.setElementWidth('mySidenavShuttle', '0px');
        }

      /**
       * Helper function to set the width of an element by ID.
       */
      private setElementWidth(elementId: string, width: string): void {
        const element = document.getElementById(elementId);
        if (element) {
          this.renderer.setStyle(element, 'width', width);
        }
      }

      /**
       * Helper function to set the display property of an element by class name.
       */
      private setElementDisplay(selector: string, display: string): void {
        const element = document.querySelector(selector);
        if (element) {
          this.renderer.setStyle(element, 'display', display);
        }
      }

      getAvailableSeats(query: any): void {
        this.seatErrors = false;
        this.dataLoading = true;
      
        this.service.getSeats(query)
          .pipe( 
            takeUntil(this.destroy$), // Ensures unsubscription
            catchError(error => {
              this.handleSeatError();
              return EMPTY;
            })
          )
          .subscribe({
            next: response => this.processSeatResponse(response, query),
            error: () => this.handleSeatError()
          });
      }

      /**
       * Processes the seat response data.
       */
      private processSeatResponse(response: any, query: any): void {
        this.seatErrors = false;
        this.seatRes = response;

        const availableSeatsData = this.seatRes.data.available_seats;
        this.availableSeats = availableSeatsData.available_seat_ids;
        this.seats = availableSeatsData.seats;
        this.booked_seat_session_id = availableSeatsData.booked_seat_session_id;

        // Handle discount for 'moderncoast'
        this.IsDiscount = query.alias === 'moderncoast' && availableSeatsData.online_discount > 0;
        if (this.IsDiscount) {
          this.discountAmount = availableSeatsData.online_discount;
        }

        // Clear previous seat data
        this.seatData = [];
        this.seatMobile = [];

        this.seats = (this.seats ?? []) as Seat[][];

        this.seats.forEach((iseats: Seat[], rowIndex: number) => {
          let s = 5, m = 5;
          iseats.forEach(seat => {
            const seatInfo = {
              id: seat.id,
              type: seat.type,
              status: seat.status,
              space_type: seat.space_type,
              fare: seat.fare
            };

            this.seatData.push({ ...seatInfo, s: s--, row: rowIndex });
            this.seatMobile.push({ ...seatInfo, m: m++, row: rowIndex });
          });
        });

        // Sort seat data
        this.seatData.sort((a, b) => a.s - b.s);
        this.seatMobile.sort((a, b) => a.m - b.m);

        // Group data
        this.seatData = Object.values(this.groupByKey(this.seatData, 's'));
        this.seatMobile = Object.values(this.groupByKey(this.seatMobile, 'row'));

        this.dataLoading = false;
      }

      /**
       * Handles errors when fetching seat data.
       */
      private handleSeatError(): void {
        this.seatErrors = true;
        this.dataLoading = false;
      }
      

 
    // Utility function for grouping array objects by a key
    groupByKey(array, key) {
        return array.reduce((acc, obj) => {
            const value = obj[key];
            acc[value] = acc[value] || [];
            acc[value].push(obj);
            return acc;
        }, {});
    }
    

    seatSelect(event: any, result: any, id: number, space_type: string, fare: any) {
      let seatPrice = '0';
      let seatCurrency = 'KSH';
      let seatsPrices = result.seats_types;
  
      // Determine seat price and currency
      if (['mashpoa', 'moderncoast'].includes(result.operator.alias)) {
          const seat = seatsPrices.find(seat => seat.alias === space_type);
          if (seat) {
              seatPrice = seat.fare;
              seatCurrency = seat.currency;
          }
      } else {
          seatsPrices.forEach(seat => {
              if (space_type === seat.alias || (space_type === 'business' && seat.alias === 'business')) {
                  seatPrice = seat.fare;
                  seatCurrency = seat.currency;
              } else if (seat.fare > 0) {
                  seatPrice = seat.fare;
                  seatCurrency = seat.currency;
              }
          });
      }
  
      if (!event.srcElement.parentElement.classList.contains('unavailable')) {
          const seatIndex = this.seatsSelected.findIndex(e => e.id === id);
          
          if (seatIndex !== -1) {
              // Seat already selected, remove it
              event.srcElement.classList.remove("selected");
              event.srcElement.parentElement.classList.remove('selected');
              this.totalFare -= parseInt(seatPrice);
              this.seatsSelected.splice(seatIndex, 1);
          } else {
              // Add seat to selection
              event.srcElement.classList.add("selected");
              event.srcElement.parentElement.classList.add('selected');
              this.totalFare += parseInt(seatPrice);
  
              let seatFare = fare[space_type] || (result.operator.alias === 'easycoach' ? fare.normal : undefined);
              this.seatsSelected.push({ id, space_type, fare: seatFare });
  
              if (this.return_schedule) {
                  Object.assign(this, {
                      busSelected: result,
                      currentBusId: result.id,
                      hide_from_ui: 0,
                      busOperatorSelected: result.operator.alias,
                      isBusSelected: true
                  });
              }
          }
  
          // Track events
          moengage.track_event('Seat Selection', {
              'SelectedSeatNumber': id,
              'SeatType': space_type,
              'BusOperator': result.operator.alias,
              'SeatPrice': parseInt(seatPrice)
          });
  
          this.mixpanelService.track('SeatSelect', {
              seatNumber: id,
              operator: result.operator.alias,
              source: 'buupassSite',
              role: 'customer'
          });
      }
  }



    get f() { return this.reactiveForm.controls; }
     // pickupanddropoff
    get fpickup() { return this.pickupForm.controls; }
    
    //book shuttle
    bookShuttle(result:any){
      this.selectedBus=result;
      this.hide_from_ui_shuttle = result.trip_id;

      this.setElementWidth('mySidenavShuttle', '380px');
      this.setElementDisplay('.fadeMe', 'block');

      this.selectedBusStatus=true;

      const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
        let seatsPrices =result.seats_types;
        let seatPrice ='0';
        for(let i=0; i<seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
        }
        this.totalFare=parseInt(seatPrice);
        this.busSelected=result;
        this.currentBusId=result.id;
        this.busOperatorSelected=result.operator.alias;
        this.isBusSelected =true;

      //TODO implement the shuttle process
      this.formdataTravel={
        'result':result,
        'fare': result.fare,
        'title': result.from + ' to ' + result.to,
        'total_fare':this.totalFare,
        "search_details": this.bookingdetails,
        'pickup' : result.boarding_points.length,
        'dropoff' :result.dropoff_points,
        'totalfare' : this.totalFare,
        'seatsSelected' : this.seatsSelected
      }

      let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let syncData={
          'travel':paytravel,
          'return':'',
          'returning':false
        }
    }

    selectPassOption(id: number,selectedBus) {
      let seatsPrices =selectedBus.seats_types;
      let seatPrice ='0';
      for(let i=0; i<seatsPrices.length; i++){
           if(seatsPrices[i].fare > 0){
             seatPrice=seatsPrices[i].fare;
           }
      }
       //getted from event
       this.seatsSelected=[];
       this.selectedOptionPassengers=id
       this.totalFare=parseInt(this.selectedOptionPassengers) * parseInt(seatPrice)

       for(let i=0;i<this.selectedOptionPassengers;i++)
       {
           const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
           this.seatsSelected.push({id:random(1, 23),space_type:'business'});
       }

     }


  submitForm(result: any): void {
    console.log(this.bookingdetails);
    this.submitted = false;
    this.setElementDisplay('.fadeMe', 'none');
    this.setElementWidth('mySidenavDropoff', '0px');
    this.setElementWidth('mySidenav', '0px');   
    this.ensurePickupAndDropoff(result);
  
    if (this.isReturnTicket && !this.isReturnTicketSelected) {
      this.isReturnTicketSelected = true;
      this.processReturnTicket(result);
      console.log('Processing return ticket selection');
    } else {
      console.log('Processing one-way ticket selection');
      this.processOneWayTicket(result);
    }
  
    // Check if the trip is a return trip
    if (this.bookingdetails.returning) {
      if (!this.isReturnTicket) {
        console.log('Starting return trip');
        this.startReturnTrip();
      } else {
        console.log('Processing return trip');
        this.processPayment(true);
      }
    } else {
      console.log('Processing one-way trip');
      this.processPayment(false);
    }
  
    // Mixpanel Tracking
    this.mixpanelService.track('BusSelected', {
      operator: result.operator.alias,
      pickup: this.fromPlaceholder,
      dropoff: this.toPlaceholder,
      fare: this.formdataTravel.total_fare,
      source: 'buupassSite',
      role: 'customer',
    });
  }
  
    /**
     * Ensures `dropoff_points` and `boarding_points` are populated.
     */
    private ensurePickupAndDropoff(result: any): void {
      if (result.dropoff_points.length < 1) {
        result.dropoff_points.push(this.dropOffInit);
      }
      if (result.boarding_points.length < 1) {
        result.boarding_points.push(this.pickUpInit);
      }
    }
  
    /**
     * Processes return ticket selection.
     */
    private processReturnTicket(result: any): void {
      const resultsData = { ...result };
      const resultSeats = [...this.seatsSelected];
    

     if (resultsData.operator.alias != 'easycoach') {
          resultsData.departure_date = this.formatDepartureDate(resultsData);
      }

      // Handle special case for `mashpoa`
      if (resultsData.operator.alias === 'mashpoa') {
        if (!this.pickupForm.valid) return;
        this.submittedMashPoa = true;
        this.pickupreturnlocation = this.pickupForm.value.pickupLocation;
        this.dropoffreturnlocation = this.pickupForm.value.dropoffLocation;
      } else {
        this.pickupreturnlocation = this.fromPlaceholder;
        this.dropoffreturnlocation = this.toPlaceholder;
      }
    
      this.formdataReturn = this.buildFormData(resultsData, resultSeats);
    }
  
    /**
     * Processes one-way ticket selection.
     */
    private processOneWayTicket(result: any): void {
      const initialData = { ...result };
    
      if (initialData.operator.alias === 'mashpoa') {
        if (!this.pickupForm.valid) return;
        this.submittedMashPoa = !this.returning;
        this.pickuplocation = this.pickupForm.value.pickupLocation;
        this.dropofflocation = this.pickupForm.value.dropoffLocation;
      } else {
        this.pickuplocation = initialData.from;
        this.dropofflocation = initialData.to;
      }
    
     

      if (initialData.operator.alias != 'easycoach') {
        initialData.departure_date = this.formatDepartureDate(initialData);
      }

      this.formdataTravel = this.buildFormData(initialData, this.seatsSelected);
    }
  
    /**
     * Formats `departure_date` based on operator rules.
     */
    private formatDepartureDate(data: any): string {
      if (data.operator.alias === 'easycoach') {
        return moment(data.departure_date, 'MM-DD-YYYY').format('DD/MM/YYYY');
      }
      if (data.is_shuttle) {
        return moment(data.departure_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
      return this.finalReturnDateInit || this.finalTravelDate;
    }
  
    /**
     * Builds form data for travel or return ticket.
     */
    private buildFormData(data: any, seats: any[]): any {
      return {
        result: data,
        fare: data.fare,
        title: `${data.from} to ${data.to}`,
        total_fare: this.totalFare,
        fare_currency: this.fareCurrency,
        search_details: this.bookingdetails,
        search_from: this.fromPlaceholder,
        search_to: this.toPlaceholder,
        pickup: data.boarding_points.length,
        dropoff: data.dropoff_points,
        pickup_location: this.pickuplocation || this.pickupreturnlocation,
        dropoff_location: this.dropofflocation || this.dropoffreturnlocation,
        totalfare: this.totalFare,
        seatsSelected: seats,
        booking_session_id: this.booked_seat_session_id,
        insurance: this.insurance,
        insurance_operators: this.insurance_operators,
        insurance_cap: this.insurance_cap,
        insurance_counter: this.insurance_counter,
      };
    }
  
    /**
     * Initiates the return trip search.
     */
    private startReturnTrip(): void {
      this.interChangeValues(this.bookingdetails.dropoff, this.bookingdetails.pickup);
    
      const returnsearch: SearchParams = {
        pickup: this.bookingdetails.dropoff,
        dropoff: this.bookingdetails.pickup,
        pickupname: this.bookingdetails.dropoff,
        dropoffname: this.bookingdetails.pickup,
        traveldate: this.bookingdetails.returndate ?? '',
        returning: true,
        returndate: '',
      };
    
      this.isReturnTicket = true;
      this.interChangeValues(this.bookingdetails.dropoff, this.bookingdetails.pickup);
      this.searchBuses(returnsearch);
    }
  
    /**
     * Processes payment and navigation.
     */
    private processPayment(isReturnTrip: boolean): void {
      console.log(this.formdataTravel);
      const paytravel = Base64.encode(JSON.stringify(this.formdataTravel));
      const payreturn = isReturnTrip ? Base64.encode(JSON.stringify(this.formdataReturn)) : '';
    
      const syncData = {
        travel: paytravel,
        return: payreturn,
        returning: isReturnTrip,
      };
    
      const pay = Base64.encode(JSON.stringify(syncData));
      const paymessage = Base64.encode('Oops, sorry mate!');
    
      this.dataService.changePaymentMessage(pay);
      this.selectedBusStatus = false;
      this.router.navigate(['/payments', paymessage]);
    }

  searchBuses(search: SearchParams): void {
    const message = Base64.encode(JSON.stringify(search));
    this.storeSearchInLocalStorage(message);
    this.updateQueryParams(search);
    this.trackSearchEvent(search);
  
    if (this.isReturnTicket) {
      console.log('Filtering return results');
      this.filterReturnResults(search);
    } else {
      console.log('Fetching search results');

      this.initializeSearchParams(search);
      this.fetchSearchResults(search);
     
    }
  }

  updateQueryParams(searchParams: SearchParams): void {
    // Construct query params dynamically
    const queryParams: any = {
      fromCityName: searchParams.pickup,
      toCityName: searchParams.dropoff,
      onward: searchParams.traveldate
    };
  
    // Add returndate only if isreturning is true and returndate is present
    if (searchParams.returning && searchParams.returndate) {
      queryParams.return = searchParams.returndate;
    }
  
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling:"replace" // Merge existing query params instead of replacing all
    });
  }
  

  /**
 * Tracks bus search event with MoEngage.
 */
private trackSearchEvent(search: SearchParams): void {
  moengage.track_event('Bus ticket search', {
    DepartureCity: search.pickup,
    ArrivalCity: search.dropoff,
    DepartureDate: search.traveldate,
  });
}

/**
 * Filters return trip results based on the selected bus operator.
 */
private filterReturnResults(search: SearchParams): void {

  const newReturn = this.return_schedule.filter(
    (result) => result.operator.alias === this.busOperatorSelected
  );

  this.providers = new Set<string>();

  console.log( this.busOperatorSelected);
    // If initialBus is not selected, add all providers
    this.providers.add(this.busOperatorSelected);
    console.log( this.providers);
console.log("-------dfdf------------");
    this.results=[];
    setTimeout(() => {
      this.isResultsLoading = false;
      this.results = newReturn;
      console.log( this.results);
    }, 3);
  // this.results = newReturn;
  // console.log( this.results);

  if (this.results.length === 0 && this.submitted) {
    this.handleEmptyReturnResults(search);
  }
}

/**
 * Handles empty return results scenario.
 */
private handleEmptyReturnResults(search: SearchParams): void {
  this.isEmptyReturn = true;
  this.submitted = false;
  this.results = [];
  this.isResultsLoading = true;

  this.initializeSearchParams(search);
  this.fetchSearchResults(search);
}

/**
 * Initializes search parameters.
 */
private initializeSearchParams(search: SearchParams): void {
  this.travelDateInit = search.traveldate;
  search.traveldate = formatDate(search.traveldate, 'dd/MM/yyyy', 'en-KE');

  if (this.returning) {
    search.returndate = formatDate(search.returndate ?? '', 'dd/MM/yyyy', 'en-KE');
  }

  this.pickUpInit = search.pickupname;
  this.dropOffInit = search.dropoffname;
  this.finalTravelDate = search.traveldate;
  this.finalReturnDateInit = search.returndate ?? '';
}

/**
 * Fetches search results from the service.
 */
private fetchSearchResults(search: SearchParams): void {
  this.isResultsLoading = true;
  this.results = [];

  this.service.onSearch(search).pipe(
    catchError(() => {
      this.isResultsLoading = false;
      return EMPTY;
    })
  ).subscribe(response => {
    this.processSearchResults(response);
  });
}

/**
 * Processes search results and filters out invalid operators.
 */
private processSearchResults(response: any): void {
  this.isResultsLoading = false;
  this.res = response;
  this.extractInsuranceData(response);

  if (this.returning) {
    this.results = this.filterValidOperators(response.data.initial_trip);
    this.originalResults = this.results;
    this.return_schedule = response.data.return_trip;
  } else {
    this.results = this.filterValidOperators(response.data.schedule);
    this.originalResults = this.results;
   

    
  }



  if (response.data) {
    this.pickup = this.pickUpInit;
    this.dropoff = this.dropOffInit;
  }
}



/**
 * Extracts insurance data from the response.
 */
private extractInsuranceData(response: any): void {
  this.insurance = response.data.insurance?.data;
  this.insurance_operators = response.data.insurance?.allowed_operators;
  this.insurance_cap = response.data.promo?.cap ?? 0;
  this.insurance_counter = response.data.promo?.counter ?? 0;
}

/**
 * Filters out results where the operator alias is 'easycoach-none'
 * and adds operator aliases to `this.providers`.
 */
private filterValidOperators(resultsData: any[]): any[] {
  return resultsData.filter(result => {
    if (result.operator?.alias) {
      this.providers.add(result.operator.alias); // ✅ Ensure `this.providers` is a Set
    }
    return result.operator.alias !== 'easycoach-none';
  });
}



/**
 * Stores search data in localStorage.
 */
private storeSearchInLocalStorage(message: string): void {
  this.dataService.changeSearchMessage(message);
}

/**
 * Stores search data in localStorage.
 */
private retrieveSearchInLocalStorage(): string {
  return localStorage.getItem('search-buupass') ?? '';
}


  trackByBooking(result:any) {
    return result.id;
 }





loadDesktopScript(): void {
  // Prevent multiple script injections
  if (document.getElementById('globalAmlScript')) {
    return;
  }

  // Create the script element
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.id = 'globalAmlScript'; // Unique ID to prevent multiple injections

  // Ad slots configuration
  const adSlots = [
    { z: '8e5c9e81-34db-47a2-a612-8f8b613c86fc', ph: 'yehtu_8e5c9e8134db47a2a6128f8b613c86fc_zone_125480_sect_59126_site_52307' },
    { z: '8d0993d8-2d8f-40c9-969e-0160b22bbebd', ph: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307' },
    { z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307' },
    { z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307' },
    { z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307' },
    { z: '2e47ebaa-4cd3-4517-890d-73002a99a2e2', ph: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307' }
  ];

  const scriptContent = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
      ${adSlots.map(slot => `
        globalAml.defineSlot({ 
          z: '${slot.z}', 
          ph: '${slot.ph}', 
          i: 'inv-nets', 
          s: '86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', 
          sender: 'yehtu' 
        });
      `).join('')}
      globalAml.singleRequest("yehtu");
    });
  `;

  script.text = scriptContent;
  this.renderer.appendChild(document.body, script);
}


loadMobileScript(): void {
  // Prevent multiple script injections
  if (document.getElementById('globalAmlMobileScript')) {
    return;
  }

  // Create the script element
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.id = 'globalAmlMobileScript'; // Unique ID to prevent multiple injections

  // Ad slots configuration
  const adSlots = [
    { z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307' },
    { z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307' },
    { z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307' },
    { z: '27641268-2842-43d1-8342-87da9ce25b3e', ph: 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307' },
    { z: 'ee955c02-ec3b-4635-9630-27bd45ff897e', ph: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307' },
    { z: '83f705e0-0010-4481-87be-1348fab1446b', ph: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307' }
  ];

  // Dynamically create script content
  const scriptContent = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
      ${adSlots.map(slot => `
        globalAml.defineSlot({ 
          z: '${slot.z}', 
          ph: '${slot.ph}', 
          i: 'inv-nets', 
          s: '86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', 
          sender: 'yehtu' 
        });
      `).join('')}
      globalAml.singleRequest("yehtu");
    });
  `;

  script.text = scriptContent;
  this.renderer.appendChild(document.body, script);
}

loadAds(): void {
  const screenWidth = window.innerWidth;

  const ads = [
    { id: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', minWidth: 992 },
    { id: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', minWidth: 992 }
  ];

  ads.forEach(ad => {
    if (screenWidth >= ad.minWidth) {
      const adElement = document.getElementById(ad.id);

      if (adElement && !adElement.querySelector('script')) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
          (window.globalAmlAds = window.globalAmlAds || []).push(function() {
            globalAml.display('${ad.id}');
          });
        `;
        this.renderer.appendChild(adElement, script);
      }
    }
  });
}


hideFeedback(){
  this.hideFeedbackBtn=true;
}


applyFilters(): void {
  if (!this.originalResults || this.originalResults.length === 0) {
    console.log("Original Results:", this.originalResults);

    console.log("No schedules available to filter.");
    this.filteredSchedules = [];
    this.isResultsLoading = false;
    return;
  }

  console.log("Applying filters...");
  const selectedRanges = new Set([...this.selectedTimeRanges].map(range => range.trim().toLowerCase()));
  const selectedOperators = new Set([...this.selectedOperators].map(op => op.trim().toLowerCase()));
  const selectedSeatTypes = new Set([...this.selectedSeatTypes].map(seat => seat.trim().toLowerCase()));

  console.log("Selected Ranges:", selectedRanges);
  console.log("Selected Operators:", selectedOperators);
  console.log("Selected Seat Types:", selectedSeatTypes);

  let filteredResults = this.originalResults.filter(schedule => {
    const isTimeValid = this.isTimeMatching(schedule, selectedRanges);
    const isOperatorValid = this.isOperatorMatching(schedule, selectedOperators);
    const isSeatTypeValid = this.isSeatTypeMatching(schedule, selectedSeatTypes);
    return isTimeValid && isOperatorValid && isSeatTypeValid;
  });

  console.log("Filtered Results Count:", filteredResults.length);

  // Sort results
  this.results = this.sortSchedules2(filteredResults);
  this.isResultsLoading = false;

  
}



private isTimeMatching(schedule: any, selectedRanges: Set<string>): boolean {
  if (selectedRanges.size === 0) return true;

  if (!schedule.departure_date || !schedule.departure_time) {
    console.warn(`Skipping schedule due to missing departure info: ${schedule.id}`);
    return false;
  }

  const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);
  if (isNaN(departureTime.getTime())) {
    console.warn(`Invalid date format for schedule ID: ${schedule.id}, date: ${schedule.departure_date}, time: ${schedule.departure_time}`);
    return false;
  }

  const hour = departureTime.getHours();
  const timeRange = this.getTimeRangeForHour(hour).trim().toLowerCase();

  return selectedRanges.has(timeRange);
}


private isOperatorMatching(schedule: any, selectedOperators: Set<string>): boolean {
  if (selectedOperators.size === 0) return true;

  if (this.isReturnTicket && this.busOperatorSelected?.operator?.alias !== schedule.operator?.alias) {
    return false;
  }

  if (!schedule.operator?.alias) {
    console.warn(`Skipping schedule due to missing operator info: ${schedule.id}`);
    return false;
  }

  return selectedOperators.has(schedule.operator.alias.trim().toLowerCase());
}

private isSeatTypeMatching(schedule: any, selectedSeatTypes: Set<string>): boolean {
  if (selectedSeatTypes.size === 0) return true;

  if (!schedule.seats_types || schedule.seats_types.length === 0) {
    console.warn(`Skipping schedule due to missing seat types: ${schedule.id}`);
    return false;
  }

  return schedule.seats_types.some((seat) => selectedSeatTypes.has(seat.alias.trim().toLowerCase()));
}

sortSchedules2(schedules: any[]): any[] {
  if (!Array.isArray(schedules) || schedules.length === 0) return [];

  return schedules
    .filter(schedule => this.isValidSchedule(schedule)) // Remove schedules with invalid dates
    .sort((a, b) => this.getScheduleTime(a) - this.getScheduleTime(b));
}

private isValidSchedule(schedule: any): boolean {
  return schedule?.departure_date && schedule?.departure_time && !isNaN(this.getScheduleTime(schedule));
}

private getScheduleTime(schedule: any): number {
  return new Date(`${schedule.departure_date}T${schedule.departure_time}Z`).getTime() || 0;
}

getTimeRangeForHour(hour: number): string {
  if (hour >= 0 && hour < 6) return "Nighttime"; // Midnight to 5:59 AM
  if (hour >= 6 && hour < 12) return "Morning";  // 6 AM to 11:59 AM
  if (hour >= 12 && hour < 18) return "Midday";  // 12 PM to 2:59 PM
  return "Late";                                 // 3 PM to 11:59 PM
}








}
