import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-search-results',
  standalone: false,
  templateUrl: './skeleton-search-results.component.html',
  styleUrl: './skeleton-search-results.component.css'
})
export class SkeletonSearchResultsComponent {
  skeletonArray = Array(5); // Simulate 5 skeleton items
}
