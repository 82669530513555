
<div class=" hidden-md hidden-lg ">
<app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
<app-headerhome ></app-headerhome>
</div>
<div >
<div class="MainView _pos-r">

  <div class=" hidden-md hidden-lg ">
      <app-search-form></app-search-form>
  </div>
  
  
    <div  class="theme-hero-area-body _mt-90 visible-lg visible-md _w-f buupass-container2" >
      
      <div class="visible-lg buupass-container2" style="height: 420px; position: relative;">

        <div class="custom-carousel buupass-container2" *ngIf="banners && banners.length > 0">
          <div *ngFor="let banner of banners; let i = index" 
               class="carousel-img-container" 
               [ngClass]="{'active': i === currentIndex}">
               
            <div *ngIf="banner.includeButton; else imageLink">
              <div>
                <img [src]="banner.imageUrl" class="carousel-img" alt="Carousel Image" />
                <div class="banner-text-container">
                  <h2>{{ banner.bannerText }}</h2>
                  <p>{{ banner.subheadingText }}</p>
                  <a [href]="banner.link">
                    <button>{{ banner.bannerButtonText }}</button>
                  </a>
                </div>
              </div>
            </div>
            <ng-template #imageLink>
              <a [href]="banner.link">
                <img [src]="banner.imageUrl" class="carousel-img" alt="Carousel Image" />
                <div class="banner-text-container">
                  <h2>{{ banner.bannerText }}</h2>
                  <p>{{ banner.subheadingText }}</p>
                </div>
              </a>
            </ng-template>
          </div>
        </div>
        
      
        <div class="theme-search-area-tabs-header _mr-20 mt-10">
          <div class="hidden-xs">
            <img class="hidden-md hidden-lg _ml-50" src="assets/img/company1/logo-mobile.webp" alt="buupass.com" />
          </div>
        </div>
        
 
            <div class="tabbable " #stickyForm  [ngClass]="sticky ? 'sticky':'' " >
              <div class="tab-content _bg-w tab-content-curved">
                <div class="tab-pane active" id="SearchAreaTabs-4" >
                  <div class="theme-search-area " >
                      <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                        <div class="row" >
                              <div class="col-md-3">
                                  <app-from formControlName="pickup"
                                    [options]="data"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                    [placeholder]="fromPlaceholder"
                                    [isReadOnly]="isReadOnly">
                                  </app-from>
                                  <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter depature location
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                  </span>
                              </div>
                              <div class="col-md-3">
                                  <app-dropdown
                                    formControlName="dropoff"
                                    [dropdownoptions]="data"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                    [placeholder]="toPlaceholder"
                                    [isReadOnly]="isReadOnly">
                                  </app-dropdown>
                                  <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter arrival location
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                  </span>
                              </div>
                              <div class="col-md-2">
                                <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
                                  <div class="theme-search-area-section-inner">
                                    <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                    <input type="text"
                                    formControlName="traveldate"
                                    placeholder="Travel Date"
                                    [minDate]="bsValue"
                                    (bsValueChange)="onTravelDateChange($event)"
                                    class="theme-search-area-section-input "
                                    bsDatepicker
                                    [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-2 ">
                              <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white">
                                <div class="theme-search-area-section-inner theme-search-area-section-inner-return">

                                          <div *ngIf="returning" class="theme-search-area-section-inner returndate">
                                            <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>

                                            <i [ngClass]="( isClicked ) ? 'hide-returnicon':''" class="theme-search-area-section-icon lin lin-calendar "></i>
                                            <input  autocomplete="off" type="text"
                                            placeholder="DD-MM-YYYY"
                                            class="theme-search-area-section-input"
                                            formControlName="returndate"
                                            [minDate]="today"
                                            #dp="bsDatepicker"
                                            [isOpen]="isOpen"
                                            bsDatepicker 
                                            [bsValue]=""
                                            [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                            >

                                            <i (click)="closeReturn()" [ngClass]="( isClicked ) ? 'hide-returnicon':''"  class="times-icon  fa fa-times"></i>
                                    </div>
                                    <button *ngIf="!returning" (click)="returnClicked()" class="flix-btn-arrow  DatePlaceholder__button___3XZ5p" type="button"> <i class=" icon-flix fa fa-plus "></i> Add Return
                                    </button>
                              </div>
                            </div>
                          </div>
                              <div class="col-md-2">
                                <button type="submit"  class="theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>
                              </div>
                      </div>
                      </form>
                </div>
                <div class="text-center" style="">
                  <img  height="60" alt="" class="text-center " src="assets/img/pay_via.webp">
              </div>
              
              </div>
            </div>
            </div>
        </div>
    </div>

    <div class="theme-page-section buupass-container _mt-10 theme-page-section-sm ">
        <div  class="xmas-img-web _mt-20   _mob-h" style="">
          <div id='yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307' data-sender='yehtu'></div>
        </div>
        <div  class="xmas-img-mobile  _mt-10  _desk-h" style="">
          <div id='yehtu_701fd58708f348d88cd90353bedc0bbf_zone_126380_sect_59123_site_52307' data-sender='yehtu'></div>
        </div> 
    </div>

    <div class="theme-page-section visible-md visible-lg _mt-10 theme-page-section-sm ">
        <div class="theme-page-section-header theme-page-section-header-white">
          <div class="buupass-home-section">
          <div class="buupass-container">
              <div class="feature-list">
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/payments.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Flexible payments</h5>
                          <p class="content">Pay with M-pesa, credit cards or bank transfers. We have you fully covered.</p>
                        </div>
                   </div>
                  </div>
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/support.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Great customer care</h5>
                          <p class="content">Get excellent customer service available 8:00 AM to 10:00 PM, via phone, chat or email.</p>
                        </div>
                   </div>
                  </div>
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/convenience.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Enjoy convenience</h5>
                          <p class="content">Book anytime from the convenience of your office, home, school,  or market.</p>
                        </div>
                   </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class=" sticky-parent" style="position: relative;">
      <div class="row _ml-10">
        <div class="theme-page-section-header theme-page-section-header-white">
          <h5 class="theme-page-section-title">Our Partners</h5>
          <p class="theme-page-section-subtitle"><span>These bus operators trust us.</span><span>We sell their tickets all in one place.</span></p>
        </div>
        <div class="col-md-12  text-center">
          <div class="theme-about-us-section-body">
            <div class="theme-about-us-section-gallery">
              
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/easy_coach.webp" class="visible-md visible-lg" alt="Easy Coach" height="46" loading="lazy">
                      <img src="assets/img/images/easy_coach.webp" class="hidden-md hidden-lg" alt="Easy Coach" height="25" loading="lazy">
                    </div>
                  </div>
                </div>
                
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/greenline.webp" class="visible-md visible-lg" alt="Greenline" height="70" loading="lazy">
                      <img src="assets/img/images/greenline.webp" class="hidden-md hidden-lg" alt="Greenline" height="40" loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/darexpress.webp" class="visible-md visible-lg"  alt="Dar Express" height="65" loading="lazy">
                      <img src="assets/img/images/darexpress.webp"  class="hidden-md hidden-lg" alt="Dar Express" height="45" loading="lazy">

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/praxigon.webp" class="visible-md visible-lg" alt="Praxigon" height="50" loading="lazy">
                      <img src="assets/img/images/praxigon.webp" class="hidden-md hidden-lg" alt="Praxigon" height="35" loading="lazy">

                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/prestige.webp" class="visible-md visible-lg" alt="Prestige" height="35" loading="lazy">
                      <img src="assets/img/images/prestige.webp" class="hidden-md hidden-lg" alt="Prestige" height="25" loading="lazy">

                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/fillers.webp" class="visible-md visible-lg" alt="Fillers" height="50" loading="lazy">
                      <img src="assets/img/images/fillers.webp" class="hidden-md hidden-lg" alt="Fillers" height="40" loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/pride.webp" class="visible-md visible-lg" alt="Pride" height="40" loading="lazy">
                      <img src="assets/img/images/pride.webp" class="hidden-md hidden-lg" alt="Pride" height="20" loading="lazy">

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/lolwe.webp" class="visible-md visible-lg" alt="Lolwe"  loading="lazy">
                      <img src="assets/img/images/lolwe.webp" class="hidden-md hidden-lg" alt="Lolwe" height="40" loading="lazy">

                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/prince.webp" class="visible-md visible-lg" alt="Prince" height="46" loading="lazy">
                      <img src="assets/img/images/prince.webp" class="hidden-md hidden-lg" alt="Prince" height="45" loading="lazy">

                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/royaleagle.webp" class="visible-md visible-lg" alt="Royaleagle" height="60" loading="lazy">
                      <img src="assets/img/images/royaleagle.webp" class="hidden-md hidden-lg" alt="Royaleagle" height="55" loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/bluenile.webp" class="visible-md visible-lg" alt="Bluenile" height="40" loading="lazy">
                      <img src="assets/img/images/bluenile.webp" class="hidden-md hidden-lg" alt="Bluenile" height="25" loading="lazy">

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/taran.webp" class="visible-md visible-lg" alt="2NK Shuttle" height="80"  loading="lazy">
                      <img src="assets/img/images/taran.webp" class="hidden-md hidden-lg" alt="2NK Shuttle" height="70"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/climax.webp" class="visible-md visible-lg" alt="Climax" height="60" loading="lazy">
                      <img src="assets/img/images/climax.webp" class="hidden-md hidden-lg" alt="Climax" height="55" loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/soores.webp" class="visible-md visible-lg" alt="Soores" height="50" loading="lazy">
                      <img src="assets/img/images/soores.webp" class="hidden-md hidden-lg" alt="Soores" height="38" loading="lazy">

                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/lodwar.webp" class="visible-md visible-lg" alt="Lodwar" height="50" loading="lazy">
                      <img src="assets/img/images/lodwar.webp" class="hidden-md hidden-lg" alt="Lodwar" height="40" loading="lazy">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/2nk.webp" class="visible-md visible-lg" alt="2NK Shuttle" height="25"  loading="lazy">
                      <img src="assets/img/images/2nk.webp" class="hidden-md hidden-lg" alt="2NK Shuttle" height="14"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/classic.webp" class="visible-md visible-lg" alt="Classic Shuttle" height="55"  loading="lazy">
                      <img src="assets/img/images/classic.webp" class="hidden-md hidden-lg" alt="Classic Shuttle" height="50"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/moyaleraha.webp" class="visible-md visible-lg" alt="Pride Shuttle" height="70"  loading="lazy">
                      <img src="assets/img/images/moyaleraha.webp" class="hidden-md hidden-lg" alt="Pride Shuttle" height="60"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/decent_travel.webp" class="visible-md visible-lg" alt="Decent Travel" height="55"  loading="lazy">
                      <img src="assets/img/images/decent_travel.webp" class="hidden-md hidden-lg" alt="Decent Travel" height="40"  loading="lazy">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/sunton.webp" class="visible-md visible-lg" alt="Suntone" height="60"  loading="lazy">
                      <img src="assets/img/images/sunton.webp" class="hidden-md hidden-lg" alt="Suntone" height="60"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/jaberr_classic.webp" class="visible-md visible-lg" alt="Jaberr Classic" height="70"  loading="lazy">
                      <img src="assets/img/images/jaberr_classic.webp" class="hidden-md hidden-lg" alt="Jaberr Classic" height="60"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/smartcoach.webp" class="visible-md visible-lg" alt="Smartcoach" height="65"  loading="lazy">
                      <img src="assets/img/images/smartcoach.webp" class="hidden-md hidden-lg" alt="Smartcoach" height="50"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/promise.webp" class="visible-md visible-lg" alt="Promise" height="70"  loading="lazy">
                      <img src="assets/img/images/promise.webp" class="hidden-md hidden-lg" alt="Promise" height="50"  loading="lazy">
                    </div>
                  </div>
                </div>
              </div>
               <div class="row" data-gutter="10">
                 <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/tcb.webp" class="visible-md visible-lg" alt="TCB" height="80"  loading="lazy">
                      <img src="assets/img/images/tcb.webp" class="hidden-md hidden-lg" alt="TCB" height="60"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/coolcars.webp" class="visible-md visible-lg" alt="Cool Cars" height="40"  loading="lazy">
                      <img src="assets/img/images/coolcars.webp" class="hidden-md hidden-lg" alt="Cool Cars" height="25"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/tawakal.webp" class="visible-md visible-lg" alt="Tawakal" height="65"  loading="lazy">
                      <img src="assets/img/images/tawakal.webp" class="hidden-md hidden-lg" alt="Tawakal" height="45"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/winover.webp" class="visible-md visible-lg" alt="Tawakal" height="75"  loading="lazy">
                      <img src="assets/img/images/winover.webp" class="hidden-md hidden-lg" alt="Tawakal" height="50"  loading="lazy">
                    </div>
                  </div>
                </div>
               </div>
               <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/impala.webp" class="visible-md visible-lg" alt="Impala" height="50"  loading="lazy">
                      <img src="assets/img/images/impala.webp" class="hidden-md hidden-lg" alt="Impala" height="30"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                <div class="banner theme-about-us-section-gallery-img banner-">
                  <div class="banner-bg custom-banner " >
                    <img src="assets/img/images/periska.webp" class="visible-md visible-lg" alt="periska" height="35"  loading="lazy">
                    <img src="assets/img/images/periska.webp" class="hidden-md hidden-lg" alt="periska" height="25"  loading="lazy">
                  </div>
                </div>
                </div>
                <div class="col-xs-3">
                <div class="banner theme-about-us-section-gallery-img banner-">
                  <div class="banner-bg custom-banner " >
                    <img src="assets/img/images/marangu.webp" class="visible-md visible-lg" alt="marangu" height="40"  loading="lazy">
                    <img src="assets/img/images/marangu.webp" class="hidden-md hidden-lg" alt="marangu" height="30"  loading="lazy">
                  </div>
                </div>
              </div>
              <div class="col-xs-3">
                <div class="banner theme-about-us-section-gallery-img banner-">
                  <div class="banner-bg custom-banner " >
                    <img src="assets/img/images/transline.webp" class="visible-md visible-lg" alt="transline" height="55" loading="lazy">
                    <img src="assets/img/images/transline.webp" class="hidden-md hidden-lg" alt="transline" height="30"  loading="lazy">
                  </div>
                </div>
              </div>
              </div>
              <div class="row" data-gutter="10">
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/turkrift.webp" class="visible-md visible-lg" alt="Turkrift" height="71" loading="lazy">
                      <img src="assets/img/images/turkrift.webp" class="hidden-md hidden-lg" alt="Turkrift" height="40"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner " >
                      <img src="assets/img/images/nairobibus.webp" class="visible-md visible-lg" alt="Nairobi Bus" height="64" loading="lazy">
                      <img src="assets/img/images/nairobibus.webp" class="hidden-md hidden-lg" alt="Nairobi Bus" height="35"  loading="lazy">
                    </div>
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="banner theme-about-us-section-gallery-img banner-">
                    <div class="banner-bg custom-banner" >
                      <img src="assets/img/images/moderncoast.webp" class="visible-md visible-lg"  alt="Modern Coast" height="60" loading="lazy">
                      <img src="assets/img/images/moderncoast.webp"  class="hidden-md hidden-lg" alt="Modern Coast" height="35" loading="lazy">

                    </div>
                  </div>
                </div>
              </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
  <div class="row row-col-border-white theme-page-section-download _p-20  hidden-md hidden-lg"  data-gutter="0">
        <div class="col-md-3 ">
          <div class="banner text-center">
            <h2 class="theme-mobile-app-title visible-lg visible-md">Download our app today</h2>
            <p class="theme-mobile-app-subtitle visible-lg visible-md">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
            <img alt="Download mobile app" src="assets/img/company1/mobile_1.webp" style="height:200px;"  class="image-app" alt="">
            <a class="banner-link" href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en_SG"></a>
            <ul class="theme-mobile-app-btn-list">

              <li>
                <a class="btn btn-dark theme-mobile-app-btn _p-5" href="#">
                  <i class="theme-mobile-app-logo">
                    <img src="assets/img/brands/play-market.webp" height="50" alt="Image Alternative text" title="Image Title">
                  </i>
                  <span>Download on
                    <br>
                    <span>Play Store</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
  </div>
     <!-- mobile implementation data-->
 <div class="row hidden-lg hidden-md ">
  <div class="col-md-3 col-lg-3 ">
        <h5 class="text-center">Customers</h5>
        <h2 class=" text-center text-pink text-stats">2.5M+</h2>
        <p class="text-center text-mobile">Total customers.</p>
  </div>
    <div class="col-md-3 col-lg-3">
        <h5 class="text-center">Buses</h5>
      <h2 class=" text-center text-pink text-stats">1250+</h2>
      <p class="text-center text-mobile">Total buses in operations</p>
   </div>
    <div class="col-md-3 col-lg-3">
        <h5 class="text-center">Routes</h5>
      <h2 class=" text-center text-pink text-stats">800+</h2>
      <p class="text-center text-mobile">Total routes covered </p>
   </div>
    <div class="col-md-3 col-lg-3">
        <h5 class="text-center">Tickets</h5>
      <h2 class=" text-center text-pink text-stats">9M+</h2>
      <p class="text-center text-mobile">Bus tickets sold</p>
    </div>

     
 

</div>
<!-- mobile implementation data -->

      <div class="theme-hero-area _mt-10">
        <div class="theme-hero-area-body">
          <div class="theme-page-section theme-page-section-buupass theme-page-section">
            <div class="buupass-container">
              <div class="theme-page-section-header _ta-l">
                <h2 class="destinationHeading">Top Destinations</h2>
                <p class="desSubHeading">These destinations are popular among travelers like you</p>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <div class="row row-col-gap" data-gutter="10">
                      <div (click)="searchTrip('Kisumu')" class="col-md-5 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kisumu1.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kisumu</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Kitale')" class="col-md-3 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kitale.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kitale</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div  (click)="searchTrip('Bungoma')" class="col-md-4 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/bungoma.webp);"></div>
                          <div class="banner-mask"></div>

                          <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                            <h5 class="banner-title">Bungoma</h5>
                            <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Kampala')" class="col-md-4 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kampala.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kampala</h5>
                                <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      <div  (click)="searchTrip('Mombasa')" class="col-md-5 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/mombasa.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                            <h5 class="banner-title">Mombasa</h5>
                            <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Busia')" class="col-md-3 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/busia.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Busia</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="row row-col-gap" data-gutter="10">
                    <div (click)="searchTrip('Kisumu')" class="col-md-5 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kisumu1.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kisumu</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="searchTrip('Kitale')" class="col-md-3 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kitale.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kitale</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div  (click)="searchTrip('Bungoma')" class="col-md-4 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/bungoma.webp);"></div>
                        <div class="banner-mask"></div>

                        <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                          <h5 class="banner-title">Bungoma</h5>
                          <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="searchTrip('Kampala')" class="col-md-4 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kampala.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kampala</h5>
                              <p class="banner-subtitle">Uganda</p>
                        </div>
                      </div>
                    </div>
                    <div  (click)="searchTrip('Mombasa')" class="col-md-5 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/mombasa.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                          <h5 class="banner-title">Mombasa</h5>
                          <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="searchTrip('Busia')" class="col-md-3 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/busia.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Busia</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
          </div>

          <div class="theme-page-section theme-page-section-sm visible-lg visible-md">
            <div class="buupass-container">
              <div class="theme-page-section-header theme-page-section-header-white">
                <div class="buupass-home-section">
                  <div class="feature-list">

                      <div class="list-item">
                        <div class="row">

                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Customers</h5>
                              <h2 class=" text-center text-pink text-stats">2.5M+</h2>
                              <p class="text-center">Total customers.</p>
                            </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Buses</h5>
                            <h2 class=" text-center text-pink text-stats">1250+</h2>
                            <p class="text-center">Total buses in operations</p>
                          </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Routes</h5>
                            <h2 class=" text-center text-pink text-stats">800+</h2>
                            <p class="text-center">Total routes covered </p>
                          </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Tickets</h5>
                            <h2 class=" text-center text-pink text-stats">9M+</h2>
                            <p class="text-center">Bus tickets sold</p>
                          </div>
                       </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

           
 
          
         
        </div>
      </div>
  </div>
</div>


<div  class="theme-hero-area theme-page-section theme-page-section-sm theme-page-section-offers visible-md visible-lg">
    <div class="theme-hero-area-body">
      <div class="container">
        <div class="theme-page-section _p-0">
          <div class="row">
            <div class="col-md-10 col-md-offset-1">
              <div class="theme-mobile-app">
                <div class="row">
                  <div class="col-md-6">
                    <div class="theme-mobile-app-section">
                      <img alt="Download mobile app" src="assets/img/company1/mobile_1.webp" style="height:450px;"  class="image-app" alt="">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="theme-mobile-app-section">
                      <div class="theme-mobile-app-body">
                        <div class="theme-mobile-app-header">
                          <h2 class="theme-mobile-app-title">Download our app</h2>
                          <p class="theme-mobile-app-subtitle">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
                        </div>
                        <ul class="theme-mobile-app-btn-list">

                          <li>
                            <a class="btn btn-dark theme-mobile-app-btn _p-5" href="#">
                              <i class="theme-mobile-app-logo">
                                <img src="assets/img/brands/play-market.webp" alt="Image Alternative text" title="Image Title">
                              </i>
                              <span>Download on
                                <br>
                                <span>Play Store</span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- Feedback Button -->
<div class="btn-group feedback-btn" [class.feedback-btn-hide]="hideFeedbackBtn" role="group" aria-label="Feedback button with hide option">
  <button type="button" id="openModalButton" class="btn btn-primary font-weight-500 p-0 rounded-0 shadow-none" data-toggle="modal" data-target="#feedbackModal" >Feedback</button>
  <button type="button" class="btn btn-primary p-0 rounded-0 shadow-none" aria-label="Hide" (click)="hideFeedback()"><i class="fa fa-times text-2" aria-hidden="true"></i></button>
</div>

<app-feedback-dialog></app-feedback-dialog>

<app-footer></app-footer>
