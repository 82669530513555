import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-filters',
  standalone: false,

  templateUrl: './skeleton-filters.component.html',
  styleUrl: './skeleton-filters.component.css'
})
export class SkeletonFiltersComponent {

}
