import { Component } from '@angular/core';
import { slideInAnimation } from './route-animation';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import mixpanel from 'mixpanel-browser';
import moengage from "@moengage/web-sdk";
import * as Sentry from "@sentry/angular";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ slideInAnimation ]
})
export class AppComponent {
  title = 'buupass';
  public _opened: boolean = false;

  public _toggleSidebar() {
    this._opened = !this._opened;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
  

    mixpanel.init('4834cb45cce08ab0b7b84746319d478a'); 
    moengage.initialize({app_id:'SKGVEQL8DHV1B33ABQZRJDL9',debug_logs: 0,swPath:"serviceworker.js",cluster:"DC_2"});
    // moengage.initialize({app_id:'SKGVEQL8DHV1B33ABQZRJDL9',debug_logs:0,cluster:"DC_2"});

    Sentry.init({
      dsn: "https://fc7e764b70fbd1dd28ada5d218761bb5@o1249374.ingest.us.sentry.io/4509077068382208"
    });
  }


  onActivate(event: any) {


    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

  
        
}
