import { Component, OnInit } from '@angular/core';
import { User } from '../models/index';
import { Router } from '@angular/router';

declare var bootstrap: any;

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
  isLoggedIn: any;
  user: User;
  modalTitle: string = '';
  modalValue: string = '';
  activeTab: string = 'profile';

  // New form object for the profile modal
  form = {
    fullName: '',
    birthday: '',
    gender: '',
    maritalStatus: '',
    city: '',
    nationality: '',
    passportNumber: '',
    issuingCountry: '',
    passportExpiry: ''
  };

  passwordForm = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  
  showOldPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  phoneNumber: string = '';
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    if (this.isLoggedIn) {
      this.user = new User().deserialize(JSON.parse(localStorage.getItem('user')));
      console.log(this.user);
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  scrollTo(section: string) {
    this.activeTab = section;
  }

  openModal(field: string) {
    const map: { [key: string]: string } = {
      name: 'Name',
      birthday: 'Birthday',
      gender: 'Gender',
      marital: 'Marital Status',
      city: 'City',
      nationality: 'Nationality',
      passport: 'Passport Number',
      mobile: 'Mobile Number',
      password: 'Password',
      traveller: 'Traveller'
    };

    this.modalTitle = map[field] || 'Field';
    this.modalValue = '';

    const profileFields = [
      'Name',
      'Birthday',
      'Gender',
      'Marital Status',
      'City',
      'Nationality',
      'Passport Number'
    ];

    const modalId = profileFields.includes(this.modalTitle)
      ? 'buupassProfileModal'
      : 'buupassOtherModal';

    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const bsModal = new bootstrap.Modal(modalElement);
      bsModal.show();
    } else {
      console.warn('Modal element not found for ID:', modalId);
    }
  }

  // New profile modal submit
  submitProfile() {
    console.log('Form data:', this.form);

    if (!this.form.fullName || this.form.fullName.trim() === '') {
      alert('Full Name is required!');
      return;
    }

    // Optionally: Add more validations here...

    alert('Profile saved successfully!');

    const modalElement = document.getElementById('buupassProfileModal');
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  }

  submitPassword() {
    const { oldPassword, newPassword, confirmPassword } = this.passwordForm;
  
    if (!oldPassword || !newPassword || !confirmPassword) {
      alert('Please fill all fields.');
      return;
    }
  
    if (newPassword !== confirmPassword) {
      alert('New passwords do not match.');
      return;
    }
  
    if (newPassword.length < 6) {
      alert('Password should be at least 6 characters.');
      return;
    }
  
    // Add API call here if needed...
    alert('Password changed successfully!');
  
    const modal = document.getElementById('buupassPasswordModal');
    if (modal) {
      const instance = bootstrap.Modal.getInstance(modal);
      instance.hide();
    }
  }
  closeModal() {
    const modalId = 'buupassProfileModal';
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  }

  // For old single-field modal (e.g. mobile, password)
  submitModal() {
    if (!this.modalValue || this.modalValue.trim() === '') {
      alert(`${this.modalTitle} cannot be empty.`);
      return;
    }

    alert(`Saved ${this.modalTitle}: ${this.modalValue}`);

    const modalId = 'buupassOtherModal';
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  }

  submitPhoneNumber() {
    const phoneRegex = /^[0-9]{9}$/;
    if (!phoneRegex.test(this.phoneNumber)) {
      alert('Please enter a valid 9-digit mobile number.');
      return;
    }
  
    const fullNumber = '+254' + this.phoneNumber;
    console.log('Saved phone number:', fullNumber);
  
    alert(`Mobile number ${fullNumber} saved successfully!`);
  
    const modalElement = document.getElementById('#buupassPhoneModal');
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  }

  logoutUser() {
    this.isLoggedIn = false;
    localStorage.setItem('isLoggedIn', this.isLoggedIn);
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
    localStorage.clear();
    this.router.navigate(['/home']);
  }
}
