import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import {  DataService, MixpanelService } from '../services/index'
import { AbstractControl, UntypedFormBuilder, ValidationErrors, UntypedFormGroup, Validators } from '@angular/forms';
import countryData from './country.json';
import { Base64 } from 'js-base64';
import * as moment from 'moment';

import { TimeFormaterPipe } from '../pipes/time-formater.pipe';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";
import { NameSplitPipe } from '../name-split.pipe';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  countries: any = countryData;
  paytotalfare:any;
  currencyfare:any;
  private sub: any;
  bookingdetails:any;
  returndetails:any;
  bookingdata:any={};
  bookingdataReturn:any={};
  passengers:any=[];
  full_name:any;
  fsname:any;
  lsname:any;
  isdno:any;
  phoneno:any;
  country:any;
  residence:any;
  insurance_dob:any;
  returnpassengers:any=[];
  formValidation=[];
  isError:boolean=false;
  errorMessage:any;
  res:any;
  insuaranceRes:any;
  is_return:false;
  bus_operator:any;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  returnformDataObj={}
  accepted_terms=false;
  isInsuarance=false;
  insuaranceProvider:any;
  policyAmount=0;
  today = new Date();
  defaultDate=new Date();
  insuaranceData:any;
  selectedInsurance: string = "noInsurance";
  private nameSplitPipe = new NameSplitPipe();
  validResults: string[] = ['mashpoa', 'climax'];
  cap:any;
  counter:any;
  hideFeedbackBtn=false;
  bus_arrival_date:any;
  bus_return_arrival_date:any;
  isTripReturning=false;
  constructor(
    private router: Router,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private mixpanelService: MixpanelService,
  ) {
    this.defaultDate.setFullYear(this.defaultDate.getFullYear() - 10);

   }

  ngOnInit() {
    window.scroll(0,0);
    this.dataService.returnPaymentValueChange.subscribe(message =>{
      if(!message){
        message = localStorage.getItem('payments-buupass') ?? ''
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('payments-buupass', message)
      }
  
      let encoded =JSON.parse(Base64.decode(message))
      this.bookingdetails = JSON.parse(Base64.decode(encoded.travel));
      this.insuaranceData = this.mergeInsuranceData(this.bookingdetails.insurance);
    
      this.validResults = this.bookingdetails.insurance_operators
      this.cap = this.bookingdetails.insurance_cap
      this.counter = this.bookingdetails.insurance_counter
      this.bus_operator=this.bookingdetails.result.operator
      

  
     

  
  


      this.isTripReturning = encoded.returning;

      console.log(this.bookingdetails);


      if(encoded.returning){
        this.returndetails = JSON.parse(Base64.decode(encoded.return));
        this.paytotalfare=this.bookingdetails.totalfare + this.returndetails.totalfare ;
        this.returndetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id


      }else{
        this.paytotalfare=this.bookingdetails.totalfare;
        this.bookingdetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id
      }

        
      if (!this.bookingdetails.result.is_shuttle){

        this.bus_arrival_date = this.getArrivalDate(this.bookingdetails.search_details.traveldate, this.bookingdetails.result.departure_time, this.bookingdetails.result.arrival_time);
        
        if(encoded.returning){
          this.bus_return_arrival_date = this.getArrivalDate(this.returndetails.search_details.traveldate, this.returndetails.result.departure_time, this.returndetails.result.arrival_time);

        }
       }else{
       this.bookingdetails.result.departure_time = "00:00:00"
       this.bookingdetails.result.arrival_time = "00:00:00"

     }

      console.log(this.returndetails);


      this.currencyfare = this.bookingdetails.fare_currency;





      this.is_return=encoded.returning;
      this.bookingdetails.seatsSelected.forEach((seat) => {
        
        let fsname='fname'+seat.id;
        let lsname='lname'+seat.id;
        let isdno='idno'+seat.id;
        let phoneno = 'phoneno'+ seat.id;
        let country = 'country'+ seat.id;
        let residence = 'residence'+ seat.id;
        
        let insurance_dob = 'insurance_dob' + seat.id
        
        this.formDataObj[fsname]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
        this.formDataObj[lsname]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
        this.formDataObj[isdno]=['', [Validators.required, Validators.minLength(7)]];
        this.formDataObj[phoneno]=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
        this.formDataObj[country]=['', Validators.required];
        this.formDataObj[insurance_dob]=['']
        // this.formDataObj[residence]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
      });

    
        // Handle Return Trip Condition ONLY IF SEAT COUNT DOES NOT MATCH
        if (this.returnF) {
          this.returndetails.seatsSelected.forEach((seat) => {
          
              let fname = 'fname_return_' + seat.id;
              let lname = 'lname_return_' + seat.id;
              let isdno = 'idno_return_' + seat.id;
              let phoneno = 'phoneno_return_' + seat.id;
              let country = 'country_return_' + seat.id;
              let insurance_dob = 'insurance_dob_return_' + seat.id;
      
             
              this.formDataObj[fname] = ['', [Validators.required, Validators.pattern("^[a-zA-Z ']+$")]];
              this.formDataObj[lname] = ['', [Validators.required, Validators.pattern("^[a-zA-Z ']+$")]];
              this.formDataObj[isdno] = ['', [Validators.required, Validators.minLength(7)]];
              this.formDataObj[phoneno] = ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
              this.formDataObj[country] = ['', Validators.required];
              this.formDataObj[insurance_dob] = [''];
          });
      }
      // Additional form fields
      this.formDataObj['mobileNumber'] = ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
      this.formDataObj['fullname'] = ['', Validators.required];
      this.formDataObj['email'] = [''];
      console.log('wooow', this.formDataObj)
      this.paymentForm = this._fb.group(this.formDataObj);

      this.mixpanelService.track('PageView', {
          pageName: 'Passenger Details',
          source: 'buupassSite',
          role: 'customer',
      });
  });
}

  



  get f() { return this.paymentForm.controls; }

get returnF() {

    return this.isTripReturning &&
           this.returndetails &&
           this.returndetails.seatsSelected &&
           this.bookingdetails.seatsSelected.length !== this.returndetails.seatsSelected.length;
}

  acceptedTerms(){
      this.accepted_terms=!this.accepted_terms;
  }
  handleClick(event: Event) {

  }
  dateOfBirthValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/]\d{4}$/.test(control.value);
    return valid ? null : { invalidDate: true };
  }

  validatePhoneNumber(control: AbstractControl): ValidationErrors | null {
    const valid = /^(\+254|0)?7[0-9]{8}$/.test(control.value);
    return valid ? null : { invalidPhoneNumber: true };
  }
 getArrivalDate(departureDate: string, departureTime: string, arrivalTime: string): string {
    // Convert times to Date objects for comparison
    const departureDateTime = new Date(`${departureDate}T${departureTime}`);
    const arrivalDateTime = new Date(`${departureDate}T${arrivalTime}`);

    // If the arrival time is earlier than the departure time, it means arrival is on the next day
    if (arrivalDateTime.getTime() <= departureDateTime.getTime()) {
        arrivalDateTime.setDate(arrivalDateTime.getDate() + 1);
    }

    // Format the arrival date as YYYY-MM-DD
    return arrivalDateTime.toISOString().split("T")[0];
}


  isInsuaranceClicked(event: any, price: number,insuarance:any) {
    this.insuaranceProvider = insuarance;
    const seatsSelected = this.bookingdetails.seatsSelected.length;
    if (price > 0) {
      this.isInsuarance = true;
     
        this.policyAmount = price * seatsSelected;
        this.paytotalfare = this.bookingdetails.totalfare + this.policyAmount;
     
    } else {
      this.isInsuarance = false;
      this.policyAmount = 0;
      this.paytotalfare = this.bookingdetails.totalfare;
    }
  }



submitForm() {
  this.submitted = true;
  if (!this.accepted_terms) return;

  if (!this.paymentForm.valid) return;

  this.isError = false;
  this.loading = true;
  this.passengers = this.preparePassengers(this.bookingdetails, this.paymentForm.value);

  if (this.is_return) {

    if(this.returnF){
      this.returnpassengers = this.prepareReturnPassengers(this.returndetails, this.paymentForm.value);

    }else{
      this.returnpassengers = this.prepareOneReturnPassengers(this.returndetails, this.passengers);
    }

    this.bookingdataReturn = this.prepareBookingData(this.returndetails, this.returnpassengers, this.paymentForm.value);
  }

  this.bookingdata = this.prepareBookingData(this.bookingdetails, this.passengers, this.paymentForm.value);

  let encodedBookingData = Base64.encode(JSON.stringify(this.bookingdata));
  let encodedBookingDataReturn = Base64.encode(JSON.stringify(this.bookingdataReturn));
  let encodedBookingDetails = Base64.encode(JSON.stringify(this.bookingdetails));
  let encodedReturnDetails = Base64.encode(JSON.stringify(this.returndetails));

  let webformdata = {
    bookingdata: encodedBookingData,
    bookingdetails: encodedBookingDetails,
    returndetails: encodedReturnDetails,
    paytotalfare: this.paytotalfare,  
    policyAmount: this.policyAmount,  
    is_return: this.is_return,
    returndata: encodedBookingDataReturn

  };


  

  let pay = Base64.encode(JSON.stringify(webformdata));
  let paymessage = Base64.encode("Sorry!");
  this.dataService.changeReviewPaymentMessage(pay);
  this.router.navigate(['/review-and-pay', paymessage]);

  this.trackUserActivity(this.bookingdetails, this.paymentForm.value);
}

/**
 * Prepare passenger list for booking details
 */
private preparePassengers(bookingdetails, formValues): any[] {
  return bookingdetails.seatsSelected.map((seat) => {
    const seatPrice = parseInt(bookingdetails.totalfare) / bookingdetails.seatsSelected.length;
    const currentFare = bookingdetails.result.is_shuttle ? seatPrice : Number(seat.fare);

    
    let passenger = {
      full_name: `${formValues[`fname${seat.id}`]} ${formValues[`lname${seat.id}`]}`,
      id_or_passport: formValues[`idno${seat.id}`]?.toString() || '',
      seat: {
        id: seat.id,
        fare: currentFare,
        class: seat.space_type
      },
      
      phone_number: formValues[`phoneno${seat.id}`]?.toString() || '',
      residence: bookingdetails.result.from,
      dob: ''
    };

    if (this.isInsuarance) {
      passenger.dob = formatDate(formValues[`insurance_dob${seat.id}`], "dd-MM-yyyy", "en-KE");
    }

    return passenger;
  });
}


/**
 * Prepare passenger list for booking details
 */
private prepareReturnPassengers(bookingdetails, formValues): any[] {
  return bookingdetails.seatsSelected.map((seat, index) => {

    const seatPrice = parseInt(bookingdetails.totalfare) / bookingdetails.seatsSelected.length;
    const currentFare = bookingdetails.result.is_shuttle ? seatPrice : Number(seat.fare);

    
    let passenger = {
      full_name: `${formValues[`fname_return_${seat.id}`]} ${formValues[`lname_return_${seat.id}`]}`,
      id_or_passport: formValues[`idno_return_${seat.id}`]?.toString() || '',
      seat: {
        id: seat.id,
        fare: currentFare,
        class: seat.space_type
      },
      
      phone_number: formValues[`phoneno_return_${seat.id}`]?.toString() || '',
      residence: bookingdetails.result.from,
      dob: ''
    };

    if (this.isInsuarance) {
      passenger.dob = formatDate(formValues[`insurance_dob_return_${seat.id}`], "dd-MM-yyyy", "en-KE");
    }

    return passenger;
  });
}


/**
 * Prepare passenger list for booking details
 */
private prepareOneReturnPassengers(bookingdetails, initialpassenger): any[] {
  return bookingdetails.seatsSelected.map((seat, index) => {

    const seatPrice = parseInt(bookingdetails.totalfare) / bookingdetails.seatsSelected.length;
    const currentFare = bookingdetails.result.is_shuttle ? seatPrice : Number(seat.fare);

    
    let passenger = {
      full_name: initialpassenger[index].full_name,
      id_or_passport: initialpassenger[index].id_or_passport,
      seat: {
        id: seat.id,
        fare: currentFare,
        class: seat.space_type
      },
      
      phone_number: initialpassenger[index].phone_number,
      residence: bookingdetails.result.from,
      dob: ''
    };

    if (this.isInsuarance) {
      passenger.dob = initialpassenger[index].dob;
    }

    return passenger;
  });
}


/**
 * Prepare booking data object
 */
private prepareBookingData(bookingdetails, passengers, formValues) {
  return {
    booking_channel: "web",
    transaction_reference: "",
    referral_code: "",
    residence: "Nairobi",
    promo_code: "",
    selected_bus: {
      from: bookingdetails.result.from,
      to: bookingdetails.result.to,
      departure_date: bookingdetails.result.departure_date,
      bus_type_id: bookingdetails.result.bus_type_id,
      operator: bookingdetails.result.operator,
      is_express_travel: bookingdetails.result.is_express_travel,
      number_of_booked_seats: bookingdetails.result.number_of_seats_booked,
      id: bookingdetails.result.id,
      make: bookingdetails.result.make,
      model: bookingdetails.result.model,
      route_id: bookingdetails.result.route_id,
      route_schedule_id: bookingdetails.result.route_schedule_id,
      route_schedule_code: bookingdetails.result.is_shuttle ? '0' : bookingdetails.result.route_schedule_code,
      departure_time: bookingdetails.result.is_shuttle ? (bookingdetails.result.operator.alias === 'climax' ? bookingdetails.result.departure_time : '00:00:00') : bookingdetails.result.departure_time,
      departure_timestamp: bookingdetails.result.departure_timestamp,
      arrival_time: bookingdetails.result.is_shuttle ? (bookingdetails.result.operator.alias === 'climax' ? bookingdetails.result.arrival_time : '00:00:00') : bookingdetails.result.arrival_time,
      number_of_available_seats: bookingdetails.result.number_of_available_seats,
      fare: `${bookingdetails.totalfare}.00`,
      boarding_points: bookingdetails.result.boarding_points,
      dropoff_points: bookingdetails.result.dropoff_points,
      seats: bookingdetails.result.seats,
      seat_types: bookingdetails.result.seats_types,
      number_of_window_seats: bookingdetails.result.number_of_window_seats,
      last_booking: bookingdetails.result.last_booking,
      amenities: bookingdetails.result.amenities,
      origin_city_id: bookingdetails.result.origin_city_id,
      destination_city_id: bookingdetails.result.destination_city_id,
      bus_capacity: bookingdetails.result.bus_capacity,
      trip_id: bookingdetails.result.trip_id,
      boarding_point: bookingdetails.pickup_location,
      drop_off_point: bookingdetails.dropoff_location,
    },
    payee: {
      phone_number: formValues.mobileNumber,
      name: formValues.fullname,
      email: formValues.email
    },
    boarding_point: bookingdetails.pickup_location,
    drop_off_point: bookingdetails.dropoff_location,
    date_of_travel: bookingdetails.result.departure_date,
    departure_time: bookingdetails.result.departure_time,
    arrival_time: bookingdetails.result.arrival_time,
    route_schedule_id: bookingdetails.result.route_schedule_id,
    operator: bookingdetails.result.operator,
    origin_city_name: bookingdetails.result.from,
    destination_city_name: bookingdetails.result.to,
    origin_city_id: bookingdetails.result.origin_city_id,
    destination_city_id: bookingdetails.result.destination_city_id,
    passengers: passengers,
    children: []
  };
}

/**
 * Track user activity for analytics
 */
private trackUserActivity(bookingdetails, formValues) {
  let mixPanelData = {
    name: formValues.fullname,
    email: formValues.email,
    residence: bookingdetails.result.from,
    route: `${bookingdetails.result.from} to ${bookingdetails.result.to}`,
    travelDate: bookingdetails.result.departure_date,
    Busoperator: bookingdetails.result.operator,
    amount: bookingdetails.total_fare,
    ticketCount: this.passengers.length,
    isReturnUser: this.is_return,
    isPromoCodeAdded: "",
    platform: 'web',
    source: 'buupassSite',
    role: 'customer',
  };

  moengage.track_event('Personal details input', {
    PassengerName: formValues.fullname,
    PassengerEmail: formValues.email,
    PassengerPhone: `254${formValues.mobileNumber.slice(-9)}`,
    PassengerResidence: bookingdetails.result.from,
    Busoperator: bookingdetails.result.operator.alias,
    TotalFare: parseInt(bookingdetails.total_fare),
    TotalPassengers: this.passengers.length
  });

  let firstName = this.nameSplitPipe.transform(formValues.fullname, 'first');
  let lastName = this.nameSplitPipe.transform(formValues.fullname, 'last');
  moengage.add_first_name(firstName);
  moengage.add_last_name(lastName);
  moengage.add_email(formValues.email);
  moengage.add_mobile(`254${formValues.mobileNumber.slice(-9)}`);
  moengage.add_user_attribute("Residence", bookingdetails.result.from);

  this.mixpanelService.track('StartPayment', mixPanelData);
}


mergeInsuranceData(data: any): any[] {
  let mergedData:any = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const itemsWithKey = data[key].map(item => ({ ...item, key }));
      mergedData = [...mergedData, ...itemsWithKey];
    }
  }
  return mergedData;
}

hideFeedback(){
  this.hideFeedbackBtn=true; 
}

}
