<nav
  class="navbar bHTikb sticky-top navbar-default navbar-fixed-top buupass-box navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border"
  id="main-nav">
  <div class="nav-overlay"></div>

  <div class="navbar-inner nav">
    <div class="navbar-header">
      <button class="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button"
        area-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>


      <a  class="navbar-brand-logo _mt-20" href="index.html">
        <img  src="assets/img/company1/logo-name.png" alt="buupass.com" width="70" alt="Image Alternative text" title="Image Title"/>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-main">

      <ul class="nav navbar-nav pd-nav">
        <li class="active">
          <a href="index.html" class="headerAccount" role="button">
            Buses
          </a>
        </li>
        <li>
          <a href="https://metickets.krc.co.ke"  class="headerAccount" role="button" rel="noreferrer" target="_blank">
            Trains
          </a>
        </li>
        <li>
          <a href="https://flights.buupass.com" class="headerAccount" role="button" rel="noreferrer" target="_blank">
            Flights
          </a>
        </li>
        <li>
          <a [routerLink]="['/hotels']" class="headerAccount" role="button" rel="noreferrer" >
            Hotels
          </a>
        </li> 
        <!-- <li >
                <a href="https://hire.buupass.com/"  role="button" >
                  CAR HIRE
                </a>
            </li> -->


      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li>
          <a target="_blank"  href="https://blog.buupass.com/" class="headerAccount" >Blog</a>
        </li>
        <li>
          <a href="contact-us.html" class="headerAccount" role="button">
            Help
          </a>
        </li>
        <li *ngIf="!isLoggedIn">
          <a id="openModalStart" data-toggle="modal" data-target="#manageBooking" class="headerAccount" role="button">
            Manage my account
          </a>
        </li>
        <button id="openModalButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#myModal">Open Modal</button>
        <button id="openSignupButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#myCreateModal">Open Modal</button>
        <button id="openSuccessButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#signupSuccess">Open Modal</button>
        <button id="openModalReset"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#resetPassModal">Open Modal</button>
       <button id="openOtpCodeButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#otpCodeModal">Open Modal</button>
        <button id="openUpdatePassword"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#passwordUpdateModal">Open Modal</button>
        <button id="openResetSuccessButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#resetSuccess">Open Modal</button>


        <!-- <li>
          <a data-toggle="modal" data-target="#myModal" class="headerAccount" role="button">
            Sign in
          </a>

        </li>
        <li>
          <a  data-toggle="modal" data-target="#myCreateModal"  class="headerAccount" role="button">
            Create an account
          </a>
        </li> -->
        <li *ngIf="isLoggedIn" class="navbar-nav-item-user dropdown">
              <a class="dropdown-toggle headerAccount" href="account.html" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd" clip-rule="evenodd">
                  <path
                    d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="/account">Account</a>
                </li>
                <li>
                  <a href="/my-trips">My Trips</a>
                </li>
                <li>
                  <a href="/my-notifications">Notifications</a>
                </li>
                <li>
                  <a (click)="logoutUser()" href="#">Logout</a>
                </li>

              </ul>
            </li>
      </ul>
    </div>
  </div>

</nav>

<!-- Modal -->
<div id="manageBooking" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button id="closeStart" type="button" class="close" data-dismiss="modal">&times;</button>
        <br>

        <div class="modal-body">

          <div class="facebook-login__disclaimer  _mb-20 _ml-15">

            <p class="paragraphRegular">Please enter your mobile number.</p>
          </div>

          <!-- <div class="or__splitter"><span>or</span></div> -->
          <form [formGroup]="startForm" id="ngFormStart" (ngSubmit)="otpSignUp()" >
            <div class="form-row  _mb-20 ">
                <div class="col-sm-8 my-1">
                  <label class="sr-only" for="inlineFormInputPhoneContinue">Email</label>
                  <input type="text"
                  class="form-control"
                  id="inlineFormInputPhoneContinue"
                  placeholder="Enter email or phone number"
                  name="phone_or_email"
                  formControlName="phone_or_email"
                  >
                </div>
               
                <div *ngIf="!loadingStart &&  api_error" class="invalid-feedback">
                  <div class="invalid-select _ml-10"  >{{api_error.getErrorMessage()}}</div>
                </div>
                <div class="col-auto my-1">
                  <button [disabled]="!startForm.valid || !this.accepted_terms"   form="ngFormStart" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                  <img *ngIf="loadingStart" src="assets/img/gifs/rll.gif">
                  <span *ngIf="!loadingStart">CONTINUE</span>

                  </button>
                </div>



                <div class="row">
                  <div class="col-sm-8 my-1">
                   <small class=" _ml-10 invalid-select" *ngIf="startForm.get('phone_or_email').touched && startForm.get('phone_or_email').hasError('required')">This field is required!</small>
                  <small class="_ml-10 invalid-select" *ngIf="startForm.get('phone_or_email').touched && startForm.get('phone_or_email').hasError('pattern')">Should be a valid phone number</small>
                </div>
                </div>
                <div class="theme-account-notifications-item">
                  <label class="icheck-label">
                    <div class="icheck" >
                      <input class="icheck terms-checkbox" type="checkbox"  (click)="acceptedTerms()" required=""  >
                    </div>
                    
                      <span class="icheck-sub-title">By continuing, you agree to receive an OTP via SMS from Buupass to login to your account</span>
                    
                  </label>
                  <small class="invalid-select" *ngIf=" numberSubmitted &&  !this.accepted_terms">Please accept the terms and condition</small>

                </div>
               


            </div>
          
            </form>
           
            <p class="_ml-15 _mt-20"><a href="/BuuPass_Website_Terms_of_Use.pdf" target="_blank">Terms & conditions</a></p>
        </div>
      </div>
    </div>
  </div>
  </div>

      <!-- Modal -->
      <div id="otpCodeModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button id="closeCode" type="button" class="close" data-dismiss="modal">&times;</button>
              <br>
              <div class="modal-body">
                <h4 class="theme-payment-page-signin-title review-header _ml-15">Enter OTP</h4>
                <div class="facebook-login__disclaimer  _mb-20 _ml-15">
                  <p class="paragraphRegular">
                    OTP has been sent to your {{is_email_otp ? 'email' : 'mobile'}}
                  </p>
                </div>
                <!-- <div class="or__splitter"><span>or</span></div> -->
                <form [formGroup]="codeForm" id="codeForm" (ngSubmit)="otpSubmitCode()" >
                  <div class="form-row  _mb-20 ">
                      <div class="col-sm-6 my-1">
                        <input type="text"
                        class="form-control"
                        id="inlineFormInputEmailHomeForm"
                        placeholder="Enter OTP here"
                        name="otp_code"
                        formControlName="otp_code"
                        >
                      </div>
                      <div class="col-auto my-1">
                        <button   form="codeForm" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                        <img *ngIf="loadingCode" src="assets/img/gifs/rll.gif">
                        <span *ngIf="!loadingCode">SUBMIT</span>
                        </button>
                      </div>
                  </div>
                  </form>
    
                  <div *ngIf="!codeubmitted &&  api_error" class="invalid-feedback">
                    <div class="invalid-select _ml-10"  >{{codeSuccessMsg}}</div>
                  </div>
                  <small class=" _ml-10 invalid-select" *ngIf="codeForm.get('otp_code').touched && codeForm.get('otp_code').hasError('required')">Please enter a code </small>
              </div>
    
            </div>
    
          </div>
        </div>
      </div>
  <!-- Modal -->
  <div id="resetPassModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closeReset" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <div class="modal-body">
            <h4 class="theme-payment-page-signin-title review-header _ml-15">Recover your account  </h4>
            <div class="facebook-login__disclaimer  _mb-20 _ml-15">
              <p class="paragraphRegular">We can help you reset your password. First, enter your email and follow the instructions.</p>
            </div>
            <div *ngIf="!resetsubmitted &&  successReset" class="success-feedback">
              <div class="success-select _ml-10"  >{{resetSuccessMsg}}</div>
            </div>
            <!-- <div class="or__splitter"><span>or</span></div> -->
            <form [formGroup]="resetForm" id="resetForm" (ngSubmit)="submitResetPassword()" >
              <div class="form-row  _mb-20 ">
                  <div class="col-sm-8 my-1">
                    <label class="sr-only" for="inlineFormInputEmailHome">Email</label>
                    <input type="text"
                    class="form-control"
                    id="inlineFormInputEmailHome"
                    placeholder="e.g your@email.com"
                    name="email"
                    formControlName="email"
                    >
                  </div>
                  <div class="col-auto my-1">
                    <button   form="resetForm" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                    <img *ngIf="loadingReset" src="assets/img/gifs/rll.gif">
                    <span *ngIf="!loadingReset">SUBMIT</span>
                    </button>
                  </div>
              </div>
              </form>

              <div *ngIf="!resetsubmitted &&  api_error" class="invalid-feedback">
                <div class="invalid-select _ml-10"  >{{resetErrMsg}}</div>
              </div>
              <small class=" _ml-10 invalid-select" *ngIf="resetForm.get('email').touched && resetForm.get('email').hasError('required')">Please enter your email</small>
              <small class=" _ml-10 invalid-select" *ngIf="resetForm.get('email').touched && resetForm.get('email').hasError('email')">Should be a valid email address</small>

          </div>

        </div>

      </div>
    </div>
  </div>



   <!-- Modal update password -->
    <div id="passwordUpdateModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button id="closePasswordUpdate" type="button" class="close" data-dismiss="modal">&times;</button>
            <br>
            <div class="modal-body">
              <h4 class="theme-payment-page-signin-title review-header _ml-15">    Reset your password       </h4>
              <div class="facebook-login__disclaimer  _mb-20 _ml-15">
                <p class="paragraphRegular">8-character minimum; case sensitive.</p>
              </div>
              <!-- <div class="or__splitter"><span>or</span></div> -->
              <form [formGroup]="passwordUpdateForm" id="passwordUpdateForm" (ngSubmit)="updatePassword()" >
                <div class="form-row  _mb-20 ">
                    <div class="col-sm-6 my-1">
                      <label class="sr-only" for="inlineFormInputPassword">Password</label>
                      <input type="password"
                      class="form-control"
                      id="inlineFormInputPassword"
                      placeholder="Password"
                      name="password"
                      formControlName="password"
                      >
                    </div>
                    <div class="col-sm-6 my-1">
                      <label class="sr-only" for="inlineFormInputConfirmPassword">Re-enter Password</label>
                      <input type="password"
                      class="form-control"
                      id="inlineFormInputConfirmPassword"
                      placeholder="Re-enter Password"
                      name="confirmpassword"
                      formControlName="confirmpassword"
                      >
                    </div>
                  </div>
                  <div class="form-row  _mb-20 ">
                    <div class="col-sm-6 my-1 _mt-20">
                      <button   form="passwordUpdateForm" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                      <img *ngIf="loadingPasswordUpdate" src="assets/img/gifs/rll.gif">
                      <span *ngIf="!loadingPasswordUpdate">SUBMIT</span>
                      </button>
                    </div>
                </div>
                
                </form>
  
                <div *ngIf="!codeubmitted &&  api_error" class="invalid-feedback">
                  <div class="invalid-select _ml-10"  >{{codeSuccessMsg}}</div>
                </div>
                <div class="row">
                  <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('password').touched && passwordUpdateForm.get('password').hasError('required')">Please enter a code </small>
                  <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('password').touched && passwordUpdateForm.get('password').hasError('minlength')">Should be 8-character minimum.</small>
                  <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('confirmpassword').touched && passwordUpdateForm.get('confirmpassword').hasError('confirmedValidator') ">Password and Confirm Password must be match.</small>
  
                </div>
            
              </div>
  
          </div>
  
        </div>
      </div>
      </div>
    
  <!-- Modal -->
  <div id="signupSuccess" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closeSuccess" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <div class="modal-body">
            <h4 class="theme-payment-page-signin-title review-header _ml-10">Success</h4>

            <div class="facebook-login__disclaimer  _mb-20 _ml-10">

              <p class="paragraphRegular">Thank you for registering with Buupass, kindly go to your email and click the confirmation link. Thank you .</p>
            </div>
          </div>

        </div>

      </div>
    </div>
    </div>
      <!-- Modal -->
  <div id="resetSuccess" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closeResetSuccess" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <div class="modal-body">
            <h4 class="theme-payment-page-signin-title review-header _ml-10">Success</h4>

            <div class="facebook-login__disclaimer  _mb-20 _ml-10">

              <p class="paragraphRegular">Your password has been updated successfully. Thank you .</p>
            </div>
          </div>

        </div>

      </div>
    </div>
    </div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <i (click)="backToEmail()" class="fa fa-arrow-left  back-btn" aria-hidden="true"></i>
        <button  id="closePassword" type="button" class="close" data-dismiss="modal">&times;</button>
        <br>
        <div class="modal-body">
            <form [formGroup]="loginForm" id="ngFormLogin" (ngSubmit)="submitLogin()" >
          <h4 class="theme-payment-page-signin-title review-header  _ml-15">Welcome back</h4>
          <div class="facebook-login__disclaimer _ml-15">
            <p class="paragraphRegular">Please enter your password to continue.</p>
          </div>
          <div class="row">
            <div class="col-md-8 _ml-15">
              <div class="row" data-gutter="10">
                <div class="col-md-12 mfb-form__row">
                  <div
                    class="theme-search-area-section first theme-search-area-section-border-light theme-search-area-section-curved">
                    <div class="theme-search-area-section-inner">
                      <input
                      name="password"
                      id="password-field"
                      formControlName="password"
                      class="theme-header-section-input typeahead"
                      [type]="fieldTextType ? 'text' : 'password'"
                      placeholder=""  >
                      <span  class="fa fa-fw  field-icon toggle-password"
                      [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                }"
                                (click)="toggleFieldTextType()"
                      ></span>
                    </div>
                  </div>
                </div>
                <div *ngIf="!loginsubmitted &&  api_error" class="invalid-feedback">
                  <div class="invalid-select _ml-5"  >{{api_error.getErrorMessage()}}</div>
                </div>
                <div class="row mfb-form__row user-login-form__row__pass-reset ">
                  <div class="col-xs-12 user-login-form__pass-reset _ml-5"><a class="user-login-form__link" (click)="initiateResetPassword()" href="#">Can’t access your account?</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-md-offset-8">
              <button class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                <img *ngIf="loadingLogin" src="assets/img/gifs/rll.gif">
                <span *ngIf="!loadingLogin">SUBMIT</span>
              </button>
            </div>
          </div>
          </form>
        </div>

      </div>

    </div>
  </div>
</div>
  <!-- Modal -->
  <div id="myCreateModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closeSignup" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <h4 class="theme-payment-page-signin-title review-header _mb-10 text-center">Sign up</h4>
<i class="fa fa-arrow-left" aria-hidden="true"></i>
          <div class="modal-body">
            <form class="makeFlex" id="ngFormSignup" novalidate [formGroup]="signupForm" (ngSubmit)="submitSignUp()">
            <div class="trvlrs-dtl _ml-30">
                <div class="c-accordion-item  expanded">
                    <div class="accordion-body" style="display: block;">
                        <div class="general-error"></div>
                        <div class="trvlr-forms-cntnr u-ib">
                            <div class="trvlr-form-cntnr">
                                <div class="trvlr-form">
                                    <div >
                                        <div class="form-hdr"><i class="text-light fa fa-bookmark"></i> Account Details</div>
                                        <div class="row" data-gutter="30">
                                            <div class="col-md-6">
                                            <div class="theme-search-area-section first theme-search-area-section-line">
                                                <label for="first_name" class="theme-search-area-section-label">Firstname</label>
                                                <div class="theme-search-area-section-inner">
                                                <input class="theme-search-area-section-input typeahead"
                                                        type="text"
                                                        name="first_name"
                                                        id="first_name"
                                                        placeholder=""
                                                        tabindex="0"
                                                        formControlName="first_name"
                                                  >
                                                </div>
                                                <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                                    <div class="invalid-select" *ngIf="f.first_name.errors.required">First name is required!</div>
                                                    <div class="invalid-select" *ngIf="f.first_name.errors.pattern">Your firstname should not contain numbers or spaces</div>
                                                </div>
                                                <div *ngIf="!loadingSignup &&  user" class="invalid-feedback">
                                                  <div class="invalid-select "  >{{user.getFname()}}</div>
                                                </div>

                                            </div>
                                            </div>
                                            <div class="col-md-6">
                                                    <div class="theme-search-area-section first theme-search-area-section-line">
                                                    <label  for="last_name" class="theme-search-area-section-label">Lastname</label>
                                                    <div class="theme-search-area-section-inner">
                                                        <input class="theme-search-area-section-input typeahead"
                                                        type="text"
                                                        name="last_name"
                                                        id="last_name"
                                                        tabindex="0"
                                                        formControlName="last_name"
                                                        >
                                                    </div>
                                                      <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                                          <div class="invalid-select" *ngIf="f.last_name.errors.required">Firstname is required!</div>
                                                          <div class="invalid-select" *ngIf="f.last_name.errors.pattern">Your lastname should not contain numbers or spaces</div>
                                                      </div>
                                                      <div *ngIf="!loadingSignup &&  user" class="invalid-feedback">
                                                        <div class="invalid-select "  >{{user.getLname()}}</div>
                                                      </div>
                                                    </div>
                                            </div>



                                        </div>
                                        <div class="row" data-gutter="30">
                                                <div class="col-md-6 ">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="phone_number" class="theme-search-area-section-label">Phone Number</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="text"
                                                              name="phone_number"
                                                              id="phone_number"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="phone_number"
                                                        >
                                                      </div>
                                                      <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                                          <div class="invalid-select" *ngIf="f.phone_number.errors.required">Phone number is required!</div>
                                                          <div class="invalid-select" *ngIf="f.phone_number.errors.pattern">Input a valid Phone Number. Sample formats (+254******)</div>
                                                      </div>
                                                      <div *ngIf="!loadingSignup &&  user" class="invalid-feedback">
                                                        <div class="invalid-select "  >{{user.getPhone()}}</div>
                                                      </div>

                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                        <div class="theme-search-area-section first theme-search-area-section-line">
                                                        <label for="id_number" class="theme-search-area-section-label">ID/Passport</label>
                                                        <div class="theme-search-area-section-inner">
                                                            <input class="theme-search-area-section-input typeahead"
                                                             type="text"
                                                             name="id_number"
                                                            id="id_number"
                                                            placeholder=""
                                                            type="text"
                                                            tabindex="0"
                                                            formControlName="id_number"
                                                             >
                                                        </div>
                                                        <div *ngIf="submitted && f.id_number.errors" class="invalid-feedback">
                                                            <div class="invalid-select" *ngIf="f.id_number.errors.minlength">ID/PASSPORT should be between 7 to 14 characters</div>
                                                            <div class="invalid-select" *ngIf="f.id_number.errors.maxlength">ID/PASSPORT should be between 7 to 14 characters</div>
                                                            <div class="invalid-select" *ngIf="f.id_number.errors.required">ID/PASSPORT is required!</div>
                                                            <div class="invalid-select" *ngIf="f.id_number.errors.pattern">ID/PASSPORT must be between 7 to 14 characters</div>
                                                        </div>
                                                        <div *ngIf="!loadingSignup &&  user" class="invalid-feedback">
                                                          <div class="invalid-select "  >{{user.getIdno()}}</div>
                                                        </div>
                                                        </div>
                                                </div>

                                        </div>
                                        <div class="row" data-gutter="30">
                                          <div class="col-md-12">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="email" class="theme-search-area-section-label">Email</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="text"
                                                              placeholder="Email"
                                                              name="email"
                                                              id="email"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="email"
                                                        >
                                                      </div>
                                                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                          <div class="invalid-select" *ngIf="f.email.errors.required">Email is required!</div>
                                                          <div class="invalid-select" *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                      </div>
                                                      <div *ngIf="!loadingSignup &&  user" class="invalid-feedback">
                                                        <div class="invalid-select "  >{{user.getEmail()}}</div>
                                                      </div>
                                                  </div>
                                          </div>
                                        </div>
                                        <div class="row" data-gutter="30">
                                          <div class="col-md-12">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="email" class="theme-search-area-section-label">Password</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="password"
                                                              placeholder="Your pasword"
                                                              name="password"
                                                              id="password"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="password"
                                                        >
                                                      </div>
                                                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                          <div class="invalid-select" *ngIf="f.password.errors.required">Password is required!</div>
                                                          <div class="invalid-select" *ngIf="f.password.errors.minlength">Your password should not be less than 6 characters</div>
                                                      </div>
                                                  </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="trvlr-list-cntnr u-ib">
                            <div class="c-trvlr-list-wrpr">
                                <div class="c-trvlr-list-cntnr u-cstm-scroll" style="max-height: 250px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="row _mt-20 ">
                    <div class="col-md-3 col-md-offset-7">
                      <button type="submit"  class=" _ml-30 theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">
                        <img *ngIf="loadingSignup" src="assets/img/gifs/rll.gif">
                        <span *ngIf="!loadingSignup">SUBMIT</span>

                      </button>
                    </div>
                  </div>
              </div>
            </form>

<p class="text-center _mt-10">By creating an account you agree to our
Terms of Use and Privacy Policy</p>

          </div>

        </div>

      </div>
    </div>
    </div>
