import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BookingService,HomeService, DataService,MixpanelService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate   } from '@angular/common';
import * as $ from 'jquery';
import { PassDestinationValueService } from '../services/pass-destination-value.service';
import { PassDepatureValueService } from '../services/pass-depature-value.service';
import { ScrolltopService } from '../services/scrolltop.service';
import * as moment from 'moment';
import { Base64 } from 'js-base64';
import { EMPTY, fromEvent,  } from 'rxjs';
import { PixelService } from '../pixel.service';
import { Subscription } from 'rxjs';
import moengage from "@moengage/web-sdk";


interface Schedule {
  operator: {
    name: string;
     alias: string };
  departureTime: string;
  departure_date: string; 
  fare: number;
  number_of_available_seats: number;
  seats_types: SeatType[];

}

interface InsuranceData {
  data?: any;  
  allowed_operators?: string[];
  cap?: number;
  counter?: number;
}

interface SeatType {
  id: string;
  alias: string;
  fare: number;
  name: string;
  currency: string;
  available_seat_ids: string[];
}
interface StreamData {
  data?: { schedule?: Schedule[] 
    insurance?: InsuranceData;
  };
  status: boolean;
  completed: boolean;
  error: any;
  provider: string;
 
 
}



@Component({
  selector: 'app-bus-booking',
  templateUrl: './bus-booking.component.html',
  styleUrls: ['./bus-booking.component.css']
})
export class BusBookingComponent implements OnInit {
  @ViewChild('focustarget') focusEl: ElementRef;
  private streamSubscription: Subscription;

  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;pickupForm: UntypedFormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  // pickupanddropoff
  pickuplocation:string =""
  dropofflocation:string =""
  pickupreturnlocation:string =""
  dropoffreturnlocation:string =""
  today=new Date();
  noResultsFound: boolean = false; 
  isOpen=false;
  bsValue;
  bsInlineValue;
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);
  //traveldate:any;
  data = [];formdata={};
  formdataTravel:any={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  insurance: any = {};
  insurance_operators:any;
  insurance_cap:any;
  insurance_counter:any;
  return_schedule:any;
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  dummyDropoff:any =[];
  seatsSelected: any=[];
  selectedOptionPassengers;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any ;
  isResultsLoading=false;
  seatErrors=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  returnPickUpInit: string;
  returnDropOffInit: string;
  travelDateInit: string;
  returnDateInit:string;
  isUnavailabe=false;
  dataLoading=false;
  isPickupClicked=false;
  isReturnTicket=false;
  isReturnTicketSelected=false;
  isShowingFilter=false;
  totalFare=0;
  fareCurrency='Ksh'
  returning=false;
  submitted=false;
  completed:boolean=false;
  submittedMashPoa:boolean=false;
  selectedIndex = -1;
  hide_from_ui:number;
  availableSeats=[];
  seats =[];
  seatData:any =[];
  seatMobile:any =[];
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false;
  discountAmount:any;
  busCheckSelected:any;
  isReadOnly=false;
  types= [];
  sticky: boolean = false;
  elementPosition: any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  //added this now to support the filters .
  operators: Set<string> = new Set(); // Assume this is populated from somewhere
  selectedOperators: Set<string> = new Set();
  selectedTimeRanges: Set<string> = new Set();
  sortOption: string = 'Recommended'; 
  filteredSchedules: any[] = [];
  providers: any[] =[];
  departure_date: string;  
  departure_time: string;
  seats_types: SeatType[];
  originalResults: any[] = [];
  selectedSeatTypes = new Set<string>(); // Store selected seat types
  availableSeatTypes = ['Normal', 'VIP', 'Business', 'Executive'];
  departureTimes = [
    { name: "Nighttime",   iconClass: "icon-nighttime" },
    { name: "Morning",   iconClass: "icon-morning" },
    { name: "Midday",  iconClass: "icon-midday" },
    { name: "Late",   iconClass: "icon-late" }
  ];





  selectedBus:any;
  currentBusId:any;
  selectedBusStatus:boolean=false;
  isAcClicked=false;
  isEmptyReturn=false;
  isWater=false;
  From: string;
  To: string;
  TravelDate: string;
  finalTravelDate: string;
  finalReturnDateInit:string;
  hide_from_ui_shuttle:number;
  booked_seat_session_id='';
  hideFeedbackBtn=false;
  initialBus:any;

  r_pickup:string;
  r_dropoff:string;
  traveldate:string;
  returndate:string; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeservice:HomeService,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private scrolltopService:ScrolltopService,
    private mixpanelService: MixpanelService,
    private renderer: Renderer2,
    

  ) {
  
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:['', Validators.required],
      returndate:[new Date(), Validators.required]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required]
    });
     // pickupanddropoff
    this.pickupForm = _fb.group({
      pickupLocation:['', Validators.required],
      dropoffLocation:['', Validators.required],
    });
    
   }
   //changed this ??
  returnClicked() {
    this.returning = !this.returning;
    this.isOpen = true;
    // Reset form and UI elements specific to the return trip here
    if (this.returning) {
        this.reactiveForm.controls['returndate'].setValidators([Validators.required]);
        this.reactiveForm.controls['returndate'].updateValueAndValidity();
    } else {
        this.reactiveForm.controls['returndate'].clearValidators();
        this.reactiveForm.controls['returndate'].updateValueAndValidity();
    }
}
  closeReturn = () =>{
    this.returning=false;
  }
  showFilter () {
    this.isShowingFilter=true;
  }
  hideFilter() {
    this.isShowingFilter=false;
  }
  ngOnInit() {
    this.loadDestinations();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
        window.scrollTo(0, 0)
    });

    let message =''
    if(!message){

      const routeParam = this.route.snapshot.paramMap.get('route');  // e.g., "Nairobi-to-Mombasa"
      const [pickup, dropoff] = routeParam.split('-to-');  // Splits into ["Nairobi", "Mombasa"]
    
      const queryParams = this.route.snapshot.queryParamMap;
    
      this.r_pickup = queryParams.get('fromCityName');
      this.r_dropoff = queryParams.get('toCityName');
      this.traveldate = queryParams.get('onward');
      this.returndate  = queryParams.get('return');

      if(!this.traveldate){
        this.traveldate= formatDate(new Date(), "yyyy-MM-dd", "en-KE");
      }


      this.traveldate = formatDate(this.traveldate, "yyyy-MM-dd", "en-KE");

      if(this.returndate){
        this.returning =true;
        this.returndate = formatDate(this.returndate, "yyyy-MM-dd", "en-KE");
      }

      let formdata = {
        pickup: this.r_pickup,
        dropoff: this.r_dropoff,
        pickupname: this.r_pickup,
        dropoffname: this.r_dropoff,
        traveldate: this.traveldate,
        returndate: this.returndate,
        returning: this.returning
      };

      message = Base64.encode(JSON.stringify(formdata));
      this.dataService.changeSearchMessage(message);
  
      if(!message){
        // this.router.navigate(['/index.html'])
      }
    }
  

    localStorage.setItem('search', message);
    this.bookingdetails = JSON.parse(Base64.decode(message))
    this.pickUpInit=this.bookingdetails.pickupname;
    this.dropOffInit=this.bookingdetails.dropoffname;
    this.travelDateInit=this.bookingdetails.traveldate;
    this.returnDateInit=this.bookingdetails.returndate;
    this.fromPlaceholder = this.bookingdetails.pickupname;
    this.toPlaceholder = this.bookingdetails.dropoffname;
    this.returning=this.bookingdetails.returning;
    this.serviceDepature.changeValue(this.pickUpInit);
    this.serviceDestination.changeValue(this.dropOffInit);
    if(this.bookingdetails.returning){
      this.returnPickUpInit=this.bookingdetails.dropoffname;
      this.returnDropOffInit=this.bookingdetails.pickupname;
    }
    this.searchBuses(JSON.parse(Base64.decode(message)))


  this.mixpanelService.track('PageView', {
    pageName: 'Schedules page',
    source: 'buupassSite',
    role: 'customer',
  });

 

    this.mixpanelService.track('PageView', {
      pageName: 'Schedules page',
      source: 'buupassSite',
      role: 'customer',
    });

    this.loadDesktopScript();
    this.loadMobileScript();
    this.loadAds();

    //filter ngonint
    // this.fetchSchedules('Madrid', 'Z', '2025-02-06');   
    

  }

  fetchSchedules(leaving_from: string, going_to: string, departing_on: string): void {
    this.isResultsLoading = true;
    this.results = []; // Clear previous results

    this.streamSubscription = this.service.streamSchedules(leaving_from, going_to, departing_on)
      .subscribe({
        next: (streamData: StreamData) => {
             
           console.log("Provider:", streamData.provider); 

           
           this.providers.push(streamData.provider);
           if (streamData.data && Array.isArray(streamData.data)) {            
            // Check if there are schedules and that it is an array
            const validSchedules = streamData.data.filter(s => s.departureTime);
            console.log(`Valid schedules count: ${validSchedules.length}`);
        
            const schedulesWithProvider = validSchedules.map(schedule => ({
                ...schedule,
                provider: streamData.provider, 
                departureTime: schedule.departureTime,
            }));
        
            this.results = [...this.results, ...schedulesWithProvider];
            console.log("Filtered data ready for application:", this.results);
            this.applyFilters();
        } else {
            console.warn("No schedules found or data is not in expected format:", streamData);
          }
        },
        error: (error) => {
          console.error('Failed to fetch schedules', error);
          this.isResultsLoading = false;
        },
        complete: () => {
          this.isResultsLoading = false;
          console.log("Stream completed. Final results:", this.results);
        }
      });
}
toggleOperatorSelection(operator: string): void {
  console.log('Toggling operator:', operator);
  
  // Normalize the name to lowercase to avoid mismatch issues
  const normalizedOperator = operator.trim().toLowerCase();

  if (this.selectedOperators.has(normalizedOperator)) {
    this.selectedOperators.delete(normalizedOperator);
  } else {
    this.selectedOperators.add(normalizedOperator);
  }

  console.log('Updated operators:', Array.from(this.selectedOperators));
  
  // Apply filters after updating selection
  this.applyFilters();
}


filterByTimeRange(schedules: any[]): any[] {
  return schedules.filter(schedule => {
    const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);
    const hour = departureTime.getHours(); 

    // Check if current hour falls within a selected range
    const timeRange = this.getTimeRangeForHour(hour); 
    return this.selectedTimeRanges.has(timeRange);
  });
}


  
 // Function to toggle departure time selection
 toggleTimeSelection(timeRange: string): void {
  if (this.selectedTimeRanges.has(timeRange)) {
    this.selectedTimeRanges.delete(timeRange);
  } else {
    this.selectedTimeRanges.add(timeRange);
  }
  this.applyFilters(); 
}
toggleSeatTypeSelection(seatType: string): void {
  console.log('Toggling seat type:', seatType);
  if (this.selectedSeatTypes.has(seatType)) {
    this.selectedSeatTypes.delete(seatType);
  } else {
    this.selectedSeatTypes.add(seatType);
  }
  console.log('Updated seat types:', Array.from(this.selectedSeatTypes));
  this.applyFilters(); // Reapply filters after selection
}

getTimeRangeForHour(hour: number): string {
  if (hour >= 0 && hour < 6) return "Nighttime"; // Midnight to 5:59 AM
  if (hour >= 6 && hour < 12) return "Morning";  // 6 AM to 11:59 AM
  if (hour >= 12 && hour < 18) return "Midday";  // 12 PM to 5:59 PM
  return "Late";                                 // 6 PM to 11:59 PM
}


// setSortOption(option: string): void {
//   console.log(`Sorting option selected: ${option}`);
//   this.sortOption = option;

//   if (this.filteredSchedules.length === 0) {
//     console.log("No schedules available for sorting. Resetting to original results.");
    
//     // If no filtered schedules, reset to original data
//     this.filteredSchedules = [...this.originalResults]; 
//   }

//   if (this.filteredSchedules.length === 0) {
//     console.warn("Still no schedules available, cannot sort.");
//     return;
//   }

//   // Apply sorting and update UI
//   this.filteredSchedules = this.sortSchedules([...this.filteredSchedules]);
//   console.log(" Filtered schedules after sorting:", this.filteredSchedules);
// }




sortByProvider(results: any[]): void {
  results.sort((a, b) => (a.provider || "").localeCompare(b.provider || ""));
}


applyFilters(): void {
  console.log('Current selected time ranges:', Array.from(this.selectedTimeRanges));
  console.log('Current selected operators:', Array.from(this.selectedOperators));
  console.log('Current selected seat types:', Array.from(this.selectedSeatTypes));
  console.log('Applying filters with current data:', this.originalResults);

  

 
  if (!this.originalResults || this.originalResults.length === 0) { 
    console.log('No schedules available to filter.');
    this.filteredSchedules = [];
    this.isResultsLoading = false;
    return;
  }

  // Convert selected time ranges to lowercase and trim spaces
  const selectedRanges = new Set([...this.selectedTimeRanges].map(range => range.trim().toLowerCase()));
  const selectedOperators = new Set([...this.selectedOperators].map(op => op.trim().toLowerCase()));
  const selectedSeatTypes = new Set([...this.selectedSeatTypes].map(seat => seat.trim().toLowerCase()));

  console.log(`Selected Time Ranges:`, Array.from(selectedRanges));
  console.log(`Selected Operators:`, Array.from(selectedOperators));
  console.log(`Selected Seat Types:`, Array.from(selectedSeatTypes));

  let filteredResults = [...this.originalResults];

  // Apply Time Filtering (Only if time filters are selected)**
  if (selectedRanges.size > 0) {
    filteredResults = filteredResults.filter(schedule => {
      if (!schedule.departure_date || !schedule.departure_time) {
        console.warn(`Skipping schedule due to missing departure info: ${schedule.id}`);
        return false;
      }

      const departureTime = new Date(`${schedule.departure_date}T${schedule.departure_time}Z`);
      if (isNaN(departureTime.getTime())) {
        console.warn(`Invalid date format for schedule ID: ${schedule.id}, date: ${schedule.departure_date}, time: ${schedule.departure_time}`);
        return false;
      }

      const hour = departureTime.getHours();
      const timeRange = this.getTimeRangeForHour(hour).trim().toLowerCase();
      console.log(`Checking Schedule ID: ${schedule.id}, Time Range: ${timeRange}`);

      return selectedRanges.has(timeRange);
    });

    console.log('Schedules after time filtering:', filteredResults);
    
  }

 //  Apply Operator Filtering (Only if operator filters are selected)**
 if (selectedOperators.size > 0) {
  filteredResults = filteredResults.filter(schedule => {
    if(this.isReturnTicket){
      if(this.initialBus.operator.alias != schedule.operator.alias){
        console.warn(`Skipping schedule due to missing operator info: ${schedule.id}`);
        return false;
      }
    }
    console.log(this.initialBus);
    if (!schedule.operator || !schedule.operator.alias) {
      console.warn(`Skipping schedule due to missing operator info: ${schedule.id}`);
      return false;
    }

    const scheduleOperator = schedule.operator.alias.trim().toLowerCase();
    return selectedOperators.has(scheduleOperator);
  });

  console.log('Schedules after operator filtering:', filteredResults);
}


// **CASE 3: Apply Seat Type Filtering (if any selected)**
if (selectedSeatTypes.size > 0) {
  filteredResults = filteredResults.filter(schedule => {
    if (!schedule.seats_types || schedule.seats_types.length === 0) {
      console.warn(`Skipping schedule due to missing seat types: ${schedule.id}`);
      return false;
    }

    // Check if any seat type in the schedule matches the selected ones
    return schedule.seats_types.some(seat => selectedSeatTypes.has(seat.alias.trim().toLowerCase()));
  });

  console.log('Schedules after seat type filtering:', filteredResults);
}

this.results = filteredResults;


// Use `sortSchedules2()` first (to sort by departure time)
let sortedResults = this.sortSchedules2(filteredResults);

// Then apply `sortSchedules()` for additional sorting (cheapest, fastest, etc.)

// this.filteredSchedules = this.sortSchedules(filteredResults);



}





// Helper function to get the sorting function based on the selected sort option
// sortSchedules(schedules: Schedule[]): Schedule[] {
//   if (!schedules || schedules.length === 0) {
//     console.warn('⚠️ No schedules to sort.');
//     return schedules; // Return empty or original list
//   }

//   console.log("Schedules before sorting:", schedules);
//   console.log(`Sorting option selected: ${this.sortOption}`);

//   let sortedSchedules: Schedule[] = [...schedules]; // Create a copy to prevent direct mutation

//   switch (this.sortOption) {
//     case 'Cheapest':
//       sortedSchedules.sort((a, b) => {
//         const fareA = a.seats_types?.length ? Math.min(...a.seats_types.map(seat => seat.fare)) : 0;
//         const fareB = b.seats_types?.length ? Math.min(...b.seats_types.map(seat => seat.fare)) : 0;
//         return fareA - fareB;
//       });
//       break;

//     case 'Fastest':
//       console.warn("⚠️ Fastest sorting is not available. The data does not have a 'duration' field.");
//       return schedules; // Return unchanged list if sorting is not applicable

//       case 'Earliest':
//         sortedSchedules.sort((a, b) => {
         
      
//           const timeA = new Date(`${a.departure_date}T${a.departureTime}Z`).getTime();
//           const timeB = new Date(`1${b.departure_date}T${b.departureTime}Z}`).getTime();
//           return timeA - timeB;
//         });
//         break;
      

//     case 'Latest':
//       sortedSchedules.sort((a, b) => {
//         const timeA = new Date(`${a.departure_date}T${a.departureTime}Z`).getTime();
//           const timeB = new Date(`${b.departure_date}T${b.departureTime}Z`).getTime();
//           return timeA - timeB; 
//       });
//       break;

//     default:
//       sortedSchedules.sort((a, b) => {
//         const providerA = a.operator?.name?.trim() || "";
//         const providerB = b.operator?.name?.trim() || "";
//         return providerA.localeCompare(providerB);
//       });
//       break;
//   }

//   console.log(" Schedules after sorting:", sortedSchedules);
//   return sortedSchedules;
// }



sortSchedules2(schedules: any[]): any[] {
  if (!schedules || schedules.length === 0) return [];

  return schedules.sort((a, b) => {
    // Example: Sort by departure time
    const timeA = new Date(`${a.departure_date}T${a.departure_time}Z`).getTime();
    const timeB = new Date(`${b.departure_date}T${b.departure_time}Z`).getTime();
    return timeA - timeB; 
  });
}


  ngAfterViewInit(): void {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
     setTimeout(()=>{
       this.reactiveForm.patchValue({traveldate:formatDate(this.bookingdetails.traveldate,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
       if(this.bookingdetails.returning){
         this.reactiveForm.patchValue({returndate:new Date(formatDate(this.bookingdetails.returndate,"yyyy-MM-dd","en-KE")) })
       }
     },0)

    this.checkDates();
  }


  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
    this.serviceDepature.changeValue(pickup);
    this.serviceDestination.changeValue(dropoff);

    setTimeout(()=>{
      this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE")})
    },0)

  }
  checkDates=() =>{
    let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
          return true;
      else
        return false;
  }
  changeDate=(selected) =>{
    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
    this.checkDates();
    let webformdata={
      'pickup' : this.pickUpInit,
      'dropoff' : this.dropOffInit,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
      'returndate' :this.returnDateInit,
    }
    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search', message);
    this.searchBuses(webformdata);
  }
  interChange=()=>{
    this.changeI=!this.changeI;

       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
      let webformdata={
        'pickup' : dropOff,
        'dropoff' : pickUp,
        'pickupname' : dropOff,
        'dropoffname' :pickUp,
        'traveldate' :this.travelDateInit,
        'returndate' :this.returnDateInit,
        'returning' : this.returning
      }

      let message =Base64.encode(JSON.stringify(webformdata));
      localStorage.setItem('search', message);

      this.interChangeValues(dropOff,pickUp);
      this.searchBuses(webformdata);

  }
  private loadDestinations= () => {
    this.homeservice.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          });
        this.data=list;
        this.types=this.res.data;
        this.destinationLoading=false;
      },
      error => {
        return EMPTY;
      }
    );
  }
  private showSidebarFormValuesForAllOperators(): void {
    this.selectedOperators.clear();
    this.providers.forEach(operator => this.selectedOperators.add(operator.toLowerCase()));
  }
  backHome(){
    this.router.navigateByUrl('/home');
  }
  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }
  submitWebForm() {
    this.submitted = true;
 
     let value = this.reactiveForm.value;
     var pattern = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
     if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
       var arrayDate = value.traveldate.match(pattern);
       var dt = new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]);
       var traveldate = formatDate(dt,"yyyy-MM-dd","en-KE");
     }else{
       var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");

     }
     var returndate='';
     if(this.returning){
        this.returning=true;
            if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
              var ptrn = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
              var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
            }else{
              var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
            }
       value.returndate=returndate;
     }else{
         this.returning=false;
     }

    let webformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate' : value.returndate,
      'returning' : this.returning
    }
    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search', message);

    this.searchBuses(webformdata);
  }

  submitMobileForm() {
    this.submitted = true;
     let value = this.reactiveMobileForm.value;
    var traveldate = new Date(formatDate(value.traveldate,"yyyy-MM-dd","en-KE"));
    var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    let mobileformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate' : returndate,
    }
    this.searchBuses(mobileformdata);
  }
  toggleShow(results: any) {
      this.seatsSelected=[];
      this.totalFare=0;
      this.dataLoading=false;
      this.hide_from_ui = 0;
      this.hide_from_ui_shuttle = 0;
      this.isBusSelected = false
      $("#mySidenavDropoff").width('0px');
      $("#mySidenav").width('0px');
      $(".fadeMe").hide();
      $("#mySidenavShuttle").width('0px');
    }
    selectSeats(results: any) {
      this.selectedBus=results;
      this.selectedBusStatus=true;
      this.dataLoading =true;
      this.hide_from_ui = results.id;
       $("#mySidenav").width('380px');
       $(".fadeMe").show();
      this.seatsSelected=[];
      this.seatData = [];
      this.seatMobile=[];
      this.totalFare=0;
      this.seats=[];
      let traveldate=formatDate(this.travelDateInit,"d-M-yyyy","en-KE");
      let query ={
        'fleet_registration_id': results.id,
        'date': traveldate,
        'start_point': results.origin_city_id,
        'end_point': results.destination_city_id,
        'alias': results.operator.alias,
        'rsc_id': results.route_schedule_id,
        'bus_id': results.id,
        'id': results.id,
        'no_of_seats': results.number_of_available_seats,
        'fare': results.fare,
        'leaving_from': results.from,
        'going_to': results.to,
      };

      moengage.track_event('Bus Select', {
        'SelectedBusOperator': results.operator.alias,
        'Fare':parseInt(results.fare),
        'RouteDepatureCity': results.from,
        'RouteDestinationCity': results.to,
        'AvailableSeats': results.number_of_available_seats,
        'BusId': results.id,
        'TravelDate': traveldate,
        // Add more relevant details if needed
      });

        this.getAvailableSeats(query);
      }

      selectDropoff(results: any) {
        // this.selectedBus=results;
        // // this.selectedBusStatus=true;
        // this.dataLoading =true;
        // this.hide_from_ui = results.id;
         $("#mySidenavDropoff").width('380px');
         $(".fadeMe").show();

         $("#mySidenav").width('0px');
         $("#mySidenavShuttle").width('0px');

      }

      sortByDepartureTime(results: any): void {
       
      
        // Sort schedules by earliest departure time
        this.results.sort((a: any, b: any) => {
          const timeA = new Date(`${a.departure_date}T${a.departure_time}Z`).getTime();
          const timeB = new Date(`${b.departure_date}T${b.departure_time}Z`).getTime();
          
          return timeA - timeB; // Sorts by earliest departure time
        });
      
        // Log results after sorting
        console.log(" Sorted schedules:");
        results.forEach((schedule, index) => {
          
        });

        return results;

      }
      
      
    
      

      getAvailableSeats(query){
        this.seatErrors=false;
        this.service.getSeats(query).subscribe(
        response => {
          this.seatErrors=false;
          this.seatRes = response;
          if(query.alias == 'moderncoast'){
            if(this.seatRes.data.available_seats.online_discount > 0){
              this.IsDiscount=true
              this.discountAmount=this.seatRes.data.available_seats.online_discount
            }
            
          }
          this.availableSeats =this.seatRes.data.available_seats.available_seat_ids;
          this.seats = this.seatRes.data.available_seats.seats;
          this.booked_seat_session_id =this.seatRes.data.available_seats.booked_seat_session_id;
      
          for(let i=0;i<this.seats.length;i++)
          {
            let iseats =this.seats[i];
            let s =5;
            for(let j=0;j< iseats.length;j++){
                var temp = {s:s,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
                this.seatData.push(temp);
                s--;
            }
            let m=5;
            for(let j=0;j< iseats.length;j++){
              var mobiletemp = {m:m,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
              this.seatMobile.push(mobiletemp);
              m++;
           }
              this.seatData.sort(function (a, b) {
                return a.s - b.s;
              });
              this.seatMobile.sort(function (a, b) {
                return a.m - b.m;
              });
              this.dataLoading=false;


          }
          const groupBy = key => array =>
          array.reduce((objectsByKeyValue:any, obj:any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          const groupByVal = groupBy('s');
          const groupByValMobile = groupBy('row');
          this.seatData = Object.values(groupByVal(this.seatData));
          this.seatMobile = Object.values(groupByValMobile(this.seatMobile));

        },
        error => {
          this.seatErrors=true;
          this.dataLoading =false;

          return EMPTY;

        }
      );
      }

      seatSelect(event:any,result:any,id:number,space_type:string,fare:any, isReturnTrip: boolean = false){
     
        let seatFare:any;
         let seatsPrices =result.seats_types;
         let seatPrice ='0';
         let seatCurrency ='KSH';
        if(result.operator.alias == 'mashpoa' || result.operator.alias == 'moderncoast'){
          for(let i=0; i<seatsPrices.length; i++){
            if(space_type == seatsPrices[i].alias){
              seatPrice=seatsPrices[i].fare;
              seatCurrency=seatsPrices[i].currency;
            }
          }
       }else{
          for(let i=0; i<seatsPrices.length; i++){
        
            if(space_type == seatsPrices[i].alias || (space_type =="business" &&  seatsPrices[i].alias =="business")){
                seatPrice=seatsPrices[i].fare;
                seatCurrency=seatsPrices[i].currency;
            }else{
              if(seatsPrices[i].fare > 0){
                seatPrice=seatsPrices[i].fare;
                seatCurrency=seatsPrices[i].currency;
              }
            }
        }
         }
        if(event.srcElement.parentElement.classList.contains('unavailable')){
        }else{
          if(this.seatsSelected.some(e => e.id === id)){
  
            if(event.srcElement.classList.contains('single-seat')){
              event.srcElement.classList.remove("selected");
            }
            this.totalFare=this.totalFare-parseInt(seatPrice);
            this.fareCurrency = seatCurrency;
            this.seatsSelected = this.seatsSelected.filter(item => item.id !== id);
            event.srcElement.parentElement.classList.remove('selected');
          }else{
            if(event.srcElement.classList.contains('single-seat')){
              event.srcElement.classList.add("selected");
            }
            this.totalFare=this.totalFare+parseInt(seatPrice);
            this.fareCurrency = seatCurrency;
            // this.seatsSelected.push({id:id,space_type:space_type});
             seatFare=fare[space_type]
            if(result.operator.alias == 'easycoach' && seatFare === undefined ){
              seatFare = fare.normal;
             }
            this.seatsSelected.push({id:id,space_type:space_type,fare:seatFare});
            event.srcElement.parentElement.classList.add('selected');
            if(this.return_schedule){
              this.busSelected=result;
              this.currentBusId=result.id;
              this.hide_from_ui=0;
              this.busOperatorSelected=result.operator.alias;
              this.isBusSelected =true;
            }
          }
  
       
        moengage.track_event('Seat Selection', {
          'SelectedSeatNumber': id,
          'SeatType': space_type,
          'BusOperator':result.operator.alias,
          'SeatPrice':parseInt(seatFare)
          // Add more relevant details if needed
        });

      }


      this.mixpanelService.track('SeatSelect', {
        seatNumber: id,
        operator: result.operator.alias,
        source: 'buupassSite',
        role: 'customer',

      });




     }
     refreashSeats(){
      this.dataLoading=true;
      let webformdata={
        'pickup' : this.pickUpInit,
        'dropoff' : this.dropOffInit,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
      }
      this.sendRefreash(webformdata)


    }

    // Refresh bus seats
    sendRefreash(search:any){
      search.traveldate=formatDate(search.traveldate,"yyyy-MM-dd","en-KE");
      this.pickUpInit = search.pickupname;
      this.dropOffInit = search.dropoffname;
      this.service.onSearch(search).subscribe(
        response => {
          this.dataLoading=false;
          this.res = response;
          this.results = this.res.data;
          if( this.res.data ) {
            this.pickup =  this.pickUpInit;
            this.dropoff =  this.dropOffInit;
          }
        },
        error => {
          return EMPTY;
        }
      );
    }
    get f() { return this.reactiveForm.controls; }
     // pickupanddropoff
    get fpickup() { return this.pickupForm.controls; }
    
    //book shuttle
    bookShuttle(result:any){
      this.selectedBus=result;
      this.hide_from_ui_shuttle = result.trip_id;
      $("#mySidenavShuttle").width('380px');
      $(".fadeMe").show();
      this.selectedBusStatus=true;

      const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
        let seatsPrices =result.seats_types;
        let seatPrice ='0';
        for(let i=0; i<seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
        }
        this.totalFare=parseInt(seatPrice);
        this.busSelected=result;
        this.currentBusId=result.id;
        this.busOperatorSelected=result.operator.alias;
        this.isBusSelected =true;

      //TODO implement the shuttle process
      this.formdataTravel={
        'result':result,
        'fare': result.fare,
        'title': result.from + ' to ' + result.to,
        'total_fare':this.totalFare,
        "search_details": this.bookingdetails,
        'pickup' : result.boarding_points.length,
        'dropoff' :result.dropoff_points,
        'totalfare' : this.totalFare,
        'seatsSelected' : this.seatsSelected
      }

      let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let syncData={
          'travel':paytravel,
          'return':'',
          'returning':false
        }
    }

    selectPassOption(id: number,selectedBus) {
      let seatsPrices =selectedBus.seats_types;
      let seatPrice ='0';
      for(let i=0; i<seatsPrices.length; i++){
           if(seatsPrices[i].fare > 0){
             seatPrice=seatsPrices[i].fare;
           }
     }
       //getted from event

       this.seatsSelected=[];
       this.selectedOptionPassengers=id
       this.totalFare=parseInt(this.selectedOptionPassengers) * parseInt(seatPrice)

       for(let i=0;i<this.selectedOptionPassengers;i++)
       {
           const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
           this.seatsSelected.push({id:random(1, 23),space_type:'business'});
       }

     }
    
    // Submit the seat selection for payment processing
    submitForm(result:any) {
      this.submitted = true;
      console.log(result);
      if (!this.insurance || !this.insurance_operators) {
        console.error('Insurance data is missing or incomplete');
        return; // Stop the submission or handle this case appropriately
    }

      if (result.dropoff_points.length <1 ) result.dropoff_points.push(this.dropOffInit);
      if (result.boarding_points.length <1 ) result.boarding_points.push(this.pickUpInit);


      if(this.isReturnTicket && !this.isReturnTicketSelected){
        this.isReturnTicketSelected=true;
        let resultsData:any;
        let resultSeats:any;
        resultsData=result;
      console.log(resultsData);
        resultSeats = this.seatsSelected;
        // var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
        if(resultsData.operator.alias =='easycoach'){
          var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
          resultsData.departure_date = momentObj.format('DD/MM/YYYY'); // 2016-07-15
        }else if (resultsData.is_shuttle){
          var momentObj = moment(resultsData.departure_date, 'YYYY-MM-DD');
          resultsData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }else{
          var momentObj = moment(resultsData.departure_date, 'YYYY-MM-DD');
          resultsData.departure_date = momentObj.format('DD/MM/YYYY'); // 2016-07-15

        }
      // pickupanddropoff
        if(resultsData.operator.alias =='mashpoa'){

          this.submittedMashPoa = true;
          if(!this.pickupForm.valid){
            return
          }
          let pickupreturn=this.pickupForm.value;
          let fvalues = this.pickupForm.value;
          pickupreturn = fvalues;
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
       
          this.pickupreturnlocation = pickupreturn.pickupLocation
          this.dropoffreturnlocation = pickupreturn.dropoffLocation
        }else{
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
          this.pickupreturnlocation = this.fromPlaceholder 
          this.dropoffreturnlocation = this.toPlaceholder
        }

        this.formdataReturn={
          'result':resultsData,
          'fare': resultsData.fare,
          'title': resultsData.from + ' to ' + resultsData.to,
          'total_fare':this.totalFare,
          'fare_currency':this.fareCurrency,
          "search_details": this.bookingdetails,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'pickup' : resultsData.boarding_points.length,
          'dropoff' :resultsData.dropoff_points,
          'pickup_location':this.pickupreturnlocation,
          'dropoff_location':this.dropoffreturnlocation,
          'totalfare' : this.totalFare,
          'seatsSelected' : resultSeats,
          'booking_session_id':this.booked_seat_session_id,
          'insurance':this.insurance,
          'insurance_operators':this.insurance_operators,
          'insurance_cap':this.insurance_cap,
          'insurance_counter':this.insurance_counter
        }
        
      }else{
        let initialData:any;
        initialData=result;
       
         // pickupanddropoff
        if(initialData.operator.alias =='mashpoa'){
         
          if(!this.pickupForm.valid){
            return
          }
          let fvalues = this.pickupForm.value;
          this.pickuplocation = fvalues.pickupLocation
          this.dropofflocation = fvalues.dropoffLocation
          
          if(this.returning){
            this.submittedMashPoa = false;
            $("#mySidenavDropoff").width('0px');
            $("#mySidenav").width('0px');
            $(".fadeMe").hide();
            this.pickupForm.reset();
          }else{
            this.submittedMashPoa = true;
            $("#mySidenav").width('0px');
            $(".fadeMe").hide();
          }
      

     
        }else{
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
          this.pickuplocation = initialData.from 
          this.dropofflocation = initialData.to
        }


        //initialData.departure_date = this.finalTravelDate;
        if(initialData.operator.alias =='easycoach'){
          var momentObj = moment(initialData.departure_date, 'MM-DD-YYYY');
          initialData.departure_date = momentObj.format('MM/DD/YYYY'); // 2016-07-15
        }else if (initialData.is_shuttle){
          var momentObj = moment(initialData.departure_date, 'YYYY-MM-DD');
          initialData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }else{
          initialData.departure_date = this.finalTravelDate;
        }
        this.formdataTravel={
          'result':initialData,
          'fare': initialData.fare,
          'title': initialData.from + ' to ' + initialData.to,
          'total_fare':this.totalFare,
          'fare_currency':this.fareCurrency,
          "search_details": this.bookingdetails,
          'pickup' : initialData.boarding_points.length,
          'dropoff' :initialData.dropoff_points,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'pickup_location':this.pickuplocation,
          'dropoff_location':this.dropofflocation,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'booking_session_id':this.booked_seat_session_id,
          'insurance':this.insurance,
          'insurance_operators':this.insurance_operators,
          'insurance_cap':this.insurance_cap,
          'insurance_counter':this.insurance_counter

        }

        console.log("=0-0=-0p=0p",this.formdataTravel);
      }
      //check if the trip is one way
      if(this.bookingdetails.returning && !this.isReturnTicket){
        this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        let returnsearch={
          'pickup' : this.bookingdetails.dropoff,
          'dropoff' : this.bookingdetails.pickup,
          'pickupname' : this.bookingdetails.dropoff,
          'dropoffname' :this.bookingdetails.pickup,
          'traveldate' :this.bookingdetails.returndate,
          'returndate' :this.bookingdetails.originalDepartureDate,
        }
        this.isReturnTicket=true;
        this.showSidebarFormValuesForAllOperators();
        // this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        this.searchBuses(returnsearch);
      } else if(this.bookingdetails.returning && this.isReturnTicket){
        console.log(this.formdataTravel)
        console.log(this.formdataReturn)
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let payreturn=Base64.encode(JSON.stringify(this.formdataReturn));
        let syncData={
            'travel':paytravel,
            'return':payreturn,
            'returning':true
        }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changePaymentMessage(pay);
        this.selectedBusStatus = false;
        this.router.navigate(['/payments', paymessage])
      } else {
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
          let syncData={
            'travel':paytravel,
            'return':'',
            'returning':false
          }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changePaymentMessage(pay);


        this.selectedBusStatus = false;
        this.router.navigate(['/payments', paymessage])
      }


      this.mixpanelService.track('BusSelected', {
        operator: result.operator.alias,
        pickup : this.fromPlaceholder,
        dropoff :this.toPlaceholder,
        fare: this.formdataTravel.total_fare,
        source: 'buupassSite',
        role: 'customer',
      });

    

    }
    
  searchBuses(search:any){
    let message=Base64.encode(JSON.stringify(search));

    moengage.track_event('Bus ticket search', {
      'DepartureCity': search.pickup,
      'ArrivalCity': search.dropoff,
      'DepartureDate': search.traveldate
      // Add more relevant details if needed
    });
    if(this.isReturnTicket){
      this.initialBus= this.selectedBus;
      let newReturn = [];
      this.results = this.return_schedule ?? [];
      for(let i=0;i<this.results.length;i++)
      {
          if(this.results[i].operator.alias == this.busOperatorSelected){
            newReturn.push(this.results[i]);
          }
      }
      this.results=newReturn
       // Fix: Null check before accessing `length`
       if (!this.results || !Array.isArray(this.results) || this.results.length === 0) {

        this.isEmptyReturn = true;
        this.submitted = false;
        this.results = [];
        this.isResultsLoading = true;

        // Fix: Ensure `search.traveldate` is valid before formatting
        if (search.traveldate && search.traveldate.trim() !== "") {
          search.traveldate = formatDate(search.traveldate, "dd/MM/yyyy", "en-KE");
        } else {
          console.error("Invalid travel date:", search.traveldate);
        }

        // Fix: Ensure `search.returndate` is valid before formatting
        if (this.returning && search.returndate && search.returndate.trim() !== "") {
          search.returndate = formatDate(search.returndate, "dd/MM/yyyy", "en-KE");
        } else if (this.returning) {
          console.warn("Return date is missing or invalid:", search.returndate);
        }
          
          this.pickUpInit = search.pickupname;
          this.dropOffInit = search.dropoffname;
          this.finalTravelDate =search.traveldate;
          this.finalReturnDateInit =search.returndate
          search.returning=this.returning;

          console.log(`Stream subscription started for: ${search.pickupname} → ${search.dropoff} on ${search.traveldate}`);

          this.isResultsLoading = true; 
          this.results = []; 
          
          this.streamSubscription = this.service
            .streamSchedules(search.pickupname, search.dropoff, search.traveldate)
            .subscribe({
              next: (streamData: StreamData) => {
                console.log("Stream data receivedyyyyyyy:", streamData);
                console.log(typeof streamData );
                console.log("THR PROD",streamData.provider);
                // this.providers = [];
              if(this.initialBus.operator.alias === streamData.provider){
                console.log("THR hywjgfdsjkfdgh",streamData.provider);
                this.providers = [streamData.provider];
                console.log("THR PROD",this.providers);
              }
              
                if (streamData && typeof streamData === "object") {
                  
                  let scheduleArray = streamData.data ?? [];
                  console.log("Schedule array received:", scheduleArray)

                  // Handle insurance data here
                  if (streamData.data?.insurance) {
                    this.insurance = streamData.data.insurance.data || {};
                    this.insurance_operators = streamData.data.insurance.allowed_operators || [];
                    this.insurance_cap = streamData.data.insurance.cap || 0;
                    this.insurance_counter = streamData.data.insurance.counter || 0;
                  }else {
                    // Set default or fallback values if insurance data is missing
                    this.insurance = {};
                    this.insurance_operators = [];
                    this.insurance_cap = 0;
                    this.insurance_counter = 0;
                }

              
                  if (Array.isArray(scheduleArray) && scheduleArray.length > 0) {
              
                    scheduleArray.forEach((schedule, index) => {
                      // console.log(`Processing schedule ${index}:`, schedule);
                      if(this.initialBus.operator.alias === schedule.operator.alias){
                        this.results.push(schedule);
                        this.originalResults.push(schedule);
            
                        this.operators.add(schedule.operator);
                      }
                      
                    });
              
                   
                 
                  } else {
                    console.warn("No schedules found in response data.");
                  }
                }
              
              
                // if (Array.isArray(streamData.data?.schedule)) {
                //   streamData.data.schedule.forEach((schedule: Schedule, index) => {
                //     console.log(`Processing schedule ${index}:`, schedule);
                //     this.results.push(schedule);
                //   });
                //   return; 
                // }
              
              
                // if ('operator' in streamData && 'departureTime' in streamData) {
                //   const schedule = streamData as Schedule;
                //   if (schedule.operator.alias === this.busOperatorSelected) {
                //     this.results.push(schedule);
                //   }
                // } else {
                //   console.warn(" Invalid single schedule object received:", streamData);
                // }
              
               
              },
              
              
              error: (err) => {
                console.error("Error streaming bus schedules:", err);
              
                // Ensure we log and handle stream errors properly
                if (this.streamSubscription) {
                  
                  this.streamSubscription.unsubscribe();
                }
              
                this.isResultsLoading = false;
              },
              
              complete: () => {
                console.log("Stream completed.");
          
                  console.log(" Stream marked as completed. before...");
                     console.log("Results",this.results)
                  // Sort before unsubscribing
                  this.results=this.sortByDepartureTime(this.results);
                  this.originalResults = [...this.results]; 
                  this.isResultsLoading = false; 
              
                  if (this.streamSubscription) {
                    this.streamSubscription.unsubscribe(); // Unsubscribe manually
                    
                  }
                
              
                }  
            });
          
              
              }
            
    }else{
      localStorage.setItem('search-buupass', message);
    this.results=[];
    this.isResultsLoading=true;
    this.travelDateInit=search.traveldate;
    if(search.traveldate != undefined)
        search.traveldate=formatDate(search.traveldate,"dd/MM/yyyy","en-KE");
    if(this.returning){
      search.returndate=formatDate(search.returndate,"dd/MM/yyyy","en-KE");
    }
    this.pickUpInit = search.pickupname;
    this.dropOffInit = search.dropoffname;
    search.returning=this.returning;
    this.finalTravelDate =search.traveldate;
    this.finalReturnDateInit =search.returndate
    this.interChangeValues(search.pickupname,search.dropoffname);

    console.log(`Stream subscription started for: ${search.pickupname} → ${search.dropoff} on ${search.traveldate}`);

this.isResultsLoading = true; 
this.results = []; 

this.streamSubscription = this.service
  .streamSchedules(search.pickupname, search.dropoff, search.traveldate)
  .subscribe({
    next: (streamData: StreamData) => {
      console.log("Stream data receivedyyyyyyy:", streamData);
      console.log(typeof streamData );
      console.log("THR PROD",streamData.provider);

      this.providers.push(streamData.provider);
    
      if (streamData && typeof streamData === "object") {
        
        let scheduleArray = streamData.data ?? [];
        console.log("Schedule array received:", scheduleArray)
         // Handle insurance data here
         if (streamData.data?.insurance) {
          this.insurance = streamData.data.insurance.data || {};
          this.insurance_operators = streamData.data.insurance.allowed_operators || [];
          this.insurance_cap = streamData.data.insurance.cap || 0;
          this.insurance_counter = streamData.data.insurance.counter || 0;
        }else {
          // Set default or fallback values if insurance data is missing
          this.insurance = {};
          this.insurance_operators = [];
          this.insurance_cap = 0;
          this.insurance_counter = 0;
      }

    
        if (Array.isArray(scheduleArray) && scheduleArray.length > 0) {
    
          scheduleArray.forEach((schedule, index) => {
            // console.log(`Processing schedule ${index}:`, schedule);
            this.results.push(schedule);
            this.originalResults.push(schedule);

            this.operators.add(schedule.operator);
          });
    
         
       
        } else {
          console.warn("No schedules found in response data.");
        }
      }
    
    
      // if (Array.isArray(streamData.data?.schedule)) {
      //   streamData.data.schedule.forEach((schedule: Schedule, index) => {
      //     console.log(`Processing schedule ${index}:`, schedule);
      //     this.results.push(schedule);
      //   });
      //   return; 
      // }
    
    
      // if ('operator' in streamData && 'departureTime' in streamData) {
      //   const schedule = streamData as Schedule;
      //   if (schedule.operator.alias === this.busOperatorSelected) {
      //     this.results.push(schedule);
      //   }
      // } else {
      //   console.warn(" Invalid single schedule object received:", streamData);
      // }
    
     
    },
    
    
    error: (err) => {
      console.error("Error streaming bus schedules:", err);
    
      // Ensure we log and handle stream errors properly
      if (this.streamSubscription) {
        
        this.streamSubscription.unsubscribe();
      }
    
      this.isResultsLoading = false;
    },
    
    complete: () => {
      console.log("Stream completed.");

        console.log(" Stream marked as completed. before...");
           console.log("Results",this.results)
        // Sort before unsubscribing
        this.results=this.sortByDepartureTime(this.results);
        this.originalResults = [...this.results]; 
        this.isResultsLoading = false; 
    
        if (this.streamSubscription) {
          this.streamSubscription.unsubscribe(); // Unsubscribe manually
          
        }
      
    
      }  
  });

    
    }
  }

  trackByBooking(result:any) {
    return result.id;
 }

 acClick(){
   if(this.isAcClicked){
     this.isAcClicked=false;
   }else{
     this.isAcClicked=true;
   }

 }
isWaterClicked(){

  if(this.isWater){
    this.isWater=false;
  }else{
    this.isWater=true;
  }

}
loadDesktopScript() {
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.text = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: '8e5c9e81-34db-47a2-a612-8f8b613c86fc', ph: 'yehtu_8e5c9e8134db47a2a6128f8b613c86fc_zone_125480_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '8d0993d8-2d8f-40c9-969e-0160b22bbebd', ph: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '2e47ebaa-4cd3-4517-890d-73002a99a2e2', ph: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
  `;
  this.renderer.appendChild(document.body, script);
  
}


loadMobileScript() {
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.text = `
    (window.globalAmlAds = window.globalAmlAds || []).push(function() {
      globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.defineSlot({z: '27641268-2842-43d1-8342-87da9ce25b3e', ph: 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.defineSlot({z: 'ee955c02-ec3b-4635-9630-27bd45ff897e', ph: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.defineSlot({z: '83f705e0-0010-4481-87be-1348fab1446b', ph: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
      globalAml.singleRequest("yehtu");});
  `;
  this.renderer.appendChild(document.body, script);
  
}

loadAds() {
  // Check and log screen width
  const screenWidth = window.innerWidth;

  // Define ad slots
  const ads = [
   
    { id: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', minWidth: 992 },
    { id: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', minWidth: 0 },
    { id: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', minWidth: 992 },
  ];

  ads.forEach(ad => {
    if (screenWidth >= ad.minWidth) {
      const adElement = document.getElementById(ad.id);
      if (adElement) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `(window.globalAmlAds = window.globalAmlAds || []).push(function() {
          globalAml.display('${ad.id}');
        });`;
        this.renderer.appendChild(adElement, script);
      } else {
      }
    }
  });
}


hideFeedback(){
  this.hideFeedbackBtn=true;
}






}
