import { Injectable, NgZone  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { StreamData } from '../interfaces/booking-interface';

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};


@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private http:HttpClient,
    private zone: NgZone
  ) { }


  
  onSearch(search : any) {
    if(search.returning){
      return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate+'&return_date='+search.returndate,httpOptions)
      .pipe(
        retry(3),
      );
    }
    return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate,httpOptions)
    .pipe(
      retry(3),
    );
  }
  getSeats(query:any){
    return this.http.get(`${environment.getSeats}`  +'?bus_id='+query.bus_id + '&leaving_from='+query.leaving_from+'&going_to='+query.going_to+'&departing_on='+ '&rsc_id='+query.rsc_id + '&start_point='+query.start_point + '&end_point='+query.end_point +'&alias='+query.alias+'&date='+query.date + '&fleet_registration_id='+query.fleet_registration_id + '&no_of_seats='+query.no_of_seats + '&fare='+query.fare + '&id='+query.id,httpOptions)
    .pipe(
      retry(3),
    );
  }
  


  // streamSchedules(leaving_from: string, going_to: string, departing_on: string): Observable<StreamData> {
   
    
  //   const url = `${environment.streamSearchBuses}?leaving_from=${leaving_from}&going_to=${going_to}&departing_on=${departing_on}&token=${environment.api_key}`;
   
    
  //   return new Observable<StreamData>((observer) => {
  //     const eventSource = new EventSource(url);
  
  //     eventSource.onopen = () => 
  
  //     eventSource.onmessage = (event) => {
  //       try {
  //         const data: StreamData = JSON.parse(event.data); // Explicit typing
         
  
  //         if (data.completed) {
  //           observer.complete();
  //         } else {
  //           observer.next(data);
  //         }
  //       } catch (error) {
  //         observer.error(new Error("Error parsing stream data: " + error));
  //       }
  //     };
  
  //     eventSource.onerror = (error) => {
  //       console.error("SSE Error:", error);
  //       observer.error(new Error("SSE connection failed"));
  //       eventSource.close();
  //     };
  
  //     return () => {
       
  //       eventSource.close();
  //     };
  //   });
  // }

  // streamSchedules(leaving_from: string, going_to: string, departing_on: string): Observable<StreamData> {
  //   return new Observable<StreamData>((observer) => {
  //     const url = `${environment.streamSearchBuses}?leaving_from=${leaving_from}&going_to=${going_to}&departing_on=${departing_on}&token=${environment.api_key}`;
      
  //     fetch(url).then(async (response) => {
  //       const reader = response.body?.getReader();
  //       const decoder = new TextDecoder("utf-8");
      
  //       if (!reader) {
  //         observer.error(new Error("Failed to read response body"));
  //         return;
  //       }
      
  //       let buffer = ""; // ✅ Accumulate partial messages
      
  //       while (true) {
  //         const { done, value } = await reader.read();
  //         if (done) {
  //           observer.complete();
  //           break;
  //         }
      
  //         buffer += decoder.decode(value, { stream: true }); // Append new chunk
      
  //         // ✅ Process full messages (split by new line)
  //         let lines = buffer.split("\n");
      
  //         buffer = lines.pop() || ""; // ✅ Keep incomplete data in buffer
      
  //         lines.forEach((line) => {
  //           if (line.startsWith("data:")) {
  //             try {
  //               const jsonString = line.replace("data:", "").trim();
  //               const parsedData: StreamData = JSON.parse(jsonString);
  //               observer.next(parsedData);
  //             } catch (error) {
  //               console.warn("Incomplete JSON, waiting for more data...");
  //               buffer += line; // Re-append to buffer if parsing fails
  //             }
  //           }
  //         });
  //       }
  //     }).catch(error => observer.error(error));
      

  //   });
  // }

  streamSchedules(leaving_from: string, going_to: string, departing_on: string): Observable<StreamData> {
    return new Observable<StreamData>((observer) => {
      const url = `${environment.streamSearchBuses}?leaving_from=${leaving_from}&going_to=${going_to}&departing_on=${departing_on}&token=${environment.api_key}`;
  
      fetch(url).then(async (response) => {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");
  
        if (!reader) {
          observer.error(new Error("Failed to read response body"));
          return;
        }
  
        let buffer = ""; // ✅ Accumulate partial messages
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            observer.complete(); // ✅ If the stream ends, complete the observer
            break;
          }
  
          buffer += decoder.decode(value, { stream: true }); // Append new chunk
  
          // ✅ Process full messages (split by new line)
          let lines = buffer.split("\n");
          buffer = lines.pop() || ""; // ✅ Keep incomplete data in buffer
  
          lines.forEach((line) => {
            if (line.startsWith("data:")) {
              try {
                const jsonString = line.replace("data:", "").trim();
                const parsedData: StreamData = JSON.parse(jsonString);
                observer.next(parsedData);
  
                // ✅ If response contains `"completed": true`, stop streaming
                if (parsedData.completed === true) {

                  observer.complete();
                }
              } catch (error) {
                console.warn("Incomplete JSON, waiting for more data...");
                buffer += line; // Re-append to buffer if parsing fails
              }
            }
          });
        }
      }).catch(error => observer.error(error));
    });
  }
  
      
}

